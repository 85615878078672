import React, { useState, useEffect, useRef } from "react";
import { thounsandSeparators } from "utils/helper";
import RUIcon from "assets/images/vault/ru.png";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { RU_CONTRACT, PLAYER_CONTRACT, NFT_VAULT_CONTRACT, RPC } from "consts";
import CountUp from "react-countup";
import { Checkbox, Modal, Button } from "antd";
import NFTDeposit from "./NFTDeposit";
import usePrevious from "hooks/usePrevious";

const Reward = ({ balanceInVault, interest, rate, reFetchInfo }) => {
  const [totalDepositRU, setTotalDepositRU] = useState(0);
  const [totalDepositNFT, setTotalDepositNFT] = useState(0);
  const [accountReward, setAccountReward] = useState(0);
  const [rewardBigNumber, setRewardBigNumber] = useState(0);
  const previosReward = usePrevious(accountReward);
  const [apy, setAPY] = useState(0);
  const { account, library, chainId } = useWeb3React();
  const [openModalNft, setOpenModalNft] = useState(false);

  const handleOpenModalNFT = () => {
    setOpenModalNft(true);
  };

  const handleCancelSelectNft = () => {
    setOpenModalNft(false);
  };

  const claimReward = async () => {
    if (account && library && library.provider) {
      const web3 = new Web3(library.provider);
      const vaultContract = new web3.eth.Contract(NFT_VAULT_CONTRACT.ABI, NFT_VAULT_CONTRACT.ADDRESS);
      const result = await vaultContract.methods.claimReward().send({
        from: account
      });
    }
  }

  useEffect(() => {
    async function initAPY() {
      const web3 = new Web3(RPC.BSC);
      const vaultContract = new web3.eth.Contract(NFT_VAULT_CONTRACT.ABI, NFT_VAULT_CONTRACT.ADDRESS);
      // const totalDepositRU = await vaultContract.methods.totalDeposit().call();
     // const RUPerBlock = await vaultContract.methods.rewardPerBlock().call();
      const APY = await vaultContract.methods.getAPR().call();
      setAPY(APY);
      // const totalDepositRUBN = new BigNumber(totalDepositRU);
      // if (totalDepositRUBN.eq(0)) {
        //   return;
        // }
        // const RUPerBlockBN = new BigNumber(RUPerBlock);
        // const apy = RUPerBlockBN.multipliedBy(365 * 86400 / 3).dividedBy(totalDepositRU).toFixed(2);
      }

    async function initAccount() {
      if (account && library && library.provider) {
        const web3 = new Web3(library.provider);
        const vaultContract = new web3.eth.Contract(NFT_VAULT_CONTRACT.ABI, NFT_VAULT_CONTRACT.ADDRESS);
        let reward = await vaultContract.methods.getUnclaimedReward(account).call();
        const totalDepositRU = await vaultContract.methods.getBalanceRU(account).call();
        const totalDepositNFT = await vaultContract.methods.getBalanceNFT(account).call();
        setRewardBigNumber(reward);
        reward = parseFloat(new BigNumber(reward).dividedBy(1e18).toFixed(3));
        setAccountReward(reward);
        setTotalDepositRU(new BigNumber(totalDepositRU).dividedBy(1e18).toFixed(2));
        setTotalDepositNFT(totalDepositNFT);
      }
    }

    initAPY();
    initAccount();

    const interval = setInterval(() => {
      initAPY();
      initAccount();
    }, 5 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, [account, chainId])

  return (
    <div className="box box-left">
      <ul className="box__info">
        <li>
          <p className="label">Deposit RU</p>
          <p className="value">{thounsandSeparators(totalDepositRU)} RU</p>
        </li>
        <li>
          <p className="label">Deposit NFT</p>
          <div className="view-all" onClick={handleOpenModalNFT}>
            {totalDepositNFT} NFTs
          </div>
        </li>
        <li>
          <p className="label">APY</p>
          <p className="value"> {apy}%</p>
        </li>
      </ul>
      <h2 className="box__title">Reward</h2>
      <ul className="box__info">
        <li className="no-border">
          <p className="label">RU</p>
          <div className="coin">
            <img src={RUIcon} alt="RU" />
            <p>
              <CountUp
                  start={previosReward === accountReward ? 0 : previosReward}
                  end={accountReward}
                  duration={1.5}
                  separator=","
                  decimals={3}
                  decimal="."
                />
             </p>
            {/* <p>{thounsandSeparators(accountReward)}</p> */}
          </div>
          <Button
            className=" btn-claim"
            onClick={claimReward}
            disabled={!account || Number(rewardBigNumber) === 0}
          >
            Claim
          </Button>
        </li>
      </ul>

      <Modal
          visible={openModalNft}
          onCancel={() => {
            setOpenModalNft(false);
          }}
          title={"LIST NFT DEPOSITED"}
          className="app-modal modal-nft-vault"
          width="80%"
          centered
          footer={null}
          closable={true}
        >
          <NFTDeposit isOpen={openModalNft} />
        </Modal>

    </div>
  );
};

export default Reward;
