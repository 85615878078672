import React from "react";
import { useState } from "react";
import Switch from "react-switch";
import {
  ADVANCED,
  CLUB_OPTIONS,
  GENERAL,
  LEAGUE_OPTIONS,
  POSITION_OPTIONS,
  SPECIES_OPTIONS,
  TALENT_OPTIONS,
} from "utils/constants";
import { Checkbox, Slider, Select } from "antd";
// import ic_filter from "../../assets/icons/filter.svg";
import IconArrowDown from "assets/icons/ic_arrow_down.png";
import IconCancel from "assets/icons/ic_cancel.png";
import { isArray } from "lodash";

const Filter = (props) => {
  const {
    handleChangeOverall,
    handleChangePosition,
    handleChangeAge,
    handleChangeHeight,
    handleChangeWeight,
    hanldeClearFilter,
    handleChangeSpecies,
    handleChangeTalent,
    handleChangeLeague,
    handleChangeClub,
    filter,
    handleChangeOnsaleFilter,
    type,
    isOnSale,
  } = props;

  const [typeFilter, setTypeFilter] = useState(GENERAL);
  // const [checkedOnSale, setCheckedOnSale] = useState(false);

  const onChangeOnsaleFilter = (value) => {
    // setCheckedOnSale(value);
    handleChangeOnsaleFilter(value);
  };

  const overallMarks = {
    20: "20",
    40: "",
    60: "",
    80: "",
    100: "",
    120: "120",
  };
  const ageMarks = { 15: "15", 20: "", 25: "", 30: "", 35: "", 45: "45" };

  const heightMarks = {
    160: "160",
    170: "",
    180: "",
    190: "",
    200: "",
    210: "",
    220: "220",
  };

  const weightMarks = { 60: "60", 70: "", 80: "", 90: "", 100: "100" };

  return (
    <div className="filter-common">
      <div className="filter-block filter-header">
        <h6>Filter</h6>
        <button onClick={hanldeClearFilter}>Clear</button>
      </div>
      <div className="filter-block filter-type">
        <div className="filter-group">
          <div
            active={typeFilter === GENERAL ? "true" : "false"}
            onClick={() => {
              setTypeFilter(GENERAL);
            }}
          >
            General
          </div>
          <div
            active={typeFilter === ADVANCED ? "true" : "false"}
            onClick={() => {
              setTypeFilter(ADVANCED);
            }}
          >
            Advanced
          </div>
        </div>
      </div>
      <div className="filter__content">
        {type === "wallet" ? (
          <div className="filter-inline filter-switch filter-sale">
            <div className="filter-name">On Sale</div>
            <Switch
              onChange={onChangeOnsaleFilter}
              checked={isOnSale}
              onColor="#fcb100"
              onHandleColor="#ffffff"
              handleDiameter={18}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={40}
              className="react-switch"
            />
          </div>
        ) : null}

        {typeFilter === GENERAL ? (
          <div>
            <div className="filter-block filter-overall">
              <div className="filter-name">Overall Score</div>
              <Slider
                range
                marks={overallMarks}
                value={filter.overallRange}
                min={20}
                max={120}
                step={20}
                onChange={handleChangeOverall}
              />
            </div>
            <div className="filter-block filter-checkbox filter-position">
              <div className="filter-name">Position</div>
              <Checkbox.Group
                options={POSITION_OPTIONS}
                onChange={handleChangePosition}
                value={
                  isArray(filter.position) ? filter.position : [filter.position]
                }
              />
            </div>
            <div className="filter-block filter-age">
              <div className="filter-name">Age</div>
              <Slider
                range
                marks={ageMarks}
                value={filter.ageRange}
                min={15}
                max={45}
                step={5}
                onChange={handleChangeAge}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="filter-block filter-checkbox filter-species">
              <div className="filter-name">Species</div>
              <Checkbox.Group
                options={SPECIES_OPTIONS}
                value={
                  isArray(filter.species) ? filter.species : [filter.species]
                }
                onChange={handleChangeSpecies}
              />
            </div>
            <div className="filter-block filter-height">
              <div className="filter-name">Height</div>
              <Slider
                range
                marks={heightMarks}
                value={filter.heightRange}
                min={160}
                max={220}
                step={10}
                onChange={handleChangeHeight}
                key="height"
              />
            </div>
            <div className="filter-block filter-weight">
              <div className="filter-name">Weight</div>
              <Slider
                range
                marks={weightMarks}
                value={filter.weightRange}
                min={60}
                max={100}
                step={10}
                onChange={handleChangeWeight}
                key="weight"
              />
            </div>
            <div className="filter-block filter-talent filter-select">
              <div className="filter-name">Talent</div>
              <Select
                placeholder="Select"
                dropdownClassName="dropdown-memu"
                options={TALENT_OPTIONS}
                suffixIcon={<img src={IconArrowDown} alt="icon" />}
                onChange={handleChangeTalent}
                value={filter.talent}
                allowClear={true}
                clearIcon={<img src={IconCancel} alt="cancel" />}
              />
            </div>
            <div className="filter-block filter-league filter-select">
              <div className="filter-name">League</div>
              <Select
                placeholder="Select"
                dropdownClassName="dropdown-memu"
                options={LEAGUE_OPTIONS}
                suffixIcon={<img src={IconArrowDown} alt="icon" />}
                onChange={handleChangeLeague}
                value={filter.league}
                allowClear={true}
                clearIcon={<img src={IconCancel} alt="cancel" />}
              />
            </div>
            <div className="filter-block filter-club filter-select">
              <div className="filter-name">Club</div>
              <Select
                placeholder="Select"
                dropdownClassName="dropdown-memu"
                options={CLUB_OPTIONS}
                suffixIcon={<img src={IconArrowDown} alt="icon" />}
                onChange={handleChangeClub}
                value={filter.club}
                allowClear={true}
                clearIcon={<img src={IconCancel} alt="cancel" />}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
