import BronzeBox from "assets/images/wallet/bronze_box.png";
import SilverBox from "assets/images/wallet/silver_box.png";
import GoldBox from "assets/images/wallet/gold_box.png";

export const BSC_MAINNET = "bsc_mainnet";
export const BSC_TESTNET = "bsc_testnet";
export const BSC =
  localStorage.getItem("network") === "testnet" ? BSC_TESTNET : BSC_MAINNET;

export const NETWORKS = {
  [BSC_MAINNET]: 56,
  [BSC_TESTNET]: 97,
};

export const NETWORK_INFO = {
  [NETWORKS[BSC_TESTNET]]: [
    {
      chainId: "0x61",
      chainName: "Binance Smart Chain Testnet",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://data-seed-prebsc-2-s2.binance.org:8545/"],
      blockExplorerUrls: ["https://testnet.bscscan.com/"],
    },
  ],
  [NETWORKS[BSC_MAINNET]]: [
    {
      chainId: "0x38",
      chainName: "Smart Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com"],
    },
  ],
};

export const GENERAL = "GENERAL";
export const ADVANCED = "ADVANCED";
export const PLAYERS = "PLAYERS";
export const TOP_PLAYERS = "TOP_PLAYERS";
export const BOX = "BOX";
export const GAME_BOX = "GAME_BOX";
export const BINANCE_LEGENDARY = "BINANCE_LEGENDARY";
export const VAULT_NFT = "VAULT_NFT";
export const TOP100 = "TOP100";

export const POSITION_OPTIONS = [
  { label: "GK", value: "GK" },
  { label: "LWB", value: "LWB" },
  { label: "CAM", value: "CAM" },
  { label: "CB", value: "CB" },
  { label: "RM", value: "RM" },
  { label: "CDM", value: "CDM" },
  { label: "RB", value: "RB" },
  { label: "CM", value: "CM" },
  { label: "LB", value: "LB" },
  { label: "CF", value: "CF" },
  { label: "RWB", value: "RWB" },
  { label: "LM", value: "LM" },
  { label: "LW", value: "LW" },
  { label: "RW", value: "RW" },
  { label: "ST", value: "ST" },
];

export const SPECIES_OPTIONS = [
  { label: "Gaur", value: "Gaur" },
  { label: "Gallic Rooster", value: "Gallic Rooster" },
  { label: "Fox", value: "Fox" },
  { label: "Shepherd Dog", value: "Shepherd Dog" },
  { label: "Lion", value: "Lion" },
  { label: "Jaguar", value: "Jaguar" },
  // { label: "Deer", value: "Deer" },
  { label: "Wolf", value: "Wolf" },
  { label: "Tiger", value: "Tiger" },
  // { label: "Cat", value: "Cat" },
  // { label: "Horse", value: "Horse" },
  // { label: "Rabbit", value: "Rabbit" },
  { label: "Bear", value: "Bear" },
  // { label: "Rat", value: "Rat" },
  // { label: "Eagle", value: "Eagle" },
  // { label: "Weasel", value: "Weasel" },
  // { label: "Elephant", value: "Elephant" },
  { label: "Lynx", value: "Lynx" },
  // { label: "Monkey", value: "Monkey" },
  // { label: "Antilope", value: "Antilope" },
];

export const CLUB_OPTIONS = [
  { label: "Badgers Minga", value: "Badgers Minga" },
  { label: "Mallardster City FC", value: "Mallardster City FC" },
  { label: "Lionpawn FC", value: "Lionpawn FC" },
  { label: "Shellcea FC", value: "Shellcea FC" },
  { label: "FC Barcino", value: "FC Barcino" },
  { label: "Parrot Saint-Gerbil", value: "Parrot Saint-Gerbil" },
  { label: "Royal Marmot CF", value: "Royal Marmot CF" },
  { label: "Jellyfish", value: "Jellyfish" },
  { label: "Club Albatros de Marmot", value: "Club Albatros de Marmot" },
  { label: "Mallardster United", value: "Mallardster United" },
  { label: "AS Rams", value: "AS Rams" },
  { label: "Red Admiral FC", value: "Red Admiral FC" },
  { label: "A.C. Molly", value: "A.C. Molly" },
  // { label: "Tortoise and Hopper", value: "Tortoise and Hopper" },
  // { label: "Seal villa FC", value: "Seal villa FC" },
  // { label: "Babirusa Dolphin", value: "Babirusa Dolphin" },
  // { label: "FC Potoroo", value: "FC Potoroo" },
  // { label: "FC Shark Dragonfish", value: "FC Shark Dragonfish" },
  // { label: "RB Leopard", value: "RB Leopard" },
  // { label: "AFC Addax", value: "AFC Addax" },
  // { label: "FC Salmon", value: "FC Salmon" },
  // { label: "Vizsla Royal CF", value: "Vizsla Royal CF" },
  // { label: "Olympique Llama", value: "Olympique Llama" },
  // { label: "SSC Nightingale", value: "SSC Nightingale" },
  // { label: "Alabai BC", value: "Alabai BC" },
  // {
  //   label: "FC Internazionale Millipede",
  //   value: "FC Internazionale Millipede",
  // },
  // { label: "SL Bengal Tiger", value: "SL Bengal Tiger" },
  // { label: "FC Basenji 1893", value: "FC Basenji 1893" },
  // { label: "SS Lhasapoo", value: "SS Lhasapoo" },
  // { label: "FC Zebra", value: "FC Zebra" },
  // { label: "FC Dynamo Kiwi", value: "FC Dynamo Kiwi" },
];

export const TALENT_OPTIONS = [
  { label: "Scissors Feint", value: "Scissors Feint" },
  { label: "Finesse Shot", value: "Finesse Shot" },
  { label: "Power Header", value: "Power Header" },
  { label: "Long Passer", value: "Long Passer" },
  { label: "Fancy Passes", value: "Fancy Passes" },
  { label: "Marseille Turn", value: "Marseille Turn" },
  { label: "Sombrero", value: "Sombrero" },
  { label: "Knuckle Shot", value: "Knuckle Shot" },
  { label: "Heel Trick", value: "Heel Trick" },
  { label: "Rabona", value: "Rabona" },
  { label: "Injury Prone", value: "Injury Prone" },
  { label: "Solid Player", value: "Solid Player" },
  { label: "Second Wind", value: "Second Wind" },
  { label: "Leadership", value: "Leadership" },
  { label: "Captaincy", value: "Captaincy" },
  { label: "Fighting Spirit", value: "Fighting Spirit" },
  { label: "Teamplayer", value: "Teamplayer" },
  { label: "Super-sub", value: "Super-sub" },
  { label: "Speed Dribbler", value: "Speed Dribbler" },
  { label: "Long Shot Taker", value: "Long Shot Taker" },
  { label: "Play Maker", value: "Play Maker" },
  { label: "Poacher", value: "Poacher" },
  { label: "Engine", value: "Engine" },
  { label: "Tackler", value: "Tackler" },
  { label: "Speedster", value: "Speedster" },
  { label: "Tactician", value: "Tactician" },
  { label: "Penalty Taker", value: "Penalty Taker" },
  { label: "GK Rushes Out", value: "Rushes Out" },
  { label: "GK Cautious", value: "Cautious" },
  { label: "GK Foot save", value: "Foot save" },
  { label: "GK Flat kick", value: "Flat kick" },
  { label: "GK Long Thrower", value: "Long Thrower" },
  { label: "GK Penalty Saver", value: "Penalty Saver" },
];

export const LEAGUE_OPTIONS = [
  { label: "Panthera League", value: "Panthera League" },
  { label: "Maestro Liger", value: "Maestro Liger" },
  { label: "Bulldog Liga", value: "Bulldog Liga" },
  { label: "Saiga A", value: "Saiga A" },
  { label: "Gallus 1", value: "Gallus 1" },
];

export const SELL_TYPE = [
  { value: 1, label: "Fix Price" },
  { value: 2, label: "Auction" },
];

export const BRONZE = 1;
export const SILVER = 2;
export const GOLD = 3;

export const TEXT_BOX = {
  1: "bronze",
  2: "silver",
  3: "gold",
};

export const IMG_BOX = {
  1: BronzeBox,
  2: SilverBox,
  3: GoldBox,
};

export const MAX_BOX_OPEN = 5;

export const REGEX_INPUT_NUMBER = /^$|^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/;
export const REGEX_QUANTITY = /^[1-9]\d*$/;

export const KEY_INVALID_INPUT = ["e", "-"];

export const TAB_ATTRIBUTE = [
  {
    label: "Original",
    value: "original",
  },
  {
    label: "Current",
    value: "current",
  },
];
