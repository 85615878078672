import React, { useEffect, useState } from "react";
import { Col, Row, Container, Table } from "react-bootstrap";
import http from "utils/http";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import { PLAYER_CONTRACT, RPC } from "consts";
import { numberToShortString, thounsandSeparators, numberToShortStringObject } from "utils/helper";
import { VAULT_NFT, TOP100 } from "utils/constants";
import { useWeb3React } from "@web3-react/core";
import CountUp from "react-countup";

const web3 = new Web3(RPC);

const allowcation = {
  1	:	1500	,
2	:	1500	,
3	:	1500	,
4	:	700	,
5	:	700	,
6	:	700	,
7	:	700	,
8	:	700	,
9	:	700	,
10	:	700	,
11	:	500	,
12	:	500	,
13	:	500	,
14	:	500	,
15	:	500	,
16	:	300	,
17	:	300	,
18	:	300	,
19	:	300	,
20	:	300	,
21	:	150	,
22	:	150	,
23	:	150	,
24	:	150	,
25	:	150	,
26	:	150	,
27	:	150	,
28	:	150	,
29	:	150	,
30	:	150	,
31	:	150	,
32	:	150	,
33	:	150	,
34	:	150	,
35	:	150	,
36	:	150	,
37	:	150	,
38	:	150	,
39	:	150	,
40	:	100	,
41	:	100	,
42	:	100	,
43	:	100	,
44	:	100	,
45	:	100	,
46	:	100	,
47	:	100	,
48	:	100	,
49	:	100	,
50	:	100	,
51	:	100	,
52	:	100	,
53	:	100	,
54	:	100	,
55	:	100	,
56	:	100	,
57	:	100	,
58	:	100	,
59	:	100	,
60	:	100	,
61	:	60	,
62	:	60	,
63	:	60	,
64	:	60	,
65	:	60	,
66	:	60	,
67	:	60	,
68	:	60	,
69	:	60	,
70	:	60	,
71	:	40	,
72	:	40	,
73	:	40	,
74	:	40	,
75	:	40	,
76	:	40	,
77	:	40	,
78	:	40	,
79	:	40	,
80	:	40	,
81	:	40	,
82	:	40	,
83	:	40	,
84	:	40	,
85	:	40	,
86	:	30	,
87	:	30	,
88	:	30	,
89	:	30	,
90	:	30	,
91	:	30	,
92	:	30	,
93	:	30	,
94	:	30	,
95	:	30	,
96	:	30	,
97	:	30	,
98	:	30	,
99	:	30	,
100	:	30	,
};

const Top100 = ({ isOpen }) => {
  const { account, library, chainId } = useWeb3React();
  const [addresses, setAddresses] = useState([]);
  const [filterAddresses, setFilterAddresses] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [ranking, setRanking] = useState(null);

  useEffect(() => {
    async function init() {
      const result = await http.get("/api/v1/vault/top100");
      if (result?.tops) {
        const rs = result?.tops.map((rs, index) => {
          return {
            ...rs,
            index: index + 1
          };
        })
        setAddresses(rs);
        if (inputValue) {
          setFilterAddresses(
            rs.filter((a) => a.address?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
          )
        } else {
          setFilterAddresses(rs);
        }

        if (account) {
          const index = rs.findIndex((v) => v.address === account);
          if (index > -1) {
            setRanking(index + 1);
          } else {
            setRanking("> 100");
          }
        }

      }
    }

    init();
    const interval = setInterval(init, 15*1000);
    return () => {
      clearInterval(interval);
    }
  }, [inputValue, account]);

  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
    setFilterAddresses(
      addresses.filter((a) => a.address?.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
    )
  }

  return (
    <Container className="top">
      <div className="search">
          <input type="text" placeholder="Search for address.." onChange={handleChangeInput} />
      </div>
      <div className="note">
        { ranking && <div> Your ranking: {ranking} </div> }
        <div className="note-txt"> * Total deposit is the total amount of RU staked and the value of NFT when converted to RU </div>
      </div>
      <div className="top100">
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th className="index">#</th>
              <th className="index"> Address </th>
              <th className="index"> Total Deposit (in RU) </th>
              <th className="index"> RIFI Whitelist Allocation </th>
            </tr>
          </thead>
          <tbody>
            {
              filterAddresses.map((address, index) => {
                const { value, suffix } = numberToShortStringObject(parseFloat(web3.utils.fromWei(address.amount)));
                return (
                  <tr>
                    <td className="index"> { address.index } </td>
                    <td> { address.address } </td>
                    <td className="index">
                    <CountUp
                      start={0}
                      end={value}
                      duration={1}
                      separator=","
                      decimals={1}
                      decimal="."
                      suffix={suffix}
                    />
                       {/* { numberToShortString(parseFloat(web3.utils.fromWei(address.amount))) }  */}
                      </td>
                    <td className="index"> ${ thounsandSeparators(allowcation[address.index]) } </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
    </div>
    </Container> 
    
  );
};

export default Top100;
