import React, { useState } from "react";
import img_title from "assets/images/vault/title.png";
import img_bale from "assets/images/vault/bale.png";
import img_rooney from "assets/images/vault/rooney.png";
import img_farming from "assets/images/vault/lp-farming.png";
import nft_farming from "assets/images/vault/nft-farming.png";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import Countdown from "components/TheCountdown/TheCountdown";

const openTime = 1637049600000;

function Vault() {
  const history = useHistory();
  const param = useParams();
  // const isForceStarted = param && param.id === "ru";
  const isForceStarted = true;
  const [isCountDown, setIsCountDown] = useState(
    new Date() < new Date(openTime)
  );

  const goToCasillasVault = () => {
    history.push("/vault/lp-farming");
  };

  const onCountdownComplete = () => {
    setIsCountDown(false);
  };

  return (
    <div className="vault">
      <div className="vault-banner">
        <div className="vault-title">
          <img src={img_title} alt="VAULT" />
        </div>
      </div>
      <div className="vault-list">
        {isCountDown ? (
          <Countdown time={openTime} countdownComplete={onCountdownComplete} />
        ) : (
          <Container>
            <Row>
              <Col xs={12} md={6} lg={4}>
                <div className="vault-item">
                  <div className="vault-item__top">
                    <div className="vault-item__img">
                      <img src={img_farming} alt="" />
                      <div className="vault-item__title">LP Farming</div>
                    </div>
                  </div>
                  <div className="vault-item__bottom">
                    <div className="vault-item__info">
                      {/* <div className="info-block">
                        <div className="info-name">TVL:</div>
                        <div className="info-value">1,000,000 USD</div>
                      </div>
                      <div className="info-block">
                        <div className="info-name">Rewards:</div>
                        <div className="info-value">1 RU/BLOCK</div>
                      </div> */}
                    </div>
                    <div className="vault-item__action">
                      <button
                        className="app-btn app-btn__primary"
                        onClick={goToCasillasVault}
                      >
                        SELECT
                      </button>
                    </div>
                    <div className="vault-item__desc">
                      Stake RU - BUSD LP Tokens to earn RU
                    </div>
                  </div>
                </div>
              </Col>
              {isForceStarted && (
                <Col xs={12} md={6} lg={4}>
                  <div className="vault-item">
                    <div className="vault-item__top">
                      <div className="vault-item__img">
                        <img src={nft_farming} alt="" />
                        <div className="vault-item__title">RU-NFT Farming</div>
                      </div>
                    </div>
                    <div className="vault-item__bottom">
                      <div className="vault-item__info"></div>
                      <div className="vault-item__action">
                        <button
                          className="app-btn app-btn__primary"
                          onClick={() => {
                            history.push("/vault/nft-farming");
                          }}
                        >
                          SELECT
                        </button>
                      </div>
                      <div className="vault-item__desc">
                        Stake RU Tokens and NFT to earn RU
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
}

export default Vault;
