import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import Switch from "react-switch";
import Pagination from "components/Common/Pagination";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { PLAYER_BOX_CONTRACT, RU_BOX_MARKETPLACE } from "consts";
import IconInfo from "assets/icons/ic_info.svg";
import { Button, Tooltip } from "antd";
import { getMarketBoxData } from "utils/client_api";
import { weiToETH } from "utils/helper";
import ModalOpenBox from "./ModalOpenBox";
import ModalSell from "./ModalSell";
import { BRONZE, GOLD, IMG_BOX, SILVER, TEXT_BOX } from "utils/constants";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const Box = ({ handleChangeTab }) => {
  const [boxBalance, setBoxBalance] = useState({
    bronze: 0,
    silver: 0,
    gold: 0,
  });

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [boxOnsale, setBoxOnsale] = useState([]);
  const { account, library } = useWeb3React();
  const [showModalSell, setShowModalSell] = useState(false);
  const [showModalOpenBox, setShowModalOpenBox] = useState(false);
  const [typeBoxSelect, setTypeBoxSelect] = useState();
  const [fetchNewData, setFetchNewData] = useState(0);
  const [isLoading, setIsLoading] = useState({});
  const [totalBoxOnSale, setTotalBoxOnSale] = useState();
  const [renderBoxOnSale, setRenderBoxOnSale] = useState();
  const [filters, setFilters] = useState({
    currentPage: 1,
    isOnSale: 0,
    ...parsed,
  });
  const pageSize = 9;

  const PushQuery = (modifiedQuery, isSearch = true) => {
    if (isSearch === false) {
      history.push({
        pathname: location.pathname,
        search: null,
      });
    } else {
      const parsed = queryString.parse(location.search);
      let search = { ...parsed, ...modifiedQuery };
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(search),
      });
    }
  };

  const handleChangeOnsaleFilter = (value) => {
    setFilters((prev) => ({
      ...prev,
      isOnSale: value ? 1 : 0,
    }));
    PushQuery({ isOnSale: value ? 1 : 0 });
  };

  const handleCloseModalOpenBox = () => {
    setTypeBoxSelect();
    setShowModalOpenBox(false);
  };

  const handleCloseModalSell = () => {
    setTypeBoxSelect();
    setShowModalSell(false);
  };

  const handleShowModalSell = (type) => {
    setTypeBoxSelect(type);
    setShowModalSell(true);
  };

  const handleShowModalOpenBox = (type) => {
    setTypeBoxSelect(type);
    setShowModalOpenBox(true);
  };

  const handleFetchNewData = () => {
    setFetchNewData((v) => v + 1);
  };

  useEffect(() => {
    async function init(typeBox) {
      if (account && library && library.provider) {
        const web3 = new Web3(library.provider);
        const playerBoxContract = new web3.eth.Contract(
          PLAYER_BOX_CONTRACT.ABI,
          PLAYER_BOX_CONTRACT.ADDRESS
        );
        const balance = await playerBoxContract.methods
          .balanceOf(account, typeBox)
          .call();
        switch (typeBox) {
          case BRONZE:
            setBoxBalance((prev) => ({
              ...prev,
              bronze: parseInt(balance),
            }));
            break;
          case SILVER:
            setBoxBalance((prev) => ({
              ...prev,
              silver: parseInt(balance),
            }));
            break;
          case GOLD:
            setBoxBalance((prev) => ({
              ...prev,
              gold: parseInt(balance),
            }));
            break;

          default:
            break;
        }
      }
    }
    init(BRONZE);
    init(SILVER);
    init(GOLD);
  }, [account, library, fetchNewData]);

  useEffect(() => {
    //handleGetList Sale
    async function getMarketBoxOnsale() {
      const data = await getMarketBoxData({
        seller: account,
        page: 1,
      });
      if (data) {
        setBoxOnsale(data.markets);
        setTotalBoxOnSale(data.total);
      }
    }
    account && getMarketBoxOnsale();
  }, [filters.isOnSale, account, fetchNewData]);

  useEffect(() => {
    const { currentPage } = filters;
    const listBox = boxOnsale.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    setRenderBoxOnSale(listBox);
  }, [boxOnsale, filters.currentPage]);

  const handleChangeCurrentPage = (page) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: page,
    }));
    PushQuery({ currentPage: page });
  };

  const history = useHistory();

  const handleDelist = async (tokenId, price) => {
    if (account && library && library.provider) {
      setIsLoading({
        [tokenId]: true,
      });
      const web3 = new Web3(library.provider);
      const ruBoxMarketplaceContract = new web3.eth.Contract(
        RU_BOX_MARKETPLACE.ABI,
        RU_BOX_MARKETPLACE.ADDRESS
      );
      try {
        const result = await ruBoxMarketplaceContract.methods
          .cancelAuction(tokenId, price)
          .send({
            from: account,
          });

        if (result) {
          setTimeout(handleFetchNewData, 5000);
        }
      } catch (error) {}
      setIsLoading({
        [tokenId]: false,
      });
    }
  };

  const listBox = [
    { name: TEXT_BOX[BRONZE], id: BRONZE },
    { name: TEXT_BOX[SILVER], id: SILVER },
    { name: TEXT_BOX[GOLD], id: GOLD },
  ];

  return (
    <div>
      <Row className="filter">
        <Col className="onSale">
          <div className="filter-name">On Sale</div>
          <Switch
            onChange={handleChangeOnsaleFilter}
            checked={Boolean(Number(filters.isOnSale))}
            onColor="#fcb100"
            onHandleColor="#ffffff"
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={40}
            className="react-switch"
          />
        </Col>
        <Col className="total_box">
          Total Box:{" "}
          {Number(filters.isOnSale) ? (
            <span className="value">{totalBoxOnSale}</span>
          ) : (
            <span className="value">
              {parseInt(boxBalance.bronze) +
                parseInt(boxBalance.silver) +
                parseInt(boxBalance.gold)}
            </span>
          )}
          {!Number(filters.isOnSale) && (
            <span className="tooltip-info">
              <Tooltip
                // color="#fcb100"
                placement="top"
                overlayClassName="tooltip-total-box"
                title={() => {
                  const text = `Bronze: <span class='number'>${boxBalance.bronze}</span>, Silver: <span class='number'>${boxBalance.silver}</span>, Gold: <span class='number'>${boxBalance.gold}</span>`;
                  return (
                    <div
                      className="tooltip-text"
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></div>
                  );
                }}
              >
                <img src={IconInfo} alt="" />
              </Tooltip>
            </span>
          )}
        </Col>
      </Row>
      <div>
        {Number(filters.isOnSale) ? (
          <div>
            <Row>
              {renderBoxOnSale?.map((box) => {
                const type = parseInt(box.tokenId);
                return (
                  <Col
                    key={TEXT_BOX[type]}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    className="col-item"
                  >
                    <div className="box-wrap">
                      <div className={`box-game-card ${TEXT_BOX[type]}`}>
                        <img src={IMG_BOX[type]} alt="" />
                        <div className="name-box">{TEXT_BOX[type]} BOX</div>
                      </div>
                      <div className="amount_box">
                        <span className="number">
                          Price: {weiToETH(box.price)} RU{" "}
                        </span>
                      </div>
                      <div className="amount_box">
                        <span className="number">
                          Amount: {box.amount} BOX{" "}
                        </span>
                      </div>
                      <div className="box-delist">
                        <Button
                          className="app-btn app-btn__primary"
                          onClick={() => handleDelist(box.tokenId, box.price)}
                          loading={isLoading[box.tokenId]}
                        >
                          DELIST
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Pagination
              currentPage={Number(filters.currentPage)}
              pages={Math.ceil(totalBoxOnSale / pageSize)}
              onPageChange={handleChangeCurrentPage}
            />
          </div>
        ) : (
          <Row>
            {listBox.map((item, index) => {
              return (
                <Col
                  key={index}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  className="col-item"
                >
                  <div className="box-wrap">
                    <div className={`box-game-card ${item.name}`}>
                      <img src={IMG_BOX[item.id]} alt="" />
                      <div className="name-box">{item.name} BOX</div>
                    </div>
                    <div className="amount_box">
                      <span className="number">{boxBalance[item?.name]}</span>{" "}
                      BOX
                    </div>
                    {boxBalance[item?.name] > 0 ? (
                      <div className="actions">
                        <Button
                          className="app-btn app-btn__primary"
                          onClick={() => handleShowModalSell(item.name)}
                        >
                          LIST
                        </Button>
                        <Button
                          className="app-btn app-btn__primary"
                          onClick={() => handleShowModalOpenBox(item.name)}
                        >
                          OPEN
                        </Button>
                      </div>
                    ) : (
                      <div style={{ minHeight: "50px" }}></div>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
      <ModalOpenBox
        visible={showModalOpenBox}
        handleCloseModal={handleCloseModalOpenBox}
        type={typeBoxSelect}
        handleFetchNewData={handleFetchNewData}
        totalBox={boxBalance[typeBoxSelect]}
        handleChangeTab={handleChangeTab}
      />
      <ModalSell
        visible={showModalSell}
        handleCloseModal={handleCloseModalSell}
        handleFetchNewData={handleFetchNewData}
        totalBox={boxBalance[typeBoxSelect]}
        type={typeBoxSelect}
      />
    </div>
  );
};

export default Box;
