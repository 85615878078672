import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Web3 from "web3";
import { injected } from "../utils/connectors";
import Logo from "assets/images/logo.png";
import { useEagerConnect, useInactiveListener } from "utils/hooks";
import { RU_CONTRACT } from "consts";
import RuIcon from "../assets/icons/ic_ru.png";
import { handleSwitchNetwork } from "utils/services";
import { NETWORKS, BSC } from "utils/constants";
import { Modal } from "antd";
import { IconContext } from "react-icons";
import { BsFillCaretDownFill } from "react-icons/bs";
import queryString from "query-string";

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const parseLocation = queryString.parse(location.search);
  const isShowChooseNetworkBtn =
    parseLocation && parseLocation.choose_network == "true";
  const [accountBalanceRU, setAccountBalanceRU] = useState(null);
  const { activate, account, connector, library, error, deactivate } =
    useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const [warning, setWarning] = useState(false);
  const [isDisconnectWallet, setIsDisconnectWallet] = useState(false);
  const connectWalletRef = useRef();

  useEffect(() => {
    const handleHideAccountPopup = (e) => {
      if (!connectWalletRef.current?.contains(e.target)) {
        setIsDisconnectWallet(false);
      }
    };
    document.addEventListener("click", handleHideAccountPopup);

    return () => document.removeEventListener("click", handleHideAccountPopup);
  }, []);

  const DisconnectWallet = async () => {
    setIsDisconnectWallet(false);
    deactivate();
  };

  const addConstract = async () => {
    const web3 = new Web3(library.provider);
    const ruContract = new web3.eth.Contract(
      RU_CONTRACT.ABI,
      RU_CONTRACT.ADDRESS
    );
    const symbol = await ruContract.methods.symbol().call();
    const decimals = await ruContract.methods.decimals().call();
    try {
      await library.provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: RU_CONTRACT.ADDRESS,
            symbol,
            decimals: parseInt(decimals),
            image:
              "https://marketplace.rifiunited.io/static/media/ic_ru.b8ed80a5.svg",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleConnect = () => {
    if (!window.ethereum) {
      setWarning(true);
    }
    activate(injected);
  };

  React.useLayoutEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const handleSwitchNetworkBtn = (network) => {
    localStorage.setItem("network", network);
    window.location.reload();
  };

  const renderChooseNetwork = () => {
    if (parseLocation && parseLocation.choose_network == "true") {
      return (
        <>
          <div> Current Network: {localStorage.getItem("network")} </div>
          <Button onClick={() => handleSwitchNetworkBtn("testnet")}>
            {" "}
            Testnet{" "}
          </Button>
          <Button onClick={() => handleSwitchNetworkBtn("test_mainnet")}>
            {" "}
            Test Mainnet{" "}
          </Button>
          <Button onClick={() => handleSwitchNetworkBtn("mainnet")}>
            {" "}
            Mainnet{" "}
          </Button>
        </>
      );
    }

    return null;
  };

  React.useEffect(() => {
    const getAccount = async () => {
      if (account && library && library.provider) {
        const web3 = new Web3(library.provider);
        const ruContract = new web3.eth.Contract(
          RU_CONTRACT.ABI,
          RU_CONTRACT.ADDRESS
        );
        const balanceRU = web3.utils.fromWei(
          await ruContract.methods.balanceOf(account).call()
        );
        setAccountBalanceRU(balanceRU);
      }
    };

    // handleSwitchNetwork(NETWORKS[BSC_TESTNET]);
    // handleSwitchNetwork(NETWORKS[BSC]);
    getAccount();
    const interval = setInterval(getAccount, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [account, library]);

  return (
    <div className="header">
      <Container>
        <Navbar expand="lg" className="nav" variant="dark">
          <Navbar.Brand className="logo">
            <a href="https://www.rifiunited.io/">
              <img src={Logo} alt=" logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="nav-toggler"
            // onClick={()=> }
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="w-100 d-flex align-items-end">
              {/* <NavLink to={"/dashboard"} exact className="nav-link">
                <span className="nav-item-text nav-hover">Dashboard</span>
              </NavLink> */}
              <NavLink to={"/marketplace"} exact className="nav-link">
                <span className="nav-item-text nav-hover">Marketplace</span>
              </NavLink>
              <NavLink to={"/wallet"} exact className="nav-link">
                <span className="nav-item-text nav-hover">Wallet</span>
              </NavLink>
              <NavLink to={"/vault"} exact className="nav-link">
                <span className="nav-item-text nav-hover">Vault</span>
              </NavLink>
              {isShowChooseNetworkBtn && renderChooseNetwork()}
              {account ? (
                <div className="account account-info">
                  <div className="account-balance-ru" onClick={addConstract}>
                    {Math.round(accountBalanceRU * 10000) / 10000}
                    <img src={RuIcon} alt="RU" className="coin-icon" />
                  </div>
                  <div
                    className="account-address"
                    onClick={() => setIsDisconnectWallet(!isDisconnectWallet)}
                    ref={connectWalletRef}
                  >
                    {`${account.substring(0, 6)}...${account.substring(
                      account.length - 4
                    )}`}
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "1rem",
                      }}
                    >
                      <BsFillCaretDownFill />
                    </IconContext.Provider>
                    {isDisconnectWallet ? (
                      <div className="disconnect-wallet">
                        <button onClick={DisconnectWallet}>Disconnect</button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : error && error instanceof UnsupportedChainIdError ? (
                <div
                  className="account connect-btn wrong"
                  onClick={() => {
                    handleSwitchNetwork(NETWORKS[BSC]);
                  }}
                >
                  Wrong network
                </div>
              ) : (
                <div className="account connect-btn" onClick={handleConnect}>
                  Connect wallet
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <Modal
        className="app-modal modal-form"
        title={null}
        visible={warning}
        onCancel={() => {
          setWarning(false);
        }}
        centered
        footer={null}
        closable={false}
      >
        <div style={{ color: "tomato" }}>
          Please download MetaMask.{" "}
          <span>
            <a href="https://metamask.io/download.html" target={"_blank"}>
              Download MetaMask here.
            </a>
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
