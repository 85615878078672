import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import IconList from "assets/images/detail_footballer/list.svg";
import IconDelist from "assets/images/detail_footballer/delist.svg";
import IconSold from "assets/images/detail_footballer/sold.svg";
import IconMint from "assets/images/detail_footballer/mint.svg";
import { getMarketData } from "../../utils/client_api";
import {
  formatTime,
  formatTokenAddress,
  thounsandSeparators,
  formatRUDecimals,
} from "../../utils/helper";
import Pagination from "components/Common/Pagination";
import { EVENT } from "consts";

const iconMap = {
  List: IconList,
  Delist: IconDelist,
  Sold: IconSold,
  Mint: IconMint,
};

const SaleHistory = ({ tokenId }) => {
  const [saleHistoryData, setSaleHistoryData] = useState();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const itemPerPage = 5;

  const handlePagigation = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const SaleHistoryData = async () => {
      const result = await getMarketData({
        sortBy: "timeline",
        orderBy: "desc",
        tokenId: tokenId,
        page: page,
        limit: itemPerPage,
      });
      if (result) {
        setSaleHistoryData(result.markets);
        setPages(Math.ceil(result.total / itemPerPage));
      } else {
        setTimeout(() => SaleHistoryData(), 1000 * 5);
      }
    };
    SaleHistoryData();
    const interval = setInterval(SaleHistoryData, 1000 * 10);
    return () => clearInterval(interval);
    // SaleHistoryData();
  }, [page, tokenId]);

  const columns = [
    {
      Header: "Event",
      accessor: "event",
      Cell: ({ original }) => {
        return (
          <div className="sale-history__events">
            <img src={iconMap[original?.event]} alt="icon" />
            <div>{original?.event}</div>
          </div>
        );
      },
    },
    {
      Header: "From",
      accessor: "seller",
      Cell: ({ original }) => {
        let from;
        if (original?.event === EVENT.Mint) {
          from = "0x0000";
        } else {
          from = original?.seller;
        }
        return <div>{formatTokenAddress(from) || "--"}</div>;
      },
    },
    {
      Header: "To",
      accessor: "winner",
      Cell: ({ original }) => {
        let to;
        if (original?.event === EVENT.Mint) {
          to = original?.minter;
        } else {
          to = original?.winner;
        }
        return <div>{formatTokenAddress(to) || "--"}</div>;
      },
    },
    {
      Header: "Price",
      accessor: "price",
      width: 150,
      Cell: ({ original }) => {
        const event = original?.event;
        let price;
        if (event === "List" || event === "Delist" || event === "Mint")
          price = original?.startingPrice;
        if (event === "Sold") price = original?.finalPrice;

        return (
          <div>{thounsandSeparators(formatRUDecimals(price)) || "--"} RU</div>
        );
      },
    },
    {
      Header: "Time",
      accessor: "time",
      Cell: ({ original }) => {
        return <div>{formatTime(original?.timeline) || "--"}</div>;
      },
    },
  ];

  return (
    <div className="sale-history">
      <div className="title">SALE HISTORY</div>
      <ReactTable
        className="sale-table"
        data={saleHistoryData}
        columns={columns}
        loading={false}
        defaultPageSize={itemPerPage}
        minRows={1}
        noDataText="No Data"
        manual
        currentPage={page}
        pages={pages}
        pageSizeOptions={[10, 20, 50, 100]}
        onPageChange={handlePagigation}
        PaginationComponent={Pagination}
      />
    </div>
  );
};

export default SaleHistory;
