import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
const Pagination = (props) => {
  const { currentPage, pages, onPageChange, siblingCount = 1 } = props;

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = () => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= pages) {
      return range(1, pages);
    }
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, pages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, "...", pages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(pages - rightItemCount + 1, pages);
      return [firstPageIndex, "...", ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, "...", ...middleRange, "...", lastPageIndex];
    }
  };

  const visiblePages = paginationRange();

  const changePage = (page) => {
    onPageChange(page);
  };

  return visiblePages?.length > 0 ? (
    <ul className="pagination pull-right">
      <div
        className={`prev-page ${currentPage === 1 ? "disable" : "active"}`}
        onClick={() => {
          if (currentPage === 1) return;
          changePage(currentPage - 1);
        }}
      >
        Prev
      </div>
      {visiblePages.map((page, index, array) => {
        return (
          <Fragment key={index}>
            <li
              key={index}
              className={cx("page-item", { active: page === currentPage })}
            >
              <div
                className="btn btn-sm btn-primary"
                onClick={() => {
                  page !== "..." && changePage(page);
                }}
              >
                {page}
              </div>
            </li>
          </Fragment>
        );
      })}
      <div
        className={`next-page ${currentPage === pages ? "disable" : "active"}`}
        onClick={() => {
          if (currentPage === pages) return;
          changePage(currentPage + 1);
        }}
      >
        Next
      </div>
    </ul>
  ) : (
    false
  );
};

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  siblingCount: PropTypes.number,
};

export default Pagination;
