import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BronzeBox from "assets/images/wallet/bronze_box.png";
import SilverBox from "assets/images/wallet/silver_box.png";
import GoldBox from "assets/images/wallet/gold_box.png";
import { useHistory } from "react-router-dom";
import Pagination from "components/Common/Pagination";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button, Select } from "antd";
import ic_filter from "../../assets/icons/filter.svg";
import { getMarketBoxData } from "utils/client_api";
import { weiToETH, formatTokenAddress } from "utils/helper";
import ModalConfirmBuy from "./ModalConfirmBuy";
import { useWeb3React } from "@web3-react/core";

const TEXT_BOX = {
  1: "bronze",
  2: "silver",
  3: "gold",
};

const IMG_BOX = {
  1: BronzeBox,
  2: SilverBox,
  3: GoldBox,
};

const GameBoxList = () => {
  const history = useHistory();
  const { Option } = Select;
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [filters, setFilters] = useState({
    currentPage: 1,
    limit: 9,
    ...parsed,
  });
  const [boxes, setBoxes] = useState([]);
  const [showModalConfirmBuy, setShowModalConfirmBuy] = useState(false);
  const [boxInfo, setBoxInfo] = useState({});
  const [fetchNewData, setFetchNewData] = useState(0);
  const [totalBox, setTotalBox] = useState();
  const { account } = useWeb3React();

  const PushQuery = (modifiedQuery, isSearch = true) => {
    if (isSearch === false) {
      history.push({
        pathname: location.pathname,
        search: null,
      });
    } else {
      const parsed = queryString.parse(location.search);
      let search = { ...parsed, ...modifiedQuery };
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(search),
      });
    }
  };

  const handleChangeCurrentPage = (page) => {
    setFilters(() => ({
      ...filters,
      currentPage: page,
    }));
    PushQuery({ currentPage: page });
  };

  const handleSort = () => {};

  const handleShowModalBuy = (box) => {
    setBoxInfo({ ...box });
    setShowModalConfirmBuy(true);
  };

  const handleCloseModalConfirmBuy = () => {
    setShowModalConfirmBuy(false);
  };

  const handleFetchNewData = () => {
    setFetchNewData((v) => v + 1);
  };

  useEffect(() => {
    async function getListBox() {
      const data = await getMarketBoxData({
        page: filters.currentPage,
        ...filters,
      });
      if (data) {
        setBoxes(data.markets);
        setTotalBox(data.total);
      }
    }

    getListBox();
  }, [filters, fetchNewData]);

  return (
    <div className="game-box-list">
      <Container>
        <Row>
          <div className="wallet-sort">
            <div className="select-group">
              <div className="select-pretext">
                <img src={ic_filter} alt="sort" />
                Sort by:
              </div>
              <Select defaultValue="startedAt-desc" onChange={handleSort}>
                <Option value="startedAt-desc">Latest</Option>
                <Option value="recommended-asc">Recommended</Option>
                <Option value="startingPrice-asc">Lowest Starting Price</Option>
                <Option value="endingPrice-asc">Lowest Ending Price</Option>
                <Option value="startingPrice-desc">
                  Highest Starting Price
                </Option>
                <Option value="endingPrice-desc">Highest Ending Price</Option>
              </Select>
            </div>
          </div>
        </Row>
        <Row>
          {boxes.map((box, index) => {
            const type = parseInt(box.tokenId);

            // if (box.seller === "0xb459173a32af1c63bd7d1e31b99b5247ddad32d8") {
            //   return null;
            // }

            return (
              <Col
                key={index}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                className="col-item"
              >
                <div className="box-wrap">
                  <div className={`box-game-card ${TEXT_BOX[type]}`}>
                    <img src={IMG_BOX[type]} alt="" />
                    <div className="name-box"> {TEXT_BOX[type]} BOX</div>
                  </div>
                  <div className="box_id">Price: {weiToETH(box.price)} RU</div>
                  <div className="box_id">Amount: {box.amount} BOX </div>
                  <div className="box_id">
                    Seller: {formatTokenAddress(box.seller)}
                  </div>
                  {box.seller.toString().toLowerCase() ===
                  account?.toString().toLowerCase() ? null : (
                    <div className="box-btn-buy">
                      <Button
                        className="app-btn app-btn__primary"
                        onClick={() => handleShowModalBuy(box)}
                      >
                        Buy
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            );
          })}

          <ModalConfirmBuy
            visible={showModalConfirmBuy}
            handleCloseModal={handleCloseModalConfirmBuy}
            box={boxInfo}
            handleFetchNewData={handleFetchNewData}
          />
        </Row>
        <Pagination
          currentPage={Number(filters?.currentPage)}
          pages={Math.ceil(totalBox / filters?.limit)}
          onPageChange={handleChangeCurrentPage}
        />
      </Container>
    </div>
  );
};

export default GameBoxList;
