import React, { useState, Fragment, useEffect } from "react";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import { RU_CONTRACT, BUSD } from "consts";

const useGetRUPrice = () => {
    const [price, setPrice] = useState(0);

    useEffect(() => {
        async function init() {
            const web3 = new Web3("https://bsc-dataseed1.defibit.io/");
            const busdContract = new web3.eth.Contract(
                BUSD.ABI,
                BUSD.ADDRESS
            );
            const ruContract = new web3.eth.Contract(
                RU_CONTRACT.ABI,
                "0x6dc923900b3000bd074d1fea072839d51c76e70e"
            );

            const busdBalance = await busdContract.methods.balanceOf('0x4C6E5311d826a16702BF49055323473220f9C2C4').call();
            const ruBalance = await ruContract.methods.balanceOf('0x4C6E5311d826a16702BF49055323473220f9C2C4').call();
            const price = new BigNumber(busdBalance).div(new BigNumber(ruBalance)).toFixed(5);
            setPrice(price);
        }
        init();
        const intervalPrice = setInterval(init, 30 * 1000);
        return () => {
            clearInterval(intervalPrice);
        }
    }, []);

    return price;
}

export default useGetRUPrice;
