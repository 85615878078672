import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Chart from "./Chart";
import Boxes from "./Boxes";
import { IconContext } from "react-icons";
import { FiMinus, FiPlus } from "react-icons/fi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { VAULT_CONTRACT } from "../../consts/vault_abi";
import CountdownVault from "./CountdownVault";
import { NETWORKS, BSC_TESTNET } from "../../utils/constants";
import { PLAYER_CONTRACT, RPC } from "../../consts";
import { PANCACKE_PAIR_CONTRACT } from "../../consts/tvl";
import { thounsandSeparators, formatRUDecimals } from "utils/helper";
import RuIcon from "../../assets/icons/ic_ru.svg";
import BUSDIcon from "../../assets/icons/ic_BUSD.png";

const TokenVault = () => {
  const { account, library, chainId } = useWeb3React();
  const [clicked, setClicked] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endLockTime, setEndLockTime] = useState();
  const [endTime, setEndTime] = useState();
  const [duration, setDuration] = useState();
  const [ruTVL, setRuTVL] = useState();
  const [busdTVL, setBusdTVL] = useState();

  const toggle = () => {
    setClicked(!clicked);
  };

  let vaultAddress = VAULT_CONTRACT.ADDRESS_MAINNET;
  if (chainId === NETWORKS[BSC_TESTNET]) {
    vaultAddress = VAULT_CONTRACT.ADDRESS_TESTNET;
  }

  useEffect(() => {
    const getDuration = async () => {
      const web3 = new Web3(RPC.BSC);
      const vaultContract = new web3.eth.Contract(
        VAULT_CONTRACT.ABI,
        vaultAddress
      );
      try {
        const startTime = await vaultContract.methods.startTime().call();
        const lockTerm = await vaultContract.methods.lockTerm().call();
        const duration = await vaultContract.methods.duration().call();
        if (startTime && duration && lockTerm) {
          setDuration(duration * 1000);
          setStartTime(Number(startTime * 1000));
          setEndLockTime((Number(startTime) + Number(lockTerm)) * 1000);
          setEndTime((Number(startTime) + Number(duration)) * 1000);
        }
      } catch (error) {}
    };
    getDuration();
  }, [account, library, vaultAddress]);

  useEffect(() => {
    const getTVL = async () => {
      const web3 = new Web3(RPC.BSC);
      const pancackePairContract = new web3.eth.Contract(
        PANCACKE_PAIR_CONTRACT.ABI,
        PANCACKE_PAIR_CONTRACT.ADDRESS
      );
      try {
        const balance = await pancackePairContract.methods
          .balanceOf(vaultAddress)
          .call();
        const totalSupply = await pancackePairContract.methods
          .totalSupply()
          .call();
        const { 0: ruRsv, 1: busdRsv } = await pancackePairContract.methods
          .getReserves()
          .call();

        if (ruRsv && busdRsv && balance && totalSupply) {
          const ruTvl = (ruRsv * balance) / totalSupply;
          const busdTvl = (busdRsv * balance) / totalSupply;
          setRuTVL(formatRUDecimals(ruTvl));
          setBusdTVL(formatRUDecimals(busdTvl));
        }
      } catch (error) {}
    };
    getTVL();
  }, [account, library, vaultAddress]);

  const toggleStart = 768;
  const { width } = useWindowDimensions();

  const Description = (
    <div className="content">
      <ul>
        {/* <li>Deposit Time: 8AM UTC Nov 16 - 8AM UTC Nov 21.</li> */}
        <li>Rewards: $RU</li>
        <li>
          No LP lock after 3 months Once your LP token is deposited, it will be
          locked for the first 3 months. After that, there will be no lock
          period. Users can withdraw their LP tokens anytime and earn real-time
          APY.
        </li>
        <li>
          Preset APY of up to 5000% based on the amount of $RU - Earnings are
          guaranteed. RU-BUSD Liquidity Farming Vaults enables liquidity
          providers to earn a sweet APY of up to 5000% regardless of the number
          of participants, which means that no matter how many people stake into
          the vault, liquidity providers are guaranteed to receive the preset
          increasing APY.
        </li>
        <li>
          A higher APY is applied for a longer staking duration. The incentive
          program will last 365 days. The APY will increase in real time, sec by
          sec, until it reaches 5000% at the end of the program. The longer and
          more TVL you deposit in the vault, the more earnings you'll make. If
          you can't hold, you won't be rich.
        </li>
      </ul>
    </div>
  );

  return (
    <div className="token-vault">
      <Container>
        <section className="token-vault__info">
          <div className="chart-tvl">
            <div className="tvl">
              <ul>
                <li>
                  <div className="title">TVL</div>
                  {/* <span>
                    {thounsandSeparators(busdTVL) || "--"}{" "}
                    <img src={BUSDIcon} alt="RU" className="coin-icon" />
                  </span> */}
                  <span>
                    {thounsandSeparators(ruTVL) || "--"}{" "}
                    <img src={RuIcon} alt="RU" className="coin-icon" />
                  </span>
                </li>
              </ul>
            </div>
            <div className="chart">
              <Chart startTime={startTime} duration={duration} />
            </div>
          </div>
          <div className="info">
            <div className="info__locktime">
              <div className="countdown">
                <CountdownVault
                  startTime={startTime}
                  endTime={endTime}
                  endLockTime={endLockTime}
                />
              </div>
            </div>
            <div className="info__description">
              {width <= toggleStart ? (
                <>
                  <div className="iheader" onClick={toggle}>
                    <div className="title">DESCRIPTION</div>
                    <IconContext.Provider
                      value={{ color: "#5d666f", size: "1.5rem" }}
                    >
                      <div>{clicked ? <FiMinus /> : <FiPlus />}</div>
                    </IconContext.Provider>
                  </div>
                  {clicked ? Description : null}
                </>
              ) : (
                <>
                  {/* <div className="title">DESCRIPTION</div> */}
                  {Description}
                </>
              )}
            </div>
          </div>
          <div></div>
        </section>
        <section className="token-vault__main">
          <Boxes
            startTime={startTime}
            endTime={endTime}
            endLockTime={endLockTime}
            vaultAddress={vaultAddress}
          />
        </section>
      </Container>
    </div>
  );
};

export default TokenVault;
