import React from "react";
import { Skeleton } from "antd";
import deco_bronze from "../../assets/images/wallet/deco_bronze.png";

const PlayerCardSkelaton = ({ cardType }) => {
  return (
    <div className="card wallet-card">
      <div className="card-top">
        <div className="card-shadow" />
        <div className={`card-body bronze`}>
          <div className="card-img"></div>
          <div className="card-deco">
            <img src={deco_bronze} alt="" />
          </div>
          <div className="card-flag">
            <Skeleton.Avatar
              className="skeleton-custom player_img"
              active={true}
            />
          </div>
          <Skeleton
            className="card-name txt--title skeleton-custom"
            active={true}
            title={false}
            paragraph={{ rows: 1, width: 100 }}
          />

          <div className="card-content">
            <div className="card-info">
              <div className="info-block">
                <Skeleton
                  className="skeleton-custom"
                  active={true}
                  title={false}
                  paragraph={{ rows: 1, width: 50 }}
                />
              </div>
              <div className="block-line"></div>
              <div className="info-block">
                <Skeleton
                  className="skeleton-custom"
                  active={true}
                  title={false}
                  paragraph={{ rows: 1, width: 50 }}
                />
              </div>
              <div className="block-line"></div>
              <div className="info-block">
                <Skeleton
                  className="skeleton-custom"
                  active={true}
                  title={false}
                  paragraph={{ rows: 1, width: 50 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-bottom">
        <Skeleton
          className={`skeleton-custom skeleton-card-id ${
            cardType === "wallet" ? null : "skeleton-card-price"
          }`}
          active={true}
          title={false}
          paragraph={{ rows: 1, width: 150 }}
        />
      </div>
    </div>
  );
};

export default PlayerCardSkelaton;
