import React from "react";
import { Modal, Button } from "antd";
import { useWeb3React } from "@web3-react/core";

const ModalConfirmDelist = ({
  visible,
  handleCancel,
  handleSubmit,
  statusLoading,
}) => {
  const { account } = useWeb3React();
  return (
    <div>
      <Modal
        className="app-modal modal-delist"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleSubmit}
        title={null}
        centered
        okText={"DELIST"}
        cancelText={"CANCEL"}
        footer={null}
      >
        {account ? (
          <>
            <div className="modal_content">
              Are you sure to delist this player?
            </div>
            <div className="actions">
              <Button className="app-btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="app-btn app-btn__primary"
                onClick={handleSubmit}
                loading={statusLoading}
                disabled={statusLoading}
              >
                Delist
              </Button>
            </div>
          </>
        ) : (
          <div className="modal_content">
            Please connect to a wallet first !
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalConfirmDelist;
