import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";
import { KEY_INVALID_INPUT, REGEX_INPUT_NUMBER } from "utils/constants";
import { useWeb3React } from "@web3-react/core";
import { convertExponential, thounsandSeparators } from "utils/helper";
import Web3 from "web3";
import Loading from "components/Common/Loading";
import { MARKET_CONTRACT, RU_CONTRACT } from "../../consts";
import useGetRUPrice from "hooks/useGetRUPrice";

const ModalConfirmBuy = ({
  form,
  visible,
  handleCancel,
  handleSubmit,
  currentPrice,
  handleChangeInput,
  buyPrice,
  statusLoading,
  saleInfo,
}) => {
  const { account, library } = useWeb3React();
  const [isApprove, setIsApprove] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const priceRU = useGetRUPrice();

  const handleApprove = async () => {
    if (account && library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const ruContract = new web3.eth.Contract(
        RU_CONTRACT.ABI,
        RU_CONTRACT.ADDRESS
      );
      try {
        const result = await ruContract.methods
          .approve(MARKET_CONTRACT.ADDRESS, web3.utils.toWei("9999999999999"))
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const ruContract = new web3.eth.Contract(
          RU_CONTRACT.ABI,
          RU_CONTRACT.ADDRESS
        );
        const approveBuy = await ruContract.methods
          .allowance(account, MARKET_CONTRACT.ADDRESS)
          .call();
        setIsApprove(web3.utils.fromWei(approveBuy) > currentPrice);
      }
      setIsFetching(false);
    }
    setIsFetching(true);
    getAllowance();
  }, [account]);

  if (isFetching && visible) {
    return (
      <Modal
        className="app-modal modal-form"
        title={null}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
      >
        <div
          className="d-flex justify-content-center"
          style={{ height: "10vh", alignItems: "center" }}
        >
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      name="modal-buy"
      className="app-modal modal-form"
      visible={visible}
      onCancel={handleCancel}
      title={null}
      centered
      footer={null}
    >
      {account ? (
        <div className="modal-form__content">
          <div className="title">Buy player</div>
          <div className="form-item">
            <div className="label">Curent Price</div>
            <div className="value">
              {thounsandSeparators(convertExponential(currentPrice))} RU
            </div>
          </div>
          <div className="form-item">
            <div>Buy at</div>
            <div>
              $
              {thounsandSeparators(
                (buyPrice ? buyPrice : currentPrice) * Number(priceRU),
                4
              )}
            </div>
          </div>

          <Form
            name="form-buy"
            className="form-app"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            onFinish={handleSubmit}
            initialValues={{
              price: buyPrice ? buyPrice : currentPrice,
            }}
            form={form}
          >
            <Form.Item
              name="price"
              label={null}
              required={false}
              colon={false}
              rules={[
                {
                  required: true,
                  message: "Price is required",
                },
                {
                  pattern: new RegExp(REGEX_INPUT_NUMBER),
                  message: "Please enter a greater than or equal to 0 number",
                },
              ]}
            >
              <Input
                placeholder="0.000"
                suffix="RU"
                name="price"
                type="number"
                onChange={handleChangeInput}
                disabled={Number(saleInfo.endingPrice) === Number(currentPrice)}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (KEY_INVALID_INPUT.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="group-btn-box">
              <Button name="cancel" className="app-btn" onClick={handleCancel}>
                Cancel
              </Button>

              {!isApprove && (
                <Button
                  name="approve"
                  className="app-btn app-btn__primary btn-approve"
                  onClick={handleApprove}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Approve
                </Button>
              )}
              {isApprove && (
                <Button
                  name="list"
                  className="app-btn app-btn__primary btn-list"
                  htmlType="submit"
                  loading={statusLoading}
                  disabled={statusLoading}
                >
                  Buy
                </Button>
              )}
            </div>
          </Form>
        </div>
      ) : (
        <div className="modal_content">Please connect to a wallet first !</div>
      )}
    </Modal>
  );
};

export default ModalConfirmBuy;
