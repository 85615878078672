import React, { useState, useEffect } from "react";
import InfoTab from "./InfoTab";
import { Container } from "react-bootstrap";
import RecentlyListedTable from "./RecentlyListedTable";
import RecentlySoleTable from "./RecentlySoldTable";
import { getMarketStatisticsData } from "../../utils/client_api";

const Dashboard = () => {
  const [statisticData, setStatisticData] = useState();
  useEffect(() => {
    const statisticData = async () => {
      const result = await getMarketStatisticsData();
      if (result) {
        setStatisticData(result.result);
      } else {
        setTimeout(statisticData, 1000 * 5);
      }
    };
    statisticData();
  }, []);

  useEffect(() => {
    document.title = "RIFI United - Dashboard";
  }, []);

  return (
    <Container className="dashboard">
      <InfoTab statisticData={statisticData} />
      <div className="dashboard__main_content">
        <RecentlyListedTable />
        <RecentlySoleTable />
      </div>
    </Container>
  );
};

export default Dashboard;
