import axios from "axios";

async function getFile(ipfs) {
  const ipfsHash = ipfs.split("ipfs://");
  //   console.log(ipfsHash[1]);
  try {
    const ipfsURL =
      process.env.REACT_APP_IPFS_URL || (localStorage.getItem("network") === "testnet" ? "https://api-dev-testnet.rifiunited.io" : 
      (localStorage.getItem("network") === "test_mainnet" ? "https://api-dev-mainnet.rifiunited.io" : "https://api.rifiunited.io" ));
    const result = await axios.get(`${ipfsURL}/ipfs/${ipfsHash[1]}`);
    return result?.data;
  } catch (e) {
    console.log("error ipfs = ", e);
    return null;
  }
}

export { getFile };
