import React from "react";
import TwitterLogo from "assets/images/social-icon/twitter.svg";
import FacebookLogo from "assets/images/social-icon/facebook.svg";
import MediumLogo from "assets/images/social-icon/medium.svg";
import TelegramLogo from "assets/images/social-icon/telegram.svg";
import { Container } from "react-bootstrap";
// import YoutubeLogo from "assets/images/social-icon/youtube.svg";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <div className="d-flex align-items-center footer-wrapper">
          <div className="contact d-flex align-items-center">
            <div>Follow Us</div>
            <nav className="social-link">
              <ul>
                <li>
                  <a
                    href="https://twitter.com/RikkeiFinance"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterLogo} alt="twitter" />
                  </a>
                </li>
                {/* <li>
            <a href="/" target="_blank" rel="noreferrer">
              <img src={YoutubeLogo} alt="youtube" />
            </a>
          </li> */}
                <li>
                  <a
                    href="https://www.facebook.com/RikkeiFinance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={FacebookLogo} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://rikkeifinance.medium.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={MediumLogo} alt="medium" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/rifiunited"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TelegramLogo} alt="telegram" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="copyright">
            Copyright © 2021, Rifi United. All Rights Reserved.
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
