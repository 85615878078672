import React, { useState } from "react";
import TotalSale from "assets/images/dashboard/total_sale.svg";
import TotalVolume from "assets/images/dashboard/total_volume.png";
import PlayerSold from "assets/images/dashboard/player_sold.svg";
import {
  thounsandSeparators,
  formatFiat,
  formatRUDecimals,
} from "../../utils/helper";
import useGetRUPrice from "hooks/useGetRUPrice";

const InfoTab = ({ statisticData }) => {
  const RUPrice = useGetRUPrice();
  const [tabActive, setTabActive] = useState(0); // 0, 1, 2
  const handleTabClick = (tabNumber) => {
    setTabActive(tabNumber);
  };

  return (
    <div className="infotab">
      <div className="infotab__header">
        {statisticData &&
          statisticData.length > 0 &&
          statisticData.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  tabActive === index
                    ? "infotab__item infotab__item--active"
                    : "infotab__item"
                }
                onClick={() => handleTabClick(index)}
              >
                {item.timeLabel}
              </div>
            );
          })}
      </div>
      <div className="infotab__body">
        <div className="body-item">
          <img src={TotalSale} alt="total sale" />
          <div className="body-item__content">
            <div className="title">Total Sale</div>
            <div className="value">
              {(statisticData &&
                statisticData.length > 0 &&
                thounsandSeparators(statisticData[tabActive].totalSale)) ||
                "--"}
            </div>
          </div>
        </div>
        <div className="body-item">
          <img src={TotalVolume} alt="total volume" />
          <div className="body-item__content">
            <div className="title">Total Volume</div>
            <div className="value">
              {(statisticData &&
                statisticData.length > 0 &&
                formatFiat(
                  formatRUDecimals(
                    statisticData[tabActive].totalVolume[0]?.total
                  ),
                  ""
                )) ||
                "--"}{" "}
              RU
              <span>
                {(statisticData &&
                  statisticData.length > 0 &&
                  formatFiat(
                    formatRUDecimals(
                      statisticData[tabActive].totalVolume[0]?.total *
                        Number(RUPrice)
                    )
                  )) ||
                  "--"}
              </span>
            </div>
          </div>
        </div>
        <div className="body-item">
          <img src={PlayerSold} alt="player sold" />
          <div className="body-item__content">
            <div className="title">Player Sold</div>
            <div className="value">
              {" "}
              {(statisticData &&
                statisticData.length > 0 &&
                thounsandSeparators(statisticData[tabActive].playerSold)) ||
                "--"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTab;
