import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Tooltip } from "antd";
import { thounsandSeparators } from "utils/helper";

const FormulaForChart = (x) => {
  // return y
  return ((11 / 24) * x ** 4 - 4 * x ** 3 + 50 * x ** 2 + 18 * x) / 2;
};

export default function Chart({ startTime, duration }) {
  // if (!startTime || !duration) return 0;
  const todayPoint = () => {
    // duration = 12 months => today = ? months
    // const todayDuration = Date.parse("2023-11-19T06:00:00+00:00") - startTime;
    const todayDuration = Date.now() - startTime;
    if (todayDuration > 0) {
      return todayDuration >= duration ? 12 : (todayDuration * 12) / duration;
    } else {
      return 0;
    }
  };

  const caculateData = () => {
    const time = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const data = time.map((item) => ({
      x: item,
      y: FormulaForChart(item),
    }));
    return data;
  };

  const data2 = [
    {
      id: "vault",
      data: caculateData(),
    },
  ];

  const theme = {
    axis: {
      fontSize: "14px",
      tickColor: "#ffffff",
      ticks: {
        line: {
          stroke: "#ffffff",
        },
        text: {
          fill: "#ffffff",
        },
      },
      legend: {
        text: {
          fill: "#ffffff",
        },
      },
    },
    crosshair: {
      line: {
        stroke: "#ffffff",
        strokeWidth: 1,
        strokeOpacity: 1,
      },
    },
    tooltip: {
      basic: {
        whiteSpace: "pre",
        display: "flex",
        alignItems: "center",
      },
    },
  };

  const PointLayer = (properties) => {
    if (!startTime || !duration) return;
    const { series, xScale, yScale } = properties;
    const unit = series[0].data[1].position.x - series[0].data[0].position.x;
    const todayDuration = todayPoint();
    return (
      <svg>
        <Tooltip
          title={`Today: ${thounsandSeparators(
            FormulaForChart(todayDuration)
          )}%`}
          overlayClassName="today-vault-tooltip"
        >
          <circle
            cx={
              xScale(Math.floor(todayDuration)) +
              (todayPoint() - Math.floor(todayDuration)) * unit
            }
            cy={yScale(FormulaForChart(todayDuration))}
            r={6}
            fill="white"
            stroke="red"
          />
        </Tooltip>
      </svg>
    );
  };

  return (
    <ResponsiveLine
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "points",
        "slices",
        "mesh",
        "legends",
        PointLayer,
      ]}
      data={data2}
      margin={{ top: 50, bottom: 50, left: 80, right: 20 }}
      theme={theme}
      curve={"natural"}
      colors={["#fcb100"]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Month",
        legendOffset: 40,
        legendPosition: "middle",
        format: (value) => `${value}`,
      }}
      enableGridX={false}
      enableGridY={false}
      crosshairType={"bottom-left"}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "APY",
        legendOffset: -65,
        legendPosition: "middle",
        format: (value) => `${value} %`,
        // legend: "count",
        // legendOffset: -40,
        // legendPosition: "middle",
      }}
      enablePoints={false}
      useMesh={true}
      tooltip={(chart) => {
        const data = chart.point.data;
        return (
          <div
            style={{
              padding: 12,
              color: "#ffffff",
              background: "#222222",
            }}
          >
            {parseFloat(data.yFormatted).toFixed(2)} %
          </div>
        );
      }}
    />
  );
}
