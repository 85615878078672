import { ethers, BigNumber } from "ethers";
import { floor, round, times } from "lodash";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Web3 from "web3";
import BigNumberJS from "bignumber.js";

BigNumberJS.config({ EXPONENTIAL_AT: 200 });

export const weiToETH = (wei) => {
  const w = new BigNumberJS(wei);
  return w.div(1e18).toFixed(0).toString();
}

export const thounsandSeparators = (number, maximumFractionDigits) => {
  if (!number) return "0";
  return Number(number).toLocaleString("en-US", {
    maximumFractionDigits: maximumFractionDigits || 2,
  });
};

export const getCurrentPlayerPrice = (saleInfo) => {
  if (!saleInfo || !saleInfo.duration) {
    return 0;
  }
  const now = Date.now();
  const { startingPrice, endingPrice, startedAt, duration } = saleInfo;
  const pricePerMilliseconds =
    (endingPrice - startingPrice) / (saleInfo.duration * 1000);
  const millionSecondsFromStarted =
    pricePerMilliseconds < 0
      ? now - new Date(startedAt * 1000).getTime()
      : now - new Date(startedAt * 1000).getTime() + 15 * 1000;
  if (millionSecondsFromStarted > duration * 1000) {
    return endingPrice;
  }
  return parseFloat(
    pricePerMilliseconds * millionSecondsFromStarted + parseFloat(startingPrice)
  );
};

/* Convert timestamp like '1635503323' or time string like '2021-11-08T09:36:58.700Z' into strings like "5m ago" */
export const formatTime = (times) => {
  if (!times) return;
  let timeStr = times; // times is time string
  try {
    timeStr = new Date(times * 1000).toISOString(); // times is timestamp, convert from timestamp to time string
  } catch (ex) {
    console.log("ex", ex);
  }
  const date = new Date();
  const now = new Date();
  date.setUTCFullYear(parseInt(timeStr.slice(0, 5)));
  date.setUTCMonth(parseInt(timeStr.slice(5, 7)) - 1);
  date.setUTCDate(parseInt(timeStr.slice(8, 10)));
  date.setUTCHours(parseInt(timeStr.slice(11, 13)));
  date.setUTCMinutes(parseInt(timeStr.slice(14, 16)));
  date.setUTCSeconds(parseInt(timeStr.slice(17, 19)));
  const seconds = (now.getTime() - date.getTime()) / 1000; // unit: second
  if (seconds < 60) {
    return `${floor(seconds)}s ago`;
  }
  const minutes = seconds / 60;
  if (minutes < 60) {
    return `${floor(minutes)}m ago`;
  }
  const hours = minutes / 60;
  if (hours < 24) {
    return `${floor(hours)}h ago`;
  }
  const days = hours / 24;
  if (days < 31) {
    return `${floor(days)}d ago`;
  }
  const months = days / 30;
  if (months < 13) {
    return `${floor(months)}mth ago`;
  }
  const years = months / 12;
  return `${floor(years)}y ago`;
};

export const convertExponential = (n) => {
  if (!n) return;
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
        "0." +
        "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
};

export const parseUnitsCustom = (value, decimals = 18) => {
  let amountFormat = "";
  if (!value.toString().includes(".")) {
    amountFormat = value;
  } else {
    const number = convertExponential(value);
    const indexDot = number.toString().indexOf(".");
    const lengthDecimal = indexDot && number.toString().slice(indexDot + 1);
    if (lengthDecimal?.length > 18) {
      const fixedAmout = (Number(value) || 0).toFixed(decimals + 1);
      amountFormat = convertExponential(
        fixedAmout.substring(0, fixedAmout.length - 1)
      );
    } else {
      amountFormat = number;
    }
  }
  return BigNumber.from(
    ethers.utils.parseUnits(amountFormat.toString(), decimals).toString()
  );
};

export const formatFiat = (number, currency = "$") => {
  const numberRounded = round(Number(number));
  const unit =
    numberRounded.toString().length > 9
      ? "B"
      : numberRounded.toString().length > 6
      ? "M"
      : numberRounded.toString().length > 3
      ? "k"
      : "";
  if (unit === "B") {
    const formatFiat = round(Number(numberRounded / 10 ** 9), 2);
    return `${currency}${thounsandSeparators(formatFiat)}${unit}`;
  } else if (unit === "M") {
    const formatFiat = round(Number(numberRounded / 1000000), 2);
    return `${currency}${thounsandSeparators(formatFiat)}${unit}`;
  } else if (unit === "k") {
    const formatFiat = round(Number(numberRounded / 1000), 2);
    return `${currency}${thounsandSeparators(formatFiat)}${unit}`;
  } else {
    const formatFiat = round(Number(numberRounded), 2);
    return `${currency}${thounsandSeparators(formatFiat)}`;
  }
};

export const formatTimeFootBaller = (time) => {
  var day, hour, miniture, seconds;
  day = Math.floor(time / 86400);
  hour = Math.floor((time % 86400) / 3600);
  miniture = Math.floor(((time % 86400) % 3600) / 60);
  seconds = time - (day * 86400 + hour * 3600 + miniture * 60);

  if (day > 0 && hour === 0 && miniture === 0) {
    return day + " day";
  } else if (day === 0 && hour > 0 && miniture === 0) {
    return hour + " h";
  } else if (day === 0 && hour === 0 && miniture > 0) {
    return miniture + " m";
  } else if (day > 0 && hour === 0 && miniture > 0) {
    return day + " day " + hour + " h " + miniture + " m";
  } else if (day === 0 && hour > 0 && miniture > 0) {
    return hour + " h " + miniture + " m";
  } else if (day > 0 && hour > 0 && miniture === 0) {
    return day + " day " + hour + " h";
  } else if (day > 0 && hour > 0 && miniture > 0) {
    return day + " day " + hour + " h " + miniture + " m";
  } else if (day === 0 && hour === 0 && miniture === 0) {
    return seconds + " s";
  }

  // if (time < 3600) {
  //   return Math.round(time / 60) > 0 ? Math.round(time / 60) + " m" : "1 m";
  // }
  // if (time >= 3600 && time < 86400) {
  //   return Math.round(time % 3600) == 0? Math.round(time % 3600) + " h"  :Math.round(time % 3600) + " h" +  Math.round(time / 60) + " m";
  // }

  // if (time >= 86400) {
  //   return Math.round(time / 86400) + " day " + Math.round((time % 86400)/ 3600) +" h " + Math.round((time % 3600) / 60) + " m";
  // }

  // if (time < 3600 && time >= 60 && Math.round(time / 60) != 60) {
  //   return Math.round(time / 60) + " m";
  // }else if (Math.round(time / 60) == 60) {
  //   return Math.round(time / 3600) + " h";
  // }else if(time < 60){
  //   return "1 m";
  // }

  // if (time >= 3600 && time < 86400) {
  //   if (time % 3600 == 0) {
  //     return Math.round(time / 3600) + " h";
  //   }else if ((time % 3600) / 60 < 1 && (time % 3600 / 60) > 0) {
  //     return Math.round(time / 3600) + " h " + "1 m";
  //   }else if(Math.round((time % 86400)/ 3600) == 24){
  //     return Math.round(time / 86400) + " day";
  //   }else{
  //     return Math.round(time / 3600) + " h " + Math.round((time % 3600) / 60) + " m";
  //   }

  // }

  // if (time >= 86400) {
  //   if (time % 86400 == 0 || ((time % 86400)/ 3600 < 1 && (time % 86400)/ 3600 > 0)) {
  //     if ((time % 86400)/ 3600 < 1 && (time % 86400)/ 3600 > 0 && Math.round((time % 3600) / 60) >= 1) {
  //       return Math.round(time / 86400) + " day " + Math.round((time % 86400)/ 3600) +" h " + Math.round((time % 3600) / 60) + " m";
  //     } else {
  //       return Math.round(time / 86400) + " day";
  //     }
  //   }else if((time % 86400) % 3600 == 0 && Math.round((time % 86400)/ 3600) != 60){
  //     return Math.round(time / 86400) + " day " + Math.round((time % 86400)/ 3600) +" h";
  //   }else if((time % 3600) / 60 < 1 && (time % 3600 / 60) > 0){
  //     return Math.round(time / 86400) + " day " + Math.round((time % 86400)/ 3600) +" h " + "1 m";
  //   }else if(Math.round((time % 86400)/ 3600) == 60){
  //     return Math.round(time / 86400) + 1 + " day";
  //   }else{
  //     return Math.round(time / 86400) + " day " + Math.round((time % 86400)/ 3600) +" h " + Math.round((time % 3600) / 60) + " m";
  //   }
  // }
};
export const formatTokenAddress = (address) => {
  if (!address) {
    return "";
  } else if (address.length <= 6) {
    return address;
  }
  return address.slice(0, 6) + "..." + address.slice(address.length - 4);
};

// convert timestamp in seconds to string date
export const formatTimestampToDate = (
  timestamp,
  type = "seconds",
  format = "MMM DD YYYY HH:mm:ss"
) => {
  let time;
  type === "seconds" ? (time = timestamp * 1000) : (time = timestamp);
  if (moment(time).isValid()) return moment(time).format(format);
  return null;
};

export const CustomTooltip = ({ isDisplay = false, text, render }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="ru-tooltip">
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 50, hide: 50 }}
      overlay={isDisplay ? renderTooltip : <div></div>}
    >
      {render}
    </OverlayTrigger>
  );
};

export const formatRUDecimals = (RUValue) => {
  const web3 = new Web3(window.ethereum);
  let formatValue = null;
  try {
    formatValue = web3.utils.fromWei(
      RUValue.toLocaleString("fullwide", { useGrouping: false })
    );
  } catch {}
  return formatValue;
};

const OVR = {
  1: 50,
  2: 70,
  3: 84,
};

export const convertOvr2RU = (ovr, age, legendary) => {
  if (legendary) {
    return {
      ru: new BigNumberJS(60000e18),
      str: new BigNumberJS(60000e18).toString(),
    };
  }

  let ru;
  let type = 1;
  if (ovr <= 63) {
    ru = new BigNumberJS(3000e18);
  } else if (ovr >= 64 && ovr <= 78) {
    ru = new BigNumberJS(5000e18);
    type = 2;
  } else {
    ru = new BigNumberJS(25000e18);
    type = 3;
  }

  let t = 1;
  if (ovr > OVR[type]) {
    for (let i = OVR[type]; i < ovr; i++) {
      t += 0.01;
    }
  }

  if (ovr < OVR[type]) {
    for (let i = ovr; i < OVR[type]; i++) {
      t -= 0.01;
    }
  }

  ru = ru.multipliedBy(t);

  let u = 1;
  if (age > 25) {
    for (let i = 25; i < age; i++) {
      u -= 0.01;
    }
  }

  if (age < 25) {
    for (let i = age; i < 25; i++) {
      u += 0.01;
    }
  }

  ru = ru.multipliedBy(u);

  return {
    ru,
    str: ru.toString(),
  };
};

export const numberToShortString = (value) => {
  if (!value) return value;
  if (value < 1000) return value.toFixed(2);
  value = value.toFixed(0);
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  if (("" + value).length % 3 == 0) suffixNum -= 1;
  var shortValue = parseFloat(suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value);
  if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
};

export const numberToShortStringObject = (value) => {
  if (!value) {
    return {
      value,
      suffix: "",
    };
  }

  if (value < 1000) {
    return {
      value: value.toFixed(2),
      suffix: "",
    };
  }
  value = value.toFixed(0);
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixNum = Math.floor(("" + value).length / 3);
  if (("" + value).length % 3 == 0) suffixNum -= 1;
  var shortValue = parseFloat(suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value);
  if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
  }

  return {
    value: shortValue,
    suffix: suffixes[suffixNum],
  };
};
