import React from "react";
import Countdown from "react-countdown";

const renderer = (props) => {
  return (
    <>
      {/* <div>{formatTimestampToDate(1636862400)}</div> */}
      <ul>
        {/* <li>
          <span>{props.formatted.days}</span>
          <span>Days</span>
        </li> */}
        {/* <li>
          <span className="dot">:</span>
        </li> */}
        <li>
          <span>{props.formatted.days}</span>
          <span>DAY</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.hours}</span>
          <span>HR</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.minutes}</span>
          <span>MIN</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.seconds}</span>
          <span>SEC</span>
        </li>
      </ul>
    </>
  );
};

const NFTCountdown = ({ time, countdownComplete }) => {
  return (
    <div className="the-countdown">
      <Countdown
        date={time}
        zeroPadTime={2}
        renderer={renderer}
        onComplete={countdownComplete}
      />
    </div>
  );
};

export default NFTCountdown;
