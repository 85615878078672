import React, { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { BOX, GAME_BOX, PLAYERS, TOP_PLAYERS } from "utils/constants";
import TheCountdown from "../TheCountdown/TheCountdown";

import Banner from "./Banner";
import PlayerList from "./PlayerList";
import TopPlayer from "./TopPlayer";
import BoxList from "./BoxList";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import GameBoxList from "./GameBoxList";

const Marketplace = () => {
  let history = useHistory();
  let location = useLocation();
  const param = useParams();
  const isForceStarted = param && param.id === "ru";
  const [typeTab, setTypeTab] = useState(PLAYERS);
  const [isCountdownComplete, setIsCountdownComplete] = useState(false);
  const time = 1637467200000; // Nov 21 2021 11:00 AM
  // const time = 1637228520000;

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const PushQuery = (modifiedQuery) => {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(modifiedQuery),
      });
    };
    PushQuery({ ...parsed, tab: typeTab });
  }, [typeTab]);

  useEffect(() => {
    const UseQuery = () => {
      const parsed = queryString.parse(location.search);
      return parsed;
    };
    const queryURL = UseQuery();
    queryURL && Object.keys(queryURL).length > 0
      ? setTypeTab(queryURL.tab)
      : setTypeTab(PLAYERS);
  }, []);

  const countdownComplete = () => {
    setIsCountdownComplete(true);
    return;
  };

  useEffect(() => {
    if (Date.now() >= time) setIsCountdownComplete(true);
  }, []);

  useEffect(() => {
    document.title = "RIFI United - Marketplace";
  }, []);
  return (
    <div className="marketplace-wrapper">
      <Banner typeTab={typeTab} setTypeTab={setTypeTab} />
      <Container className="marketplace-list">
        {typeTab === PLAYERS && <PlayerList />}
        {typeTab === TOP_PLAYERS && <TopPlayer />}
        {typeTab === GAME_BOX && <GameBoxList />}
        {/* {typeTab === BOX && !isCountdownComplete && !isForceStarted && (
          <TheCountdown time={time} countdownComplete={countdownComplete} />
        )}
        {typeTab === BOX && (isCountdownComplete || isForceStarted) && <BoxList />} */}
      </Container>
    </div>
  );
};

export default Marketplace;
