import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Banner from "./Banner";
import PlayerCard from "../Common/PlayerCard";
import BinanceLegendary from "../Common/BinanceLegendary";
import Pagination from "components/Common/Pagination";
// import ic_filter from "../../assets/icons/filter.svg";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { PLAYER_CONTRACT } from "../../consts";
import Loading from "components/Common/Loading";
import PlayerCardSkelaton from "components/Common/PlayerCardSkelaton";
import BinanceLegendarySkelation from "components/Common/BinanceLegendarySkelation";
import {
  getDetailPlayer,
  getListPlayerOnWallet,
  getMarketDataOnSale,
} from "../../utils/client_api";
import useGetRUPrice from "hooks/useGetRUPrice";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Filter from "components/Common/Filter";
import { debounce } from "lodash";
import { BOX } from "utils/constants";
import Box from "components/Box/Box";
import { BINANCE_LEGENDARY, PLAYERS } from "utils/constants";

const pageSize = 9;

const defaultFilter = {
  currentPage: 1,
  overallRange: [20, 120],
  ageRange: [15, 45],
  position: [],
  species: [],
  heightRange: [160, 220],
  weightRange: [60, 100],
  talent: undefined,
  league: undefined,
  club: undefined,
};

const Wallet = () => {
  let history = useHistory();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const { account, library, chainId } = useWeb3React();
  const [players, setPlayers] = useState([]);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [renderedPlayers, setRenderedPlayers] = useState([]);
  const [filter, setFilter] = useState({ ...defaultFilter, ...parsed });
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPlayer, setIsLoadingPlayer] = useState(false);
  const [isOnSale, setIsOnSale] = useState(false);
  const [boxes, setBoxes] = useState([]);
  const price = useGetRUPrice();
  const [tab, setTab] = useState(parsed.tab ?? PLAYERS);
  const [bnfts, setBnfts] = useState([]);
  // const [runGetNFTOwner, setRunGetNFTOwner] = useState(false);
  const [fetchNewData, setFetchNewData] = useState(0);

  const PushQuery = (modifiedQuery, isSearch = true) => {
    if (isSearch === false) {
      history.push({
        pathname: location.pathname,
        search: null,
      });
    } else {
      const parsed = queryString.parse(location.search);
      let search = { ...parsed, ...modifiedQuery };
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(search),
      });
    }
  };

  // useEffect(() => {
  //   if (!bnfts.length) {
  //     setTab(PLAYERS);
  //   }
  // }, [bnfts]);

  useEffect(() => {
    async function init() {
      if (account && library && library.provider) {
        // setIsLoading(true);
        let listPlayer = await getListPlayerOnWallet({ account });
        listPlayer = listPlayer.sort(
          (player1, player2) =>
            parseInt(player1.tokenId) - parseInt(player2.tokenId)
        );

        setPlayers(listPlayer);
        // setFilter((state) => {
        //   state.currentPage = 1;
        //   return { ...state };
        // });
        setTotalPlayers(listPlayer.length);
      }
      // setIsLoading(false);
    }

    async function getOnSalePlayer() {
      if (account && library && library.provider) {
        // setIsLoading(true);
        const result = await getMarketDataOnSale({
          seller: account.toLowerCase(),
          limit: 10000,
        });

        if (!result?.markets) {
          return;
        }

        const web3 = new Web3(library.provider);
        const playerContract = new web3.eth.Contract(
          PLAYER_CONTRACT.ABI,
          PLAYER_CONTRACT.ADDRESS
        );
        let listPlayer = await Promise.all(
          result?.markets.map(async (p) => {
            const { tokenId } = p;
            const fullData = (await getDetailPlayer(tokenId)) || {};
            return {
              ...fullData,
              tokenId,
              data: {},
            };
          })
        );

        listPlayer = listPlayer.sort(
          (player1, player2) =>
            parseInt(player1.tokenId) - parseInt(player2.tokenId)
        );

        setPlayers(listPlayer);
        setFilter((state) => {
          state.currentPage = 1;
          return { ...state };
        });
        setTotalPlayers(listPlayer.length);
        // setIsLoading(false);
      }
    }

    if (isOnSale) {
      getOnSalePlayer();
    } else {
      tab === PLAYERS && init();
    }
  }, [account, isOnSale, chainId, fetchNewData, tab]);

  const handleDebounceSearch = debounce((func) => {
    func();
  }, 100);

  const handleChangeCurrentPage = useCallback(async (page) => {
    setFilter((state) => ({
      ...state,
      currentPage: page,
    }));
    PushQuery({ currentPage: page });
  }, []);

  const handleChangeOverall = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        overallRange: range,
        currentPage: 1,
      }));
      PushQuery({ overallRange: range, currentPage: 1 });
    });
  };

  const handleChangeAge = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        ageRange: range,
        currentPage: 1,
      }));
      PushQuery({ ageRange: range, currentPage: 1 });
    });
  };

  const handleChangePosition = (position) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        position: position,
        currentPage: 1,
      }));
      PushQuery({ position: position, currentPage: 1 });
    });
  };

  const handleChangeHeight = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        heightRange: range,
        currentPage: 1,
      }));
    });
    PushQuery({ heightRange: range, currentPage: 1 });
  };

  const handleChangeWeight = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        weightRange: range,
        currentPage: 1,
      }));
    });
    PushQuery({ weightRange: range, currentPage: 1 });
  };

  const handleChangeSpecies = (species) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        species: species,
        currentPage: 1,
      }));
    });
    PushQuery({ species: species, currentPage: 1 });
  };

  const handleChangeTalent = (talent) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        talent: talent,
        currentPage: 1,
      }));
    });
    PushQuery({ talent: talent, currentPage: 1 });
  };

  const handleChangeLeague = (league) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        league: league,
        currentPage: 1,
      }));
    });
    PushQuery({ league: league, currentPage: 1 });
  };

  const handleChangeClub = (club) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        club: club,
        currentPage: 1,
      }));
    });
    PushQuery({ club: club, currentPage: 1 });
  };

  const hanldeClearFilter = async () => {
    setFilter({ ...defaultFilter });
    PushQuery({}, false);
  };

  const handleChangeTab = (tab) => {
    setTab(tab);
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ tab: tab }),
    });
  };

  useEffect(() => {
    setIsLoadingPlayer(true);
    let fplayers = [...players];

    if (filter.overallRange) {
      const { overallRange } = filter;
      fplayers = players.filter((player) => {
        if (
          parseInt(player?.playerAttribute?.ovr) >= overallRange[0] &&
          parseInt(player?.playerAttribute?.ovr) <= overallRange[1]
        ) {
          return true;
        }
        return false;
      });
    }

    if (filter.ageRange) {
      const { ageRange } = filter;
      fplayers = fplayers.filter((player) => {
        if (
          parseInt(player?.playerAttribute?.age) >= ageRange[0] &&
          parseInt(player?.playerAttribute?.age) <= ageRange[1]
        ) {
          return true;
        }
        return false;
      });
    }

    if (filter.heightRange) {
      const { heightRange } = filter;
      fplayers = fplayers.filter((player) => {
        if (
          parseInt(player?.playerAttribute?.height) >= heightRange[0] &&
          parseInt(player?.playerAttribute?.height) <= heightRange[1]
        ) {
          return true;
        }
        return false;
      });
    }

    if (filter.weightRange) {
      const { weightRange } = filter;
      fplayers = fplayers.filter((player) => {
        if (
          parseInt(player?.playerAttribute?.weight) >= weightRange[0] &&
          parseInt(player?.playerAttribute?.weight) <= weightRange[1]
        ) {
          return true;
        }
        return false;
      });
    }

    if (filter.species && filter.species.length > 0) {
      const { species } = filter;
      fplayers = fplayers.filter(
        (player) => species.indexOf(player?.species?.name) > -1
      );
    }

    if (filter.position && filter.position.length > 0) {
      const { position } = filter;
      fplayers = fplayers.filter((player) => {
        if (
          position.indexOf(player?.playerAttribute?.pfdPos1?.pos) > -1 ||
          position.indexOf(player?.playerAttribute?.pfdPos2?.pos) > -1 ||
          position.indexOf(player?.playerAttribute?.pfdPos3?.pos) > -1
        ) {
          return true;
        }
        return false;
      });
    }

    if (filter.talent) {
      const { talent } = filter;
      fplayers = fplayers.filter((player) => {
        if (player.talentCards && player.talentCards.length) {
          const talents = player.talentCards.map((t) => t.name);
          return talents.indexOf(talent) > -1;
        }
        return false;
      });
    }

    if (filter.league) {
      const { league } = filter;
      fplayers = fplayers.filter((player) => player?.league?.name === league);
    }

    if (filter.club) {
      const { club } = filter;
      fplayers = fplayers.filter((player) => player?.club?.name === club);
    }

    const { currentPage } = filter;
    setTotalPlayers(fplayers.length);
    fplayers = fplayers.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    setRenderedPlayers(fplayers);

    setTimeout(() => setIsLoadingPlayer(false), 800);
  }, [players, filter]);

  useEffect(() => {
    document.title = "RIFI United - Wallet";
  }, []);

  const Players = (
    <Col xs={12} lg={9}>
      <Row>
        {renderedPlayers?.map((item) => (
          <Col
            key={item.tokenId}
            sm={12}
            md={6}
            lg={6}
            xl={4}
            className="wallet-item"
          >
            {isLoadingPlayer ? (
              <PlayerCardSkelaton cardType={"wallet"} />
            ) : (
              <PlayerCard item={item} isLoadingPlayer={isLoadingPlayer} />
            )}
          </Col>
        ))}
      </Row>
      <Pagination
        currentPage={Number(filter.currentPage)}
        pages={Math.ceil(totalPlayers / pageSize)}
        onPageChange={handleChangeCurrentPage}
      />
    </Col>
  );

  const BinancePlayers = (
    <Col xs={12} lg={12}>
      <Row>
        {bnfts?.map((player) => (
          <Col
            key={player.tokenId}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            className="wallet-item"
          >
            {isLoadingPlayer ? (
              <BinanceLegendarySkelation cardType={"wallet"} />
            ) : (
              <BinanceLegendary
                playerData={player}
                // setRunGetNFTOwner={setRunGetNFTOwner}
                handleReFetchData={() => {
                  setFetchNewData((v) => v + 1);
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </Col>
  );

  return (
    <div className="wallet-wrapper">
      <Banner
        tab={tab}
        setTab={handleChangeTab}
        handleSetBnfts={setBnfts}
        // runGetNFTOwner={runGetNFTOwner}
        fetchNewData={fetchNewData}
      />
      <Container className="wallet-list">
        {tab === BOX ? (
          <div className="list-box">
            <Box handleChangeTab={handleChangeTab} />
          </div>
        ) : (
          <Row>
            {tab === PLAYERS ? (
              <Col xs={12} lg={3}>
                <Filter
                  handleChangePosition={handleChangePosition}
                  handleChangeAge={handleChangeAge}
                  handleChangeOverall={handleChangeOverall}
                  handleChangeHeight={handleChangeHeight}
                  handleChangeWeight={handleChangeWeight}
                  handleChangeSpecies={handleChangeSpecies}
                  hanldeClearFilter={hanldeClearFilter}
                  handleChangeTalent={handleChangeTalent}
                  handleChangeLeague={handleChangeLeague}
                  handleChangeClub={handleChangeClub}
                  handleChangeOnsaleFilter={setIsOnSale}
                  filter={filter}
                  type="wallet"
                  isOnSale={isOnSale}
                />
              </Col>
            ) : null}
            {tab === PLAYERS && Players}
            {tab === BINANCE_LEGENDARY && BinancePlayers}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Wallet;
