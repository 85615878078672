import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Modal, Button } from "antd";
import Slider from "react-slick";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { SALE_CONTRACT, RU_CONTRACT, RPC, PLAYER_CONTRACT } from "../../consts";
import http from "utils/http";
import BuyModal from "./BuyModal";
import { Tooltip } from "antd";
import BoxCard from "./BoxCard";
import PlayerCard from "components/Common/PlayerCard";
import Loading from "components/Common/Loading";

import ball_1 from "../../assets/images/marketplace/ball-1.png";
import ball_bg_1 from "../../assets/images/marketplace/ball-bg-1.png";

import ball_2 from "../../assets/images/marketplace/ball-2.png";
import ball_bg_2 from "../../assets/images/marketplace/ball-bg-2.png";

import ball_3 from "../../assets/images/marketplace/ball-3.png";
import ball_bg_3 from "../../assets/images/marketplace/ball-bg-3.png";
import stars3 from "../../assets/images/marketplace/3-stars.png";
import stars5 from "../../assets/images/marketplace/5-stars.png";
import player from "../../assets/images/marketplace/KaKa.png";
import player_avatar_1 from "../../assets/images/marketplace/player_avatar_1.png";
import player_avatar_2 from "../../assets/images/marketplace/player_avatar_2.png";
import confetti from "../../assets/images/marketplace/confetti.svg";
// import { getFile } from "utils/ipfs";
import Player from "../Common/Player";
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { API_URL } from "consts";
import { getDetailPlayer } from "utils/client_api";

console.log("SALE ", SALE_CONTRACT.ADDRESS);
console.log("RU ", RU_CONTRACT.ADDRESS);
console.log("PLAYER ", PLAYER_CONTRACT.ADDRESS);

const renderTooltip = (level) => {
  if (level === 1) {
    return (
      <span className="level level-1">
        <Tooltip
          color="#fcb100"
          placement="top"
          title={"Overall score ranges between 44 and 63."}
          overlayClassName="tooltip-box"
        >
          Rising Star
        </Tooltip>
      </span>
    );
  }
  if (level === 2) {
    return (
      <span className="level level-2">
        <Tooltip
          color="#fcb100"
          placement="top"
          title={"Overall score ranges between 64 and 78."}
          overlayClassName="tooltip-box"
        >
          Accomplished Players
        </Tooltip>
      </span>
    );
  }
  if (level === 3) {
    return (
      <span className="level level-3">
        <Tooltip
          color="#fcb100"
          placement="top"
          title={"Overall score ranges between 79 and 88."}
          overlayClassName="tooltip-box"
        >
          World-Class
        </Tooltip>
      </span>
    );
  }
};

const boxLevelInfoMapping = [
  {
    id: 1,
    name: "Bronze",
    img: ball_1,
    bg: ball_bg_1,
    noPlayer: "5 Players",
    priceRU: 3250,
    priceBNB: 0,
    type: 1,
    description: (
      <div>
        Grab a chance that 1% of all players are {renderTooltip(3)}, 20% of
        players are {renderTooltip(2)} and 79% of players are {renderTooltip(1)}
      </div>
    ),
  },
  {
    id: 2,
    name: "Silver",
    img: ball_2,
    bg: ball_bg_2,
    noPlayer: "5 Players",
    priceRU: 6500,
    priceBNB: 0,
    type: 2,
    description: (
      <div>
        Grab a chance that 4% of all players are {renderTooltip(3)}, 36% of
        players are {renderTooltip(2)} and 60% of players are {renderTooltip(1)}
      </div>
    ),
  },
  {
    id: 3,
    name: "Gold",
    img: ball_3,
    bg: ball_bg_3,
    noPlayer: "5 Players",
    priceRU: 9750,
    priceBNB: 0,
    type: 3,
    description: (
      <div>
        Grab a chance that 10% of all players are {renderTooltip(3)}, 50% of
        players are {renderTooltip(2)} and 40% of players are {renderTooltip(1)}
      </div>
    ),
  },
];

const BoxList = () => {
  const history = useHistory();
  const { account, library } = useWeb3React();
  const [modalCloseable, setModalCloseable] = useState(true);
  const [modalMaskClosable, setModalMaskClosable] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [boxes, setBoxes] = useState(boxLevelInfoMapping);
  const [modalBody, setModalBody] = useState(null);
  const [isResultModal, setIsResultModal] = useState(false);
  const [isGold, setIsGold] = useState(false);
  const [fetchNewData, setFetchNewData] = useState(0);
  const [startCountDown, setStartCountDown] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [currentRound, setCurrentRound] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const settings = {
    className: "result-slider",
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          centerPadding: "0px",
          variableWidth: false,
          centerMode: true,
        },
      },
    ],
  };

  const settings_gold = {
    slidesToShow: 2,
  };

  function setErrorModal(msg) {
    setModalCloseable(true);
    setModalMaskClosable(true);
    setModalBody(
      <Fragment>
        <div className="error"> {msg} </div>
      </Fragment>
    );
    return;
  }

  const buyBox = async (box, captchaValue) => {
    if (isSoldOut) {
      return setErrorModal("Sold Out!");
    }

    if (!account || !library || !library.provider) {
      return setErrorModal("Please connect to a wallet first ");
    }

    const web3 = new Web3(library.provider);
    const saleContract = new web3.eth.Contract(
      SALE_CONTRACT.ABI,
      SALE_CONTRACT.ADDRESS
    );
    const ruContract = new web3.eth.Contract(
      RU_CONTRACT.ABI,
      RU_CONTRACT.ADDRESS
    );

    const accountBalanceRU = web3.utils.fromWei(
      await ruContract.methods.balanceOf(account).call()
    );

    if (parseFloat(accountBalanceRU) < parseFloat(box.priceRU)) {
      return setErrorModal("Not enough RU!");
    }
    console.log("accountBalance RU = ", accountBalanceRU);

    const currentRound = await saleContract.methods.currentRound().call();
    const roundTimeStart = await saleContract.methods
      .roundTimeStart(currentRound)
      .call();

    if (
      Date.now() < parseInt(roundTimeStart) * 1000 ||
      parseInt(roundTimeStart) === 0
    ) {
      return setErrorModal("Round not started!");
    }

    const currentBoxIndex = await saleContract.methods
      .currentBoxIndex(currentRound, box.type)
      .call();
    const boxQuantity = await saleContract.methods.boxQuantity(box.type).call();
    if (parseInt(currentBoxIndex) >= parseInt(boxQuantity)) {
      return setErrorModal("Sold Out!");
    }

    if (box.type === 3) {
      const boxGoldQuantity = await saleContract.methods
        .currentGoldBoxSold(account)
        .call();
      if (parseInt(boxGoldQuantity) >= 2) {
        return setErrorModal("One wallet can only buy maximum of 2 gold box!");
      }
    }

    try {
      let signature;
      try {
        signature = await http.post(`/api/v1/box/sign/${box.type}`, {
          captchaValue,
          round: currentRound,
        });
        console.log("signature === ", signature);
      } catch (e) {
        if (e.msg.indexOf("captcha") > -1) {
          return setErrorModal("Catpcha not match!");
        } else {
          return setErrorModal(e.msg);
        }
      }

      // buyBox(uint256 _round,uint256 _type,string memory _idBox,string[] memory _inputMetadatas,bytes memory adminSignedData)
      const { round, type, idBox, playerMetadata, adminSignedData } = signature;
      const result = await saleContract.methods
        .buyBox(round, type, idBox, playerMetadata, adminSignedData)
        .send({
          from: account,
        });
      console.log("result === ", result);
      async function getPlayerInfo(result) {
        let goldIndex = -1;
        let legendIndex = -1;
        const items = [];
        if (result.events) {
          const ids = [];
          const eventKeys = Object.keys(result.events);
          for (let i = 0; i < eventKeys.length; i++) {
            const event = result.events[i];
            if (
              event?.address?.toLowerCase() ===
              PLAYER_CONTRACT.ADDRESS.toLowerCase()
            ) {
              const id = web3.eth.abi.decodeParameter(
                "uint256",
                event?.raw?.topics[3]
              );
              ids.push(id);
            }
          }
          let maxOvr = 78;
          for (let i = 0; i < ids.length; i++) {
            // const ipfs = await playerContract.methods.tokenURI(ids[i]).call();
            const player = await getDetailPlayer(ids[i]);
            if (!player) {
              setTimeout(getPlayerInfo.bind(null, result), 10 * 1000);
              return;
            }
            if (player?.legendary) {
              legendIndex = i;
            }

            if (player?.playerAttribute?.ovr > maxOvr) {
              goldIndex = i;
              maxOvr = player.playerAttribute.ovr;
            }
            items.push({
              ...player,
              playerId: ids[i],
              tokenId: ids[i],
            });
          }
        }
        if (legendIndex !== -1 || goldIndex !== -1) {
          let itemIndex = legendIndex !== -1 ? legendIndex : goldIndex;
          const goldId = items[itemIndex].playerId;
          setIsGold(true);
          setModalBody(
            <div className="open-box-result-body">
              <h3 className="box-title">Congratulation!</h3>
              <div className="box-left">
                <div className="player-stats">
                  <div className="player-stats__top">
                    <img
                      src={legendIndex !== -1 ? stars5 : stars3}
                      alt="stars"
                    />
                    <div className="player-name">{items[itemIndex].name}</div>
                    <div className="player-id">
                      <span>ID: </span>
                      {items[itemIndex].playerId}
                    </div>
                  </div>
                  <div className="player-stats__middle">
                    <div className="player-overall">
                      OVERALL
                      <br />
                      <span>{items[itemIndex].playerAttribute.ovr}</span>
                    </div>
                  </div>
                  <div className="player-stats__bottom">
                    <div className="info-block">
                      <div className="info-name">AGE</div>
                      <div className="info-value">
                        {items[itemIndex].playerAttribute.age}
                      </div>
                    </div>
                    <div className="block-line"></div>
                    <div className="info-block">
                      <div className="info-name">POSITION</div>
                      <div className="info-value">
                        {items[itemIndex].playerAttribute.pfdPos1.pos}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="player-img">
                  {legendIndex !== -1 ? (
                    <img
                      className="player-legendary"
                      src={`${API_URL}${items[itemIndex]?.playerAttribute?.bodyImage}`}
                      alt=""
                    />
                  ) : (
                    <Player
                      {...items[itemIndex].playerAttribute?.bodyDetails}
                      pos={items[itemIndex].playerAttribute?.pfdPos1?.pos}
                    />
                  )}
                  <img
                    src={confetti}
                    alt="Confetti"
                    className="player-img__confetti"
                  />
                </div>
              </div>
              <div className="box-right">
                <div className="box-right__top">
                  <h3>Congratulation!</h3>
                </div>
                <div className="box-right__middle">
                  <Slider {...settings_gold}>
                    {items
                      .filter((i) => i.playerId !== goldId)
                      .map((p) => (
                        <div className="player-card">
                          <div className="player-avatar">
                            <Player
                              {...p.playerAttribute?.bodyDetails}
                              pos={p.playerAttribute?.pfdPos1?.pos}
                            />
                          </div>
                          <div className="player-name">{p.name}</div>
                          <div className="player-id">
                            <span>ID: {p.playerId}</span>
                          </div>
                          <div className="player-info">
                            Overall: <span>{p.playerAttribute.ovr}</span>
                          </div>
                          <div className="player-info">
                            Position:{" "}
                            <span>{p.playerAttribute.pfdPos1.pos}</span>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
                <div className="box-right__bottom">
                  <button onClick={() => history.push("/wallet")}>
                    GO TO WALLET
                  </button>
                </div>
              </div>
            </div>
          );
        } else {
          setModalBody(
            <div className="open-box-result-body">
              <div className="title">Congratulation</div>
              {/* <div className="message">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div> */}
              <Slider {...settings}>
                {items.map((item) => {
                  return (
                    <div className="slider-item">
                      <div className="item-wrapper">
                        <PlayerCard item={item} />
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <div className="actions">
                <Button
                  onClick={() => history.push("/wallet")}
                  type="primary"
                  className="app-btn app-btn__primary"
                >
                  Go to wallet
                </Button>
              </div>
            </div>
          );
        }

        setModalCloseable(true);
        setIsResultModal(true);
      }
      if (result && result.blockHash) {
        getPlayerInfo(result);
        setFetchNewData((v) => v + 1);
      }
    } catch (e) {
      if (
        e &&
        e.toString() &&
        e.toString().indexOf("reverted by the EVM") > -1
      ) {
        return setErrorModal("Sold Out!");
      }

      return setErrorModal(e.message || e.toString());
    }
  };

  const handleOk = async (box, captchaValue) => {
    setModalCloseable(false);
    setModalMaskClosable(false);
    setModalBody(
      <Fragment>
        <div className="message"> Your purchase is being processed </div>
        <div
          className="d-flex justify-content-center"
          style={{ height: "40px" }}
        >
          <Loading />
        </div>
      </Fragment>
    );
    buyBox(box, captchaValue);
    setIsGold(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsGold(false);
  };

  const selectBox = (box) => {
    setModalCloseable(true);
    setModalMaskClosable(true);
    setIsResultModal(false);
    setModalBody(
      <BuyModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        box={box}
        setModalCloseable={setModalCloseable}
      />
    );
    setIsModalVisible(true);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>00:00:00:00</span>;
    }

    return (
      <span>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const handleComplete = () => {
    setFetchNewData((v) => v + 1);
  };

  useEffect(() => {
    async function initBox() {
      console.log("init Box Price");
      // const web3 = new Web3(RPC.BSC_TESTNET);
      const web3 = new Web3(RPC.BSC);
      const saleContract = new web3.eth.Contract(
        SALE_CONTRACT.ABI,
        SALE_CONTRACT.ADDRESS
      );

      const currentRound = await saleContract.methods.currentRound().call();

      const boxesContract = await Promise.all(
        boxes.map(async (box) => {
          let priceRU = await saleContract.methods.getBoxPrice(box.type).call();
          // let priceBNB = await saleContract.methods.priceBNB(box.type).call();
          box.priceRU = web3.utils.fromWei(priceRU);
          const currentBoxIndex = await saleContract.methods
            .currentBoxIndex(currentRound, box.type)
            .call();
          const boxQuantity = await saleContract.methods
            .boxQuantity(box.type)
            .call();
          box.boxLeft = boxQuantity - currentBoxIndex;
          box.quantity = boxQuantity;
          //  box.priceBNB = web3.utils.fromWei(priceBNB);
          return box;
        })
      );

      setBoxes(boxesContract);

      const startTime = await saleContract.methods
        .roundTimeStart(currentRound)
        .call();
      const startTimeMiliseconds = parseInt(startTime) * 1000;
      if (Date.now() < startTimeMiliseconds || parseInt(startTime) === 0) {
        return;
      }

      setCurrentRound(currentRound);
      const timeStep = await saleContract.methods.timeStep().call();
      const endTimeMiliseconds =
        startTimeMiliseconds + 1000 * parseInt(timeStep);
      if (parseInt(currentRound) === 12 && Date.now() > endTimeMiliseconds) {
        setIsSoldOut(true);
        return;
      }

      setStartTime(startTimeMiliseconds);
      setEndTime(endTimeMiliseconds);
      setStartCountDown(
        Date.now() >= startTimeMiliseconds && Date.now() <= endTimeMiliseconds
      );
    }

    initBox();
  }, [fetchNewData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFetchNewData((v) => v + 1);
    }, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const showTotalBoxInfo = () => {
    let text = `<div className='text-nomal'>The number of boxes in rotation: </div>`;
    let number_box = "";
    for (let i = 0; i < boxes.length; i++) {
      if (i === boxes.length - 1) {
        number_box += `<span className='title'>${
          boxes[i].name
        }</span> : <span className='number ${boxes[i].name}'>${
          boxes[i].quantity ?? "-"
        }</span>`;
      } else {
        number_box += `<span className='title'>${
          boxes[i].name
        }</span> : <span className='number  ${boxes[i].name}'>${
          boxes[i].quantity ?? "-"
        }</span> , `;
      }
    }
    return text + `<div>${number_box}</div>`;
  };

  return (
    <Fragment>
      {isSoldOut && (
        <Row>
          <div className="marketplace-box">
            {" "}
            <div className="list-info"> End All Round! </div>{" "}
          </div>{" "}
        </Row>
      )}
      {!isSoldOut && (
        <Row>
          <div className="marketplace-box">
            <div className="list-info">
              <div className="info-box">
                <div className="label">Round</div>
                <div className="value">{currentRound}</div>
              </div>
              <div className="info-box">
                <div className="label">Start time</div>
                <div className="value">
                  {startTime > 0
                    ? moment(startTime).format("HH:mm - DD/MM/YYYY")
                    : "-"}
                </div>
              </div>
              <div className="info-box">
                <div className="label">End time</div>
                <div className="value">
                  {endTime > 0
                    ? moment(endTime).format("HH:mm - DD/MM/YYYY")
                    : "-"}
                </div>
              </div>
              {startCountDown && (
                <div className="info-box">
                  <div className="label">Time remaining</div>
                  <div className="time-remaining value">
                    <Countdown
                      date={new Date(endTime)}
                      zeroPadTime={2}
                      zeroPadDays={2}
                      renderer={renderer}
                      onComplete={handleComplete}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: showTotalBoxInfo() }}
            ></div>
            <p
              style={{ textAlign: "center", color: "rgba(255, 255, 255, 0.7)" }}
            >
              A slippage of 20, 60, 180 RU will be applied after each Bronze,
              Silver and Gold box purchased respectively.
            </p>
          </div>
        </Row>
      )}
      <Row className="justify-content-center">
        {boxLevelInfoMapping.map((item) => (
          <Col key={item.id} sm={6} xl={4} className="marketplace-item">
            <BoxCard
              boxInfo={item}
              onSelectBox={selectBox}
              setModalBody={setModalBody}
              setModalCloseable={setModalCloseable}
              setModalMaskClosable={setModalMaskClosable}
              setIsModalVisible={setIsModalVisible}
              isSoldOut={isSoldOut}
            />
          </Col>
        ))}
      </Row>
      <Modal
        visible={isModalVisible}
        closable={modalCloseable}
        maskClosable={modalMaskClosable}
        centered={true}
        width={!isResultModal ? 412 : isGold ? 960 : 720}
        onCancel={handleCancel}
        className={`app-modal${isGold ? " gold" : ""}`}
        footer={null}
      >
        {modalBody}
      </Modal>
    </Fragment>
  );
};

export default BoxList;
