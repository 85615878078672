import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, InputNumber } from "antd";
import {
  KEY_INVALID_INPUT,
  REGEX_INPUT_NUMBER,
  REGEX_QUANTITY,
} from "utils/constants";
import { useWeb3React } from "@web3-react/core";
import { convertExponential, thounsandSeparators } from "utils/helper";
import Web3 from "web3";
import Loading from "components/Common/Loading";
import { RU_CONTRACT, RU_BOX_MARKETPLACE, BOX_TYPE_NUMBER } from "consts";
import useGetRUPrice from "hooks/useGetRUPrice";
import { debounce } from "lodash";
import { weiToETH } from "utils/helper";

const ModalConfirmBuy = ({
  box,
  visible,
  handleCloseModal,
  handleFetchNewData,
}) => {
  const { account, library } = useWeb3React();
  const [isApprove, setIsApprove] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const priceRU = useGetRUPrice();
  const amount = parseInt(box.amount);
  const currentPrice = parseFloat(weiToETH(box.price));
  const type = +box.tokenId;

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    handleCloseModal();
  };

  const handleSubmit = async (values) => {
    console.log(values);
    if (library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const ruBoxMarketplaceContract = new web3.eth.Contract(
        RU_BOX_MARKETPLACE.ABI,
        RU_BOX_MARKETPLACE.ADDRESS
      );
      try {
        const result = await ruBoxMarketplaceContract.methods
          .bid(type, values.amount, box.price, box.seller)
          .send({
            from: account,
          });
        if (result && result.blockHash) {
          handleCancel();
          setTimeout(() => handleFetchNewData(), 2000);
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  const handleDebounceChange = debounce((func) => {
    func();
  }, 200);

  const handleApprove = async () => {
    if (account && library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const ruContract = new web3.eth.Contract(
        RU_CONTRACT.ABI,
        RU_CONTRACT.ADDRESS
      );
      try {
        const result = await ruContract.methods
          .approve(
            RU_BOX_MARKETPLACE.ADDRESS,
            web3.utils.toWei("9999999999999")
          )
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const ruContract = new web3.eth.Contract(
          RU_CONTRACT.ABI,
          RU_CONTRACT.ADDRESS
        );
        const approveBuy = await ruContract.methods
          .allowance(account, RU_BOX_MARKETPLACE.ADDRESS)
          .call();
        setIsApprove(web3.utils.fromWei(approveBuy) > currentPrice);
      }
      setIsFetching(false);
    }
    setIsFetching(true);
    visible && getAllowance();
  }, [account, visible]);

  if (isFetching && visible) {
    return (
      <Modal
        className="app-modal modal-form"
        title={null}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
      >
        <div
          className="d-flex justify-content-center"
          style={{ height: "10vh", alignItems: "center" }}
        >
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      name="modal-buy"
      className="app-modal modal-form"
      visible={visible}
      onCancel={handleCancel}
      title={null}
      centered
      footer={null}
    >
      {account ? (
        <div className="modal-form__content">
          <div className="title">Buy box</div>
          <div className="form-item">
            <div className="label">Curent Price</div>
            <div className="value">
              {thounsandSeparators(convertExponential(currentPrice))} RU
            </div>
          </div>
          <div className="form-item">
            <div className="label">Max Amount</div>
            <div className="value">{amount} BOX</div>
          </div>

          <Form
            name="form-buy"
            className="form-app"
            onFinish={handleSubmit}
            form={form}
          >
            <Form.Item
              name="amount"
              label={"Amount"}
              required={false}
              colon={false}
              rules={[
                {
                  required: true,
                  message: "Amount is required",
                },
                {
                  pattern: new RegExp(REGEX_QUANTITY),
                  message:
                    "Please enter a positive integer number greater than 0",
                },
                // ({}) => ({
                //   validator(_, value) {
                //     if (value && value > amount) {
                //       return Promise.reject(
                //         new Error(
                //           "Please enter a less than or equal to box amount number"
                //         )
                //       );
                //     }
                //     return Promise.resolve();
                //   },
                // }),
              ]}
            >
              <InputNumber
                autoComplete="off"
                max={amount}
                min={1}
                placeholder="0"
                onKeyDown={(e) => {
                  if (KEY_INVALID_INPUT.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <div className="group-btn-box">
              <Button name="cancel" className="app-btn" onClick={handleCancel}>
                Cancel
              </Button>

              {!isApprove && (
                <Button
                  name="approve"
                  className="app-btn app-btn__primary btn-approve"
                  onClick={handleApprove}
                  loading={isLoading}
                >
                  Approve
                </Button>
              )}
              {isApprove && (
                <Button
                  name="list"
                  className="app-btn app-btn__primary btn-list"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Buy
                </Button>
              )}
            </div>
          </Form>
        </div>
      ) : (
        <div className="modal_content">Please connect to a wallet first !</div>
      )}
    </Modal>
  );
};

export default ModalConfirmBuy;
