import React from "react";

function Player({ shoesColor, shortsColor, shirtColor, bodyType, head, pos }) {
  return (
    <div className="rifi-player">
      <rifi-player
        shirt={shirtColor}
        shoes={shoesColor}
        shorts={shortsColor}
        body={bodyType}
        head={head?.split(" ").join("_")}
        goal-keeper={pos === "GK" && "true"}
      ></rifi-player>
    </div>
  );
}

export default Player;
