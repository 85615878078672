import { NETWORK_INFO } from "./constants";

export const handleSwitchNetwork = async (chainID) => {
  if (!chainID) return;
  let ethereum = window.ethereum;
  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${Number(chainID).toString(16)}` }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        const data = NETWORK_INFO[chainID.toString()];
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: data,
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};
