import React from "react";
import Countdown from "react-countdown";
// import YoutubeEmbed from "./YoutubeEmbed";

const renderer = (props) => {
  return (
    <>
      {/* <div>{formatTimestampToDate(1636862400)}</div> */}
      <div className="title">RU - NFT Farming</div>

      <div className="subtitle">COMING IN</div>
      <ul>
        {/* <li>
          <span>{props.formatted.days}</span>
          <span>Days</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li> */}
        <li>
          <span>{props.formatted.hours}</span>
          <span>Hours</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.minutes}</span>
          <span>Minutes</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.seconds}</span>
          <span>Seconds</span>
        </li>
      </ul>
    </>
  );
};

const TheCountdown = ({ style = null, time, countdownComplete }) => {
  return (
    <div className="the-countdown" style={style}>
      <Countdown
        date={time}
        zeroPadTime={2}
        renderer={renderer}
        onComplete={countdownComplete}
      />
      {/* <div className="see-more">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://medium.com/rikkeifinance/rifi-united-nft-mystery-box-final-sale-batch-announcement-4a59e2452889"
        >
          SEE MORE
        </a>
      </div>
      <YoutubeEmbed embedId="5hvUvq3K3Gg" /> */}
    </div>
  );
};

export default TheCountdown;
