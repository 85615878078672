import http from "./http";

export const getListMarket = async (params) => {
  try {
    const result = await http.get("api/v1/market/list", { params: params });
    if (result) return result;
  } catch (err) {
    throw err;
  }
};

export const getMarketData = async (params) => {
  try {
    const result = await http.get("api/v1/market", { params: params });
    if (result) return result;
  } catch (err) {
    throw err;
  }
};

export const getMarketStatisticsData = async (params) => {
  try {
    const result = await http.get("api/v1/market/statistics", {
      params: params,
    });
    if (result) return result;
  } catch (err) {
    throw err;
  }
};

export const getMarketDataOnSale = async (params) => {
  try {
    const result = await http.get("api/v1/market/onsale", { params: params });
    if (result) return result;
  } catch (err) {
    throw err;
  }
};

export const getMarketBoxData = async (params) => {
  try {
    const result = await http.get("api/v1/market/list-box", { params: params });
    if (result) return result;
  } catch (err) {
    throw err;
  }
};

export const getOpenBoxAdminSignedData = async (body) => {
  try {
    const result = await http.post("api/v1/bulkPlayers", body);
    if (result) return result;
  } catch (err) {
    throw err;
  }
};

export const getListPlayerOnWallet = async (params) => {
  try {
    const result = await http.get("api/v1/players/search", { params: params });
    if (result) return result?.players;
  } catch (error) {
    console.log(error);
  }
};

export const getDetailPlayer = async (id) => {
  try {
    const result = await http.get(`api/v1/players/${id}`);
    if (result) return result?.player;
  } catch (error) {
    console.log(error);
  }
};
