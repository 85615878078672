import { useWeb3React } from "@web3-react/core";
import { Checkbox } from "antd";
import PlayerCard from "components/Common/PlayerCard";
import PlayerCardSkelaton from "components/Common/PlayerCardSkelaton";
import { PLAYER_CONTRACT, NFT_VAULT_CONTRACT } from "consts";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getDetailPlayer } from "utils/client_api";
import Web3 from "web3";

const NFTList = ({ isOpen }) => {
  const [players, setPlayers] = useState([]);
  const [renderPlayers, setRenderPlayers] = useState([]);
  const [isLoadingPlayer, setIsLoadingPlayer] = useState(false);

  const { account, library, chainId } = useWeb3React();

  useEffect(() => {
    async function init() {
      if (account && library && library.provider) {
        setIsLoadingPlayer(true);
        const web3 = new Web3(library.provider);
        const vaultContract = new web3.eth.Contract(
          NFT_VAULT_CONTRACT.ABI,
          NFT_VAULT_CONTRACT.ADDRESS
        );
        const balance = await vaultContract.methods
          .getBalanceNFT(account)
          .call();
        const arr = new Array(Number(balance)).fill();
        let listPlayer = await Promise.all(
          arr.map(async (i, idx) => {
            const tokenId = await vaultContract.methods
              .tokenOfOwnerByIndex(account, idx)
              .call();
            const fullData = (await getDetailPlayer(tokenId)) || {};
            return {
              ...fullData,
              tokenId,
              data: {},
            };
          })
        );

        listPlayer = listPlayer.sort(
          (player1, player2) =>
            parseInt(player1.tokenId) - parseInt(player2.tokenId)
        );
        setPlayers(listPlayer);
        setRenderPlayers(listPlayer);
      }
      setIsLoadingPlayer(false);
    }

    isOpen && init();
  }, [account, chainId, isOpen]);

  if (renderPlayers && isEmpty(renderPlayers) && !isLoadingPlayer) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ height: "100%", alignItems: "center" }}
      >
        No NFT Deposited
      </div>
    );
  }

  return (
    <div className="nft-list">
      <Row>
        {renderPlayers?.map((item, index) => (
          <Col
            key={index}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            className="wallet-item"
          >
            {isLoadingPlayer ? (
              <PlayerCardSkelaton cardType={"wallet"} />
            ) : (
              <PlayerCard
                item={item}
                isLoadingPlayer={isLoadingPlayer}
                notOnClick={true}
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NFTList;
