import React, { useState, useEffect } from "react";
import deco_legendary from "../../assets/images/wallet/deco_legendary.png";
import Player from "./Player";
import { useWeb3React } from "@web3-react/core";
import { BNFT_CONTRACT, SWAP_NFT_CONTRACT } from "consts";
import Web3 from "web3";
import http from "utils/http";
import { toast } from "react-toastify";

function BinanceLegendary({
  playerData,
  // setRunGetNFTOwner,
  handleReFetchData,
}) {
  const { account, library, chainId } = useWeb3React();
  const [isApprove, setIsApprove] = useState(false);

  const handleApprove = async () => {
    if (account && library && library.provider) {
      // setIsLoading(true);
      const web3 = new Web3(library.provider);
      const playerContract = new web3.eth.Contract(
        BNFT_CONTRACT.ABI,
        BNFT_CONTRACT.ADDRESS
      );
      try {
        const result = await playerContract.methods
          .setApprovalForAll(SWAP_NFT_CONTRACT.ADDRESS, true)
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
          handleReFetchData();
        }
      } catch (error) {}
      // setIsLoading(false);
    }
  };

  const handleSwap = async () => {
    if (account && library && library.provider) {
      console.log(playerData.tokenId);
      const result = await http.get(`api/v1/box/swapnft/${playerData.tokenId}`);
      console.log("result = ", result);
      const { tokenId, player, adminSignedData } = result;
      const web3 = new Web3(library.provider);
      const swapContract = new web3.eth.Contract(
        SWAP_NFT_CONTRACT.ABI,
        SWAP_NFT_CONTRACT.ADDRESS
      );

      try {
        const swapResult = await swapContract.methods
          .swapRequest(tokenId, player.ipfs, adminSignedData)
          .send({
            from: account,
          });

        console.log("swapResult === ", swapResult);
        if (swapResult) {
          toast.success("Swap Player Success");
          handleReFetchData();
        }
        // setRunGetNFTOwner(true);
      } catch (err) {
        toast.error("Swap Player Fail");
        console.log("Swap error: ", err);
      }
    }
  };

  useEffect(() => {
    async function getAllowance() {
      if (account && library && library.provider) {
        const web3 = new Web3(library.provider);
        const playerContract = new web3.eth.Contract(
          BNFT_CONTRACT.ABI,
          BNFT_CONTRACT.ADDRESS
        );
        const isApproveSell = await playerContract.methods
          .isApprovedForAll(account, SWAP_NFT_CONTRACT.ADDRESS)
          .call();
        setIsApprove(!!isApproveSell);
      }
      // setIsFetching(false);
    }
    // setIsFetching(true);
    getAllowance();
  }, [account, library]);

  return (
    <div className="card wallet-card">
      <div className="card-top">
        <div className="card-shadow" />
        <div className={`card-body legendary`}>
          {/* {<Player />} */}
          <div className="card-deco">
            <img src={playerData.image || deco_legendary} alt="" />
          </div>
          <div className="card-name txt--title">{playerData.name}</div>
          <div className="card-content">
            <div className="card-info">
              <div className="block-line"></div>
              <div className="info-block">
                <div className="info-name"></div>
                <div className="info-value"> </div>
              </div>
              <div className="block-line"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-bottom">
        <div className="card-id">ID: {playerData.tokenId}</div>
      </div>
      <div className="card-swap">
        {!isApprove && <button onClick={handleApprove}> Approve </button>}
        <button onClick={handleSwap} disabled={!isApprove}>
          Swap
        </button>
      </div>
    </div>
  );
}

export default BinanceLegendary;
