import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowRight from "assets/images/dashboard/arrow-right.svg";
import Web3 from "web3";
import { PLAYER_CONTRACT, EVENT, RPC } from "../../consts";
import { getFile } from "../../utils/ipfs";
import {
  formatTime,
  thounsandSeparators,
  formatFiat,
  formatRUDecimals,
} from "../../utils/helper";
import Pagination from "components/Common/Pagination";
import { getMarketData } from "../../utils/client_api";
import Player from "../Common/Player";
import useGetRUPrice from "hooks/useGetRUPrice";
import Loading from "components/Common/Loading";

const RecentlyListedTable = () => {
  const RUPrice = useGetRUPrice();
  const history = useHistory();
  // const [recentlySoldData, setRecentlySoldData] = useState([]);
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const itemPerPage = 9;
  const handlePagigation = (page) => {
    setPage(page);
  };

  useEffect(() => {
    async function getData() {
      const result = await getMarketData({
        event: EVENT.Sold,
        sortBy: "soldAt",
        orderBy: "desc",
        page: page,
        limit: itemPerPage,
      });
      return result;
    }
    async function init() {
      const web3 = new Web3(RPC.BSC);
      const playerContract = new web3.eth.Contract(
        PLAYER_CONTRACT.ABI,
        PLAYER_CONTRACT.ADDRESS
      );
      setIsLoading(true);
      const result = await getData();

      if (result && result.markets) {
        setPages(Math.ceil(result.total / itemPerPage));
        const promises =
          result.markets &&
          result.markets.length > 0 &&
          result.markets.map(async (item) => {
            let tokenId = item.tokenId;
            let startingPrice = item.startingPrice;
            let startedAt = item.startedAt;

            if (!tokenId) {
              return {};
            }
            const ipfs = await playerContract.methods.tokenURI(tokenId).call();
            const fullData = await getFile(ipfs);
            return {
              ...fullData,
              tokenId,
              startingPrice,
              startedAt,
              ipfs,
              data: {},
            };
          });
        const listPlayers = promises && (await Promise.all(promises));
        setPlayers(listPlayers || []);
      } else {
        setTimeout(getData, 5 * 1000);
      }
      setIsLoading(false);
    }

    init();
  }, [page]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ height: "10vh", alignItems: "center", width: "50%" }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="recently-sold">
      <div className="title">Recently Sold</div>
      <div className="content">
        {players && players.length > 0 ? (
          players.map((item, index) => {
            return (
              <div
                key={index}
                className="recently-sold__item"
                onClick={() => history.push(`/player/${item.tokenId}`)}
              >
                <div className="icon">
                  <Player {...item?.playerAttribute?.bodyDetails} />
                </div>
                <div className="properties">
                  <ul className="main-property">
                    <li className="main-property__item main-property__id">
                      ID: {item.tokenId}
                    </li>
                    <li className="main-property__item">
                      Position:{" "}
                      <span>{item.playerAttribute?.pfdPos1?.pos || "--"}</span>
                    </li>
                    <li className="main-property__item">
                      Overall: <span>{item.playerAttribute?.ovr || "--"}</span>
                    </li>
                  </ul>
                  {item.playerAttribute?.pfdPos1?.pos === "GK" ? (
                    <ul className="skills">
                      <li className="skill">
                        <div>DIV</div>
                        <div>{item.playerAttribute?.div || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>HAN</div>
                        <div>{item.playerAttribute?.han || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>POS</div>
                        <div>{item.playerAttribute?.pos || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>REF</div>
                        <div>{item.playerAttribute?.ref || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>SPD</div>
                        <div>{item.playerAttribute?.spd || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>KIC</div>
                        <div>{item.playerAttribute?.kic || "--"}</div>
                      </li>
                    </ul>
                  ) : (
                    <ul className="skills">
                      <li className="skill">
                        <div>PAC</div>
                        <div>{item.playerAttribute?.pac || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>PAS</div>
                        <div>{item.playerAttribute?.pas || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>DEF</div>
                        <div>{item.playerAttribute?.def || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>SHO</div>
                        <div>{item.playerAttribute?.sho || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>DRI</div>
                        <div>{item.playerAttribute?.dri || "--"}</div>
                      </li>
                      <li className="skill">
                        <div>PHY</div>
                        <div>{item.playerAttribute?.phy || "--"}</div>
                      </li>
                    </ul>
                  )}
                </div>
                <ul className="price">
                  <li className="price__item price__title">PRICE</li>
                  <li className="price__item price__money">
                    {thounsandSeparators(
                      formatRUDecimals(item.finalPrice)
                    ) || "--"}
                  </li>
                  <li className="price__item price__fiat">
                    {formatFiat(
                      formatRUDecimals(item.finalPrice) * Number(RUPrice)
                    ) || "--"}
                  </li>
                  <li className="price__item price__time">
                    {formatTime(item.startedAt) || "--"}
                  </li>
                </ul>
                <div>
                  <img src={ArrowRight} alt="arrow" />
                </div>
              </div>
            );
          })
        ) : (
          <div>No data</div>
        )}
      </div>
      <Pagination
        currentPage={page}
        pages={pages}
        onPageChange={handlePagigation}
      />
    </div>
  );
};

export default RecentlyListedTable;
