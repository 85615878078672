import React from "react";
import Title from "assets/images/marketplace/banner-title.png";
import { VAULT_NFT, TOP100 } from "utils/constants";

const Banner = ({ typeTab, setTypeTab }) => {
  return (
    <div className="banner">
      <div className="title">
        <div className="tab-marketplace">
          <div
            onClick={() => {
              setTypeTab(VAULT_NFT);
            }}
            className={`tab-item ${typeTab === VAULT_NFT ? "active" : ""}`}
          >
            Stake RU-NFT
          </div>
          <div
            onClick={() => {
              setTypeTab(TOP100);
            }}
            className={`tab-item ${typeTab === TOP100 ? "active" : ""}`}
          >
            Leaderboard
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
