import React from "react";
import { Skeleton } from "antd";
import deco_legendary from "../../assets/images/wallet/deco_legendary.png";

const BinanceLegendarySkelation = ({ cardType }) => {
  return (
    <div className="card wallet-card">
      <div className="card-top">
        <div className="card-shadow" />
        <div className={`card-body legendary`}>
          <div className="card-img"></div>
          <div className="card-deco">
            <img src={deco_legendary} alt="" />
          </div>
          <Skeleton
            className="card-name txt--title skeleton-custom"
            active={true}
            title={false}
            paragraph={{ rows: 1, width: 100 }}
          />

          <div className="card-content"></div>
        </div>
      </div>
      <div className="card-bottom">
        <Skeleton
          className={`skeleton-custom skeleton-card-id ${
            cardType === "wallet" ? null : "skeleton-card-price"
          }`}
          active={true}
          title={false}
          paragraph={{ rows: 1, width: 150 }}
        />
      </div>
    </div>
  );
};

export default BinanceLegendarySkelation;
