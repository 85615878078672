import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { useParams } from "react-router";
import BronzeBox from "assets/images/wallet/bronze_box.png";
import Box1 from "assets/images/wallet/box1.png";
import ReactTable from "react-table";
import Pagination from "components/Common/Pagination";
import IconList from "assets/images/detail_footballer/list.svg";
import IconDelist from "assets/images/detail_footballer/delist.svg";
import IconSold from "assets/images/detail_footballer/sold.svg";
import IconMint from "assets/images/detail_footballer/mint.svg";
import { EVENT } from "consts";
import {
  formatRUDecimals,
  formatTime,
  formatTokenAddress,
  thounsandSeparators,
} from "utils/helper";
import ModalSell from "./ModalSell";
import ModalOpenBox from "./ModalOpenBox";
import { useWeb3React } from "@web3-react/core";
import {
  PLAYER_BOX_CONTRACT,
  RU_BOX_MARKETPLACE,
  BOX_TYPE_NUMBER,
} from "consts";
import Web3 from "web3";

const BoxDetail = () => {
  const params = useParams();
  const { id } = params;
  const fakeData = [];

  const iconMap = {
    List: IconList,
    Delist: IconDelist,
    Sold: IconSold,
    Mint: IconMint,
  };

  const columns = [
    {
      Header: "Event",
      accessor: "event",
      Cell: ({ original }) => {
        return (
          <div className="sale-history__events">
            <img src={iconMap[original?.event]} alt="icon" />
            <div>{original?.event}</div>
          </div>
        );
      },
    },
    {
      Header: "From",
      accessor: "seller",
      Cell: ({ original }) => {
        let from;
        if (original?.event === EVENT.Mint) {
          from = "0x0000";
        } else {
          from = original?.seller;
        }
        return <div>{formatTokenAddress(from) || "--"}</div>;
      },
    },
    {
      Header: "To",
      accessor: "winner",
      Cell: ({ original }) => {
        let to;
        if (original?.event === EVENT.Mint) {
          to = original?.minter;
        } else {
          to = original?.winner;
        }
        return <div>{formatTokenAddress(to) || "--"}</div>;
      },
    },
    {
      Header: "Price",
      accessor: "price",
      width: 150,
      Cell: ({ original }) => {
        const event = original?.event;
        let price;
        if (event === "List" || event === "Delist" || event === "Mint")
          price = original?.startingPrice;
        if (event === "Sold") price = original?.finalPrice;

        return (
          <div>{thounsandSeparators(formatRUDecimals(price)) || "--"} RU</div>
        );
      },
    },
    {
      Header: "Time",
      accessor: "time",
      Cell: ({ original }) => {
        return <div>{formatTime(original?.timeline) || "--"}</div>;
      },
    },
  ];

  const { account, library } = useWeb3React();
  const [saleHistory, setSaleHistory] = useState(fakeData);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [showModalSell, setShowModalSell] = useState(false);
  const [showModalOpenBox, setShowModalOpenBox] = useState(false);
  const [amount, setAmount] = useState(0);
  const [fetchNewData, setFetchNewData] = useState(0);

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const playerBoxContract = new web3.eth.Contract(
          PLAYER_BOX_CONTRACT.ABI,
          PLAYER_BOX_CONTRACT.ADDRESS
        );
        const balanceBox = await playerBoxContract.methods
          .balanceOf(account, BOX_TYPE_NUMBER[id])
          .call();
        console.log("balanceBox === ", balanceBox);
        setAmount(balanceBox);
      }
    }
    getAllowance();
  }, [account, library, fetchNewData]);

  const handlePagigation = (page) => {
    setPage(page);
  };

  const handleShowModalSell = () => {
    setShowModalSell(true);
  };

  const handleShowModalOpenBox = () => {
    setShowModalOpenBox(true);
  };

  const handleCloseModalSell = () => {
    setShowModalSell(false);
  };

  const handleCloseModalOpenBox = () => {
    setShowModalOpenBox(false);
  };

  const handleFetchNewData = () => {
    setFetchNewData((v) => v + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPages(Math.ceil(saleHistory?.length / 3));
  }, []);

  return (
    <div className="nft-box-detail">
      <Container>
        <Row>
          <Col xl={6}>
            <div className="box-avatar">
              <div className="avatar">
                <img className="img-bg" src={Box1} alt="" />
                <img className="img-box" src={BronzeBox} alt="" />
              </div>
              <div className="price_box">
                <div className="title">Amount: {amount} (BOX)</div>
                {/* <div className="price">2500RU</div> */}
              </div>
              <div className="actions">
                {/* <Button
                  className="app-btn app-btn__primary"
                  onClick={handleShowModalBuy}
                >
                  BUY
                </Button> */}
                <Button
                  className="app-btn app-btn__primary"
                  onClick={handleShowModalSell}
                >
                  LIST
                </Button>
                {/* <Button
                  className="app-btn app-btn__primary"
                  onClick={handleShowModalDelist}
                >
                  DELIST
                </Button> */}
                <Button
                  className="app-btn app-btn__primary"
                  onClick={handleShowModalOpenBox}
                >
                  OPEN
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div className="box-info">
              <div className="info">
                <div className="name">Bronze Box</div>
                <div className="box_id">
                  <span className="label">ID: </span>
                  {id}
                </div>
              </div>
              <div className="description">
                <div className="title">Description</div>
                <div className="description__content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
                  in enim mauris felis a enim commodo. Lobortis facilisi dictum
                  volutpat purus tincidunt arcu quis. Euismod dui aliquam et id
                  habitant....
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div className="sale-history">
              <div className="title">SALE HISTORY</div>
              <ReactTable
                className="sale-table"
                data={saleHistory}
                columns={columns}
                loading={false}
                defaultPageSize={10}
                minRows={1}
                noDataText="No Data"
                manual
                currentPage={page}
                pages={pages}
                pageSizeOptions={[10, 20, 50, 100]}
                onPageChange={handlePagigation}
                PaginationComponent={Pagination}
              />
            </div>
          </Col>
        </Row> */}
      </Container>
      <ModalSell
        visible={showModalSell}
        handleCloseModal={handleCloseModalSell}
        handleFetchNewData={handleFetchNewData}
        totalBox={amount}
      />
      {/* <ModalConfirmBuy
        visible={showModalConfirmBuy}
        handleCloseModal={handleCloseModalConfirmBuy}
      /> */}
      {/* <ModalConfirmDelist
        visible={showModalConfirmDelist}
        handleCloseModal={handleCloseModalConfirmDelist}
      /> */}
      <ModalOpenBox
        visible={showModalOpenBox}
        handleCloseModal={handleCloseModalOpenBox}
        type={id}
        handleFetchNewData={handleFetchNewData}
        totalBox={amount}
      />
    </div>
  );
};

export default BoxDetail;
