import React from "react";
import Title from "assets/images/marketplace/banner-title.png";
import { BOX, PLAYERS, TOP_PLAYERS, GAME_BOX } from "utils/constants";

const Banner = ({ typeTab, setTypeTab }) => {
  return (
    <div className="banner">
      <div className="title">
        <img src={Title} alt="Marketplace" />
        <div className="tab-marketplace">
          <div
            onClick={() => {
              setTypeTab(TOP_PLAYERS);
            }}
            className={`tab-item ${typeTab === TOP_PLAYERS ? "active" : ""}`}
          >
            Top players
          </div>
          <div
            onClick={() => {
              setTypeTab(PLAYERS);
            }}
            className={`tab-item ${typeTab === PLAYERS ? "active" : ""}`}
          >
            Players
          </div>
          <div
            onClick={() => {
              setTypeTab(GAME_BOX);
            }}
            className={`tab-item ${typeTab === GAME_BOX ? "active" : ""}`}
          >
            Game Box
          </div>
          {/* <div
            onClick={() => {
              setTypeTab(BOX);
            }}
            className={`tab-item ${typeTab === BOX ? "active" : ""}`}
          >
            BOX
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
