import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { formatTimestampToDate } from "../../utils/helper";

const Completionist = ({ endTime, endLockTime }) => {
  return (
    <div className="lock">
      {Date.now() < endLockTime ? (
        <>
          <div className="lock__title">Vault unlock at:</div>
          <div className="lock__item">
            <div>
              {formatTimestampToDate(endLockTime, "miliseconds") || "--"}
            </div>
          </div>
        </>
      ) : Date.now() < endTime ? (
        <>
          <div className="lock__title">Vault end at:</div>
          <div className="lock__item">
            <div>{formatTimestampToDate(endTime, "miliseconds") || "--"}</div>
          </div>
        </>
      ) : (
        <div
          className="lock__title"
          style={{ fontSize: "1.2rem", textAlign: "center" }}
        >
          Vault Ended. Please withdraw your asset.{" "}
        </div>
      )}
    </div>
  );
};

const renderer = (props) => {
  return (
    <>
      <div className="title">
        Time left until vault locked
        <p>(Can't deposit after vault locked)</p>
      </div>
      <ul>
        <li>
          <span>{props.formatted.days}</span>
          <span>Days</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.hours}</span>
          <span>Hours</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.minutes}</span>
          <span>Minutes</span>
        </li>
        <li>
          <span className="dot">:</span>
        </li>
        <li>
          <span>{props.formatted.seconds}</span>
          <span>Seconds</span>
        </li>
      </ul>
    </>
  );
};

const CountdownVault = ({ startTime, endTime, endLockTime }) => {
  const [isComplete, setIsComplete] = useState(false);
  const [countTime, setCountTime] = useState();

  useEffect(() => {
    setCountTime(startTime);
    Date.now() > startTime ? setIsComplete(true) : setIsComplete(false);
  }, [startTime]);

  if (isComplete && endTime && endLockTime)
    return <Completionist endTime={endTime} endLockTime={endLockTime} />;

  return countTime ? (
    <Countdown
      date={countTime}
      zeroPadTime={2}
      renderer={renderer}
      onComplete={() => setIsComplete(true)}
    />
  ) : (
    <div className="title">Loading...</div>
  );
};

export default CountdownVault;
