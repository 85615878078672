import React, { useState, useEffect } from "react";
import { Modal, Radio, Button, Input, Form, InputNumber } from "antd";
import {
  KEY_INVALID_INPUT,
  REGEX_INPUT_NUMBER,
  REGEX_QUANTITY,
  SELL_TYPE,
} from "utils/constants";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import Loading from "components/Common/Loading";
import {
  PLAYER_BOX_CONTRACT,
  RU_BOX_MARKETPLACE,
  BOX_TYPE_NUMBER,
} from "consts";
import { thounsandSeparators } from "utils/helper";

const ModalSell = ({
  visible,
  handleCloseModal,
  totalBox,
  type,
  handleFetchNewData,
}) => {
  const { account, library } = useWeb3React();
  const [isFetching, setIsFetching] = useState(true);
  const [isApprove, setIsApprove] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sellPrice, setSellPrice] = useState();

  const [form] = Form.useForm();

  // const handleChangeSellType = (e) => {
  //   setSellType(e.target.value);
  //   form.resetFields();
  // };

  const handleCancel = () => {
    form.resetFields();
    setSellPrice();
    handleCloseModal();
  };

  const handleSubmit = async (values) => {
    if (library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const ruBoxMarketplaceContract = new web3.eth.Contract(
        RU_BOX_MARKETPLACE.ABI,
        RU_BOX_MARKETPLACE.ADDRESS
      );
      try {
        const result = await ruBoxMarketplaceContract.methods
          .createAuction(
            BOX_TYPE_NUMBER[type],
            values.amount,
            web3.utils.toWei(values.price)
          )
          .send({
            from: account,
          });
        if (result && result.blockHash) {
          handleFetchNewData();
          handleCancel();
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  const handleChangeInputSell = (e) => {
    setSellPrice(e.target.value);
  };

  const handleApprove = async () => {
    if (account && library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const playerBoxContract = new web3.eth.Contract(
        PLAYER_BOX_CONTRACT.ABI,
        PLAYER_BOX_CONTRACT.ADDRESS
      );
      try {
        const result = await playerBoxContract.methods
          .setApprovalForAll(RU_BOX_MARKETPLACE.ADDRESS, true)
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const playerBoxContract = new web3.eth.Contract(
          PLAYER_BOX_CONTRACT.ABI,
          PLAYER_BOX_CONTRACT.ADDRESS
        );
        const isApproveSell = await playerBoxContract.methods
          .isApprovedForAll(account, RU_BOX_MARKETPLACE.ADDRESS)
          .call();
        setIsApprove(!!isApproveSell);
      }
      setIsFetching(false);
    }
    setIsFetching(true);
    getAllowance();
  }, [account, library]);

  if (isFetching && visible) {
    return (
      <Modal
        className="app-modal modal-form"
        title={null}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
      >
        <div
          className="d-flex justify-content-center"
          style={{ height: "10vh", alignItems: "center" }}
        >
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      className="app-modal modal-form"
      title={null}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      {account ? (
        <>
          <div className="title">Sell</div>
          {/* <div className="type-sell-block">
            <div className="select-type-sell">
              <Radio.Group
                onChange={handleChangeSellType}
                value={sellType}
                options={SELL_TYPE}
              ></Radio.Group>
            </div>
          </div> */}
          <div className="modal-form__content">
            <div>
              <div className="description">
                You box will be listed on the marketplace with a fixed price. In
                order to get it back, you'll have to cancel the sale
              </div>
              <Form
                name="form-sell-fix"
                className="form-app"
                onFinish={handleSubmit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                initialValues={{
                  amount: 1,
                }}
              >
                <Form.Item
                  colon={false}
                  label="Price per box"
                  name="price"
                  required={false}
                  rules={[
                    {
                      pattern: new RegExp(REGEX_INPUT_NUMBER),
                      message:
                        "Please enter a greater than or equal to 0 number",
                    },
                    { required: true, message: "Sell at is required" },
                  ]}
                >
                  <Input
                    placeholder="0.000"
                    suffix="RU"
                    onChange={handleChangeInputSell}
                    type="number"
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (KEY_INVALID_INPUT.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  colon={false}
                  label="Amount"
                  name="amount"
                  required={false}
                  rules={[
                    {
                      pattern: new RegExp(REGEX_QUANTITY),
                      message:
                        "Please enter a positive integer number greater than 0",
                    },
                    { required: true, message: "Quantity required" },
                  ]}
                >
                  <InputNumber
                    autoComplete="off"
                    max={totalBox}
                    min={1}
                    onKeyDown={(e) => {
                      if (KEY_INVALID_INPUT.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <div className="result-box">
                  <div className="content">
                    You will receive{" "}
                    <span>{thounsandSeparators(sellPrice * 0.95, 4)}</span> RU
                    after subtracting a 5% fee.
                  </div>
                </div>
                <div className="group-btn-box">
                  <Button className="app-btn btn-cancel" onClick={handleCancel}>
                    Cancel
                  </Button>

                  {!isApprove && (
                    <Button
                      name="approve"
                      className="app-btn app-btn__primary btn-approve"
                      onClick={handleApprove}
                      loading={isLoading}
                    >
                      Approve
                    </Button>
                  )}
                  {isApprove && (
                    <Button
                      name="list"
                      className="app-btn app-btn__primary btn-list"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      List
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </>
      ) : (
        <div className="modal_content">Please connect to a wallet first !</div>
      )}
    </Modal>
  );
};

export default ModalSell;
