import React, { useState, useEffect } from "react";
import { Modal, Radio, Button, Input, Form } from "antd";
import {
  KEY_INVALID_INPUT,
  REGEX_INPUT_NUMBER,
  SELL_TYPE,
} from "utils/constants";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import Loading from "components/Common/Loading";
import { PLAYER_CONTRACT, MARKET_CONTRACT } from "../../consts";
import { thounsandSeparators } from "utils/helper";

const ModalSell = ({
  form,
  visible,
  handleCancel,
  handleChangeSellType,
  sellType,
  handleSubmit,
  handleChangeInputSell,
  sellPrice,
  statusLoading,
}) => {
  const { account, library } = useWeb3React();
  const [isFetching, setIsFetching] = useState(true);
  const [isApprove, setIsApprove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleApprove = async () => {
    if (account && library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const playerContract = new web3.eth.Contract(
        PLAYER_CONTRACT.ABI,
        PLAYER_CONTRACT.ADDRESS
      );
      try {
        const result = await playerContract.methods
          .setApprovalForAll(MARKET_CONTRACT.ADDRESS, true)
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const playerContract = new web3.eth.Contract(
          PLAYER_CONTRACT.ABI,
          PLAYER_CONTRACT.ADDRESS
        );
        const isApproveSell = await playerContract.methods
          .isApprovedForAll(account, MARKET_CONTRACT.ADDRESS)
          .call();
        setIsApprove(!!isApproveSell);
      }
      setIsFetching(false);
    }
    setIsFetching(true);
    getAllowance();
  }, [account, library]);

  if (isFetching && visible) {
    return (
      <Modal
        className="app-modal modal-form"
        title={null}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
      >
        <div
          className="d-flex justify-content-center"
          style={{ height: "10vh", alignItems: "center" }}
        >
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      className="app-modal modal-form"
      title={null}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      {account ? (
        <>
          <div className="title">Sell</div>
          <div className="type-sell-block">
            <div className="select-type-sell">
              <Radio.Group
                onChange={handleChangeSellType}
                value={sellType}
                options={SELL_TYPE}
              ></Radio.Group>
            </div>
          </div>
          <div className="modal-form__content">
            {sellType === SELL_TYPE[0].value ? (
              <div>
                <div className="description">
                  You player will be listed on the marketplace with a fixed
                  price. In order to get it back, you'll have to cancel the sale
                </div>
                <Form
                  name="form-sell-fix"
                  className="form-app"
                  onFinish={handleSubmit}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  form={form}
                >
                  <Form.Item
                    colon={false}
                    label="Sell at"
                    name="sell"
                    required={false}
                    rules={[
                      {
                        pattern: new RegExp(REGEX_INPUT_NUMBER),
                        message:
                          "Please enter a greater than or equal to 0 number",
                      },
                      { required: true, message: "Sell at is required" },
                    ]}
                  >
                    <Input
                      placeholder="0.000"
                      suffix="RU"
                      onChange={handleChangeInputSell}
                      type="number"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (KEY_INVALID_INPUT.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <div className="result-box">
                    <div className="content">
                      You will receive{" "}
                      <span>{thounsandSeparators(sellPrice * 0.95, 4)}</span> RU
                      after subtracting a 5% fee.
                    </div>
                  </div>
                  <div className="group-btn-box">
                    <Button
                      className="app-btn btn-cancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>

                    {!isApprove && (
                      <Button
                        name="approve"
                        className="app-btn app-btn__primary btn-approve"
                        onClick={handleApprove}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Approve
                      </Button>
                    )}
                    {isApprove && (
                      <Button
                        name="list"
                        className="app-btn app-btn__primary btn-list"
                        htmlType="submit"
                        loading={statusLoading}
                        disabled={statusLoading}
                      >
                        List
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            ) : (
              <div>
                <div className="description">
                  Auctions can down over time. When time expires, the price will
                  stop changing, but your asset will still stay in the
                  Marketplace. In order to bring it back to your inventory, you
                  have to cancel the auction.
                </div>
                <Form
                  name="form-sell-auction"
                  className="form-app"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={handleSubmit}
                  form={form}
                >
                  <Form.Item
                    colon={false}
                    label="Starting price"
                    name="startingPrice"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Starting price is required",
                      },

                      {
                        pattern: new RegExp(REGEX_INPUT_NUMBER),
                        message:
                          "Please enter a greater than or equal to 0 number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="0.000"
                      suffix="RU"
                      type="number"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (KEY_INVALID_INPUT.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label="Ending price"
                    name="endingPrice"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Ending price is required",
                      },

                      {
                        pattern: new RegExp(REGEX_INPUT_NUMBER),
                        message:
                          "Please enter a greater than or equal to 0 number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="0.000"
                      suffix="RU"
                      type="number"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (KEY_INVALID_INPUT.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label="Duration"
                    name="duration"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: "Duration is required",
                      },
                      ({}) => ({
                        validator(_, value) {
                          if (value && value <= 0) {
                            return Promise.reject(
                              new Error("Please enter a greater than 0 number")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="0"
                      suffix="DAY"
                      type="number"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (KEY_INVALID_INPUT.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <div className="result-box">
                    <div className="content">
                      A 5% fee will be taken from the final sale price.
                    </div>
                  </div>
                  <div className="group-btn-box">
                    <Button
                      className="app-btn btn-cancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>

                    {!isApprove && (
                      <Button
                        name="approve"
                        className="app-btn app-btn__primary btn-approve"
                        onClick={handleApprove}
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        Approve
                      </Button>
                    )}
                    {isApprove && (
                      <Button
                        name="list"
                        className="app-btn app-btn__primary btn-list"
                        htmlType="submit"
                        loading={statusLoading}
                        disabled={statusLoading}
                      >
                        List
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="modal_content">Please connect to a wallet first !</div>
      )}
    </Modal>
  );
};

export default ModalSell;
