import React, { useEffect } from "react";
import { Checkbox } from "antd";
import Loading from "components/Common/Loading";
import PlayerCard from "components/Common/PlayerCard";
import PlayerCardSkelaton from "components/Common/PlayerCardSkelaton";
import { Col, Row } from "react-bootstrap";
import { convertOvr2RU } from "utils/helper";
import BigNumber from "bignumber.js";
import { isEmpty } from "lodash";

// BigNumber.config({ EXPONENTIAL_AT: 200 });

function findPlayerInfo(tokenId, players) {
  for (let i = 0; i < players.length; i++) {
    if (tokenId == players[i].tokenId) {
      const { ovr, age } = players[i].playerAttribute;
      const { legendary } = players[i];
      return { ovr, age, legendary };
    }
  }
  return null;
}

const NFTList = (props) => {
  const {
    handleSelectNft,
    listNftDeposit,
    // listNftInVault,
    // isDepositNFT,
    setMinAmount,
    isLoadingPlayer,
    players,
    className,
  } = props;

  // useEffect(() => {
  //   if (isWithdrawNFT) {
  //     setRenderPlayers(listNftInVault);
  //   } else {
  //     setRenderPlayers(players);
  //   }
  // }, [players, listNftInVault, isWithdrawNFT]);

  useEffect(() => {
    if (!listNftDeposit) {
      return;
    }

    let minAmount = new BigNumber(0);
    for (let i = 0; i < listNftDeposit.length; i++) {
      const tokenId = listNftDeposit[i];
      const player = findPlayerInfo(tokenId, players);
      if (player) {
        const { ovr, age, legendary } = player;
        const { ru, str } = convertOvr2RU(ovr, age, legendary);
        minAmount = minAmount.plus(ru);
      }
    }

    setMinAmount(minAmount.div(1e18).toFixed(2));
  }, [listNftDeposit]);

  if (isLoadingPlayer) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ height: "50vh", alignItems: "center" }}
      >
        <Loading />
      </div>
    );
  }
  if (players && isEmpty(players) && !isLoadingPlayer) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ height: "100%", alignItems: "center" }}
      >
        No NFT
      </div>
    );
  }

  return (
    <div className={`nft-list ${className}`}>
      <Checkbox.Group onChange={handleSelectNft} value={listNftDeposit}>
        <Row>
          {players?.map((item, index) => (
            <Col
              key={index}
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="wallet-item"
            >
              {isLoadingPlayer ? (
                <PlayerCardSkelaton cardType={"wallet"} />
              ) : (
                <Checkbox value={item.tokenId}>
                  <PlayerCard
                    item={item}
                    isLoadingPlayer={isLoadingPlayer}
                    notOnClick={true}
                  />
                </Checkbox>
              )}
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default NFTList;
