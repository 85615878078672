import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowRight from "assets/images/dashboard/arrow-right.svg";
import { Container } from "react-bootstrap";
import Web3 from "web3";
import { PLAYER_CONTRACT, RPC } from "../../consts";
import { formatTokenAddress } from "../../utils/helper";
import { getDetailPlayer, getMarketData } from "../../utils/client_api";
import Player from "../Common/Player";
import { API_URL } from "consts";
import InfiniteScroll from "react-infinite-scroll-component";

const TopPlayer = () => {
  const LIMIT = 100;
  const history = useHistory();
  const [players, setPlayers] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);

  const sliceAPIData = (start, end) => {
    return APIData.slice(start, end);
  };

  useEffect(() => {
    async function getData() {
      const result = await getMarketData({
        event: "Mint",
        topPlayer: true,
        orderBy: "desc",
        limit: LIMIT,
      });
      if (result && result.markets) {
        setAPIData(result.markets);
      } else {
        setTimeout(getData, 1000 * 5);
      }
    }
    getData();
  }, []);

  async function fetchMoreData() {
    const more = 20;
    const web3 = new Web3(RPC.BSC);
    const playerContract = new web3.eth.Contract(
      PLAYER_CONTRACT.ABI,
      PLAYER_CONTRACT.ADDRESS
    );
    let result = sliceAPIData(start, end);

    setStart((prevState) => prevState + more);
    setEnd((prevState) => prevState + more);
    if (result) {
      const promises =
        result &&
        result.length > 0 &&
        result.map(async (item) => {
          let tokenId = item.tokenId;
          let startingPrice = item.startingPrice;
          let startedAt = item.startedAt;

          const fullData = await getDetailPlayer(tokenId);
          const owner = await playerContract.methods.ownerOf(tokenId).call();
          return {
            ...fullData,
            tokenId,
            startingPrice,
            startedAt,
            data: {},
            owner,
          };
        });
      const listPlayers = promises && (await Promise.all(promises));
      setPlayers((prevState) => prevState.concat(listPlayers || []));
    }
  }

  useEffect(() => {
    if (APIData?.length > 0) {
      fetchMoreData();
    }
  }, [APIData]);

  const handleClickOwner = (e, address) => {
    e.stopPropagation();
    window.open(`https://bscscan.com/address/${address}`, "__blank");
  };

  return (
    <Container className="top-player">
      <div className="top-player__main_content">
        <div className="title"> Top Players </div>
        <div className="recently-listed">
          <div className="content">
            <InfiniteScroll
              dataLength={players.length}
              next={fetchMoreData}
              hasMore={end <= LIMIT}
              loader={
                <p
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    fontSize: "1.3rem",
                  }}
                >
                  Loading...
                </p>
              }
            >
              {players.map((item, index) => {
                return (
                  <div
                    key={item.tokenId}
                    className={`recently-listed__item
                  ${
                    (index === 0 || index === 1 || index === 2) &&
                    `recently-listed__item-top-${index}`
                  }`}
                    onClick={() => history.push(`/player/${item.tokenId}`)}
                  >
                    <div className="rank">{index + 1}</div>
                    <div className="name-icon">
                      <div className="icon">
                        {item.legendary ? (
                          <img
                            className="player-legend"
                            src={`${API_URL}${item?.playerAttribute?.bodyImage}`}
                            alt="players"
                          />
                        ) : (
                          <Player {...item?.playerAttribute?.bodyDetails} />
                        )}
                      </div>
                      <div className="name">{item.name || "--"}</div>
                    </div>
                    <div className="properties">
                      <ul className="main-property">
                        <li className="main-property__item main-property__id">
                          ID: {item.tokenId}
                        </li>
                        <li className="main-property__item main-property__age">
                          Age: <span>{item.playerAttribute?.age}</span>
                        </li>
                        <li className="main-property__item">
                          Position:{" "}
                          <span>
                            {item.playerAttribute?.pfdPos1?.pos || "--"}
                          </span>
                        </li>
                        <li className="main-property__item">
                          Overall:{" "}
                          <span>{item.playerAttribute?.ovr || "--"}</span>
                        </li>
                      </ul>
                      {item.playerAttribute?.pfdPos1?.pos === "GK" ? (
                        <ul className="skills">
                          <li className="skill">
                            <div>DIV</div>
                            <div>{item.playerAttribute?.div || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>HAN</div>
                            <div>{item.playerAttribute?.han || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>POS</div>
                            <div>{item.playerAttribute?.pos || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>REF</div>
                            <div>{item.playerAttribute?.ref || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>SPD</div>
                            <div>{item.playerAttribute?.spd || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>KIC</div>
                            <div>{item.playerAttribute?.kic || "--"}</div>
                          </li>
                        </ul>
                      ) : (
                        <ul className="skills">
                          <li className="skill">
                            <div>PAC</div>
                            <div>{item.playerAttribute?.pac || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>PAS</div>
                            <div>{item.playerAttribute?.pas || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>DEF</div>
                            <div>{item.playerAttribute?.def || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>SHO</div>
                            <div>{item.playerAttribute?.sho || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>DRI</div>
                            <div>{item.playerAttribute?.dri || "--"}</div>
                          </li>
                          <li className="skill">
                            <div>PHY</div>
                            <div>{item.playerAttribute?.phy || "--"}</div>
                          </li>
                        </ul>
                      )}
                    </div>
                    <ul className="owner">
                      <li className="owner__item owner__title"> OWNER </li>
                      <li
                        className="owner__item owner__fiat"
                        onClick={(e) => handleClickOwner(e, item.owner)}
                      >
                        {formatTokenAddress(item.owner)}
                      </li>
                    </ul>
                    <div>
                      <img src={ArrowRight} alt="arrow" />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TopPlayer;
