import React, { useEffect, useRef, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Tooltip } from "antd";
import CountUp from "react-countup";
import Loading from "components/Common/Loading";
import { useWeb3React } from "@web3-react/core";
import Pace from "assets/images/detail_footballer/pace.svg";
import Passing from "assets/images/detail_footballer/passing.svg";
import Defence from "assets/images/detail_footballer/defence.svg";
import Shooting from "assets/images/detail_footballer/shooting.svg";
import Dribbling from "assets/images/detail_footballer/dribbling.svg";
import Physical from "assets/images/detail_footballer/physical.svg";
import Diving from "assets/images/detail_footballer/gk/diving.svg";
import Handing from "assets/images/detail_footballer/gk/handing.svg";
import Positioning from "assets/images/detail_footballer/gk/positioning.svg";
import Reflexes from "assets/images/detail_footballer/gk/reflexes.svg";
import Speed from "assets/images/detail_footballer/gk/speed.svg";
import Kicking from "assets/images/detail_footballer/gk/kicking.svg";
import Talents from "assets/images/detail_footballer/talents.svg";
import { thounsandSeparators, formatTimeFootBaller } from "utils/helper";
import Legandary from "assets/images/detail_footballer/legandary.png";
import LegandaryBg from "assets/images/detail_footballer/legandary-bg.png";
import Ball1 from "assets/images/detail_footballer/level1.png";
import Ball2 from "assets/images/detail_footballer/level2.png";
import Ball3 from "assets/images/detail_footballer/level3.png";
import BallEmpty from "assets/images/detail_footballer/ball-empty.png";
import Player from "components/Common/Player";
import { API_URL } from "consts";
import { TAB_ATTRIBUTE } from "utils/constants";

const FootballerInfo = ({
  playerInfo,
  id,
  handleShowModalSell,
  handleShowModalConfirmBuy,
  handleShowModalConfirmDelist,
  saleInfo,
  currentPrice,
}) => {
  playerInfo = playerInfo?.name
    ? playerInfo
    : {
        playerAttribute: {},
        species: {},
        league: {},
        club: {},
        isOwner: null,
        isOnSale: null,
      };

  const {
    name,
    playerAttribute: {
      ovr,
      age,
      height,
      weight,
      pfdFoot,
      pfdPos1,
      wage,
      // value,
      pfdPos2,
      pac,
      pas,
      def,
      sho,
      dri,
      phy,
      div,
      han,
      pos,
      ref,
      spd,
      kic,
      origOvr,
      origDiv,
      origHan,
      origKic,
      origPos,
      origRef,
      origSpd,
      origPac,
      origPas,
      origDef,
      origSho,
      origDri,
      origPhy,
      pfdPos3,
    },
    species: { name: specialName },
    league: { name: leagueName },
    club: { name: clubName },
    legendary,
    talentCards,
    isOwner,
    isOnSale,
    tokenId,
  } = playerInfo;

  const attributeOrig = [origPac, origPas, origDef, origSho, origDri, origPhy];
  const attributeOrigGk = [
    origDiv,
    origHan,
    origKic,
    origPos,
    origRef,
    origSpd,
  ];

  const { account } = useWeb3React();
  const lastCurrentPrice = useRef();
  const firstRenderRef = useRef(0);

  const [tab, setTab] = useState(TAB_ATTRIBUTE[0].value);

  const getPreferedPosition = () => {
    let result = "";
    result += pfdPos1?.pos;

    if (pfdPos2?.pos) {
      result += " / " + pfdPos2?.pos;
    }

    if (pfdPos3?.pos) {
      result += " / " + pfdPos3?.pos;
    }

    return result;
  };

  const getActionBtn = () => {
    if (!account && isOnSale) {
      return (
        <Button className="btn-buy" onClick={handleShowModalConfirmBuy}>
          BUY
        </Button>
      );
    }

    if (!account && !isOnSale) {
      return null;
    }

    if (isOwner == null && isOnSale == null) {
      return (
        <div>
          {" "}
          <Loading />{" "}
        </div>
      );
    }

    if (isOnSale && saleInfo.isSeller) {
      return (
        <Button className="btn-list" onClick={handleShowModalConfirmDelist}>
          DELIST
        </Button>
      );
    }

    if (!isOwner && isOnSale === false) {
      return <div> </div>;
    }

    if (!isOwner && isOnSale) {
      return (
        <Button className="btn-buy" onClick={handleShowModalConfirmBuy}>
          BUY
        </Button>
      );
    }

    if (isOwner) {
      return (
        <Button className="btn-list" onClick={handleShowModalSell}>
          LIST
        </Button>
      );
    }
  };

  const handleCheckLevelPlayer = (playerInfo) => {
    const ovr = playerInfo?.playerAttribute?.ovr;
    if (!ovr) return BallEmpty;
    if (ovr >= 44 && ovr <= 63) return Ball1;
    else if (ovr >= 64 && ovr <= 78) return Ball2;
    else if (ovr >= 79 && ovr <= 88) return Ball3;
    else return Ball1;
  };

  const checkTrainingGK = () => {
    return attributeOrigGk.some((item) => item !== undefined);
  };

  const checkTrainingPlayer = () => {
    return attributeOrig.some((item) => item !== undefined);
  };
  const renderAttr = (value1, value2) => {
    if (tab === TAB_ATTRIBUTE[1].value) {
      return value1;
    } else if (value2) {
      return value2;
    } else {
      return value1;
    }
  };

  useEffect(() => {
    lastCurrentPrice.current = currentPrice;
    firstRenderRef.current++;
  }, [currentPrice]);

  return (
    <div className="detail-footballer__info">
      <Container className="info-wrapper">
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className="col-avatar">
            {playerInfo?.legendary ? (
              <div className="avatar-player">
                <div>
                  <img className="ball" src={Legandary} />
                  <img className="legandary" src={LegandaryBg} />

                  <div className="player-img">
                    <img
                      className="player-legend"
                      src={`${API_URL}${playerInfo?.playerAttribute?.bodyImage}`}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="avatar-player">
                <img
                  className="ball"
                  src={handleCheckLevelPlayer(playerInfo)}
                />

                <div className="player-img">
                  {playerInfo.playerAttribute?.bodyDetails?.head ? (
                    <Player
                      {...playerInfo.playerAttribute?.bodyDetails}
                      pos={pfdPos1?.pos}
                    />
                  ) : null}
                </div>
              </div>
            )}
            <div className="info-avatar__body">
              {legendary && (
                <div className="description">
                  {playerInfo.playerAttribute?.bio}
                </div>
              )}

              <div className="price-box">
                {isOnSale &&
                saleInfo?.startingPrice &&
                saleInfo?.endingPrice &&
                saleInfo?.endingPrice !== saleInfo?.startingPrice ? (
                  <div className="price-auction-box">
                    <div className="title-auction">
                      Auction in {formatTimeFootBaller(saleInfo?.duration)}
                    </div>
                    <div className="range_price">
                      <div>
                        {thounsandSeparators(Number(saleInfo.startingPrice), 4)}{" "}
                        RU
                      </div>
                      <div>
                        {thounsandSeparators(Number(saleInfo.endingPrice), 4)}{" "}
                        RU
                      </div>
                    </div>
                  </div>
                ) : null}
                {isOnSale &&
                currentPrice !== undefined &&
                saleInfo?.endingPrice !== saleInfo?.startingPrice ? (
                  <div className="action-auction">
                    <div>
                      <div>Price</div>
                      <div className="price">
                        <CountUp
                          start={
                            lastCurrentPrice.current === currentPrice &&
                            firstRenderRef.current < 3
                              ? saleInfo?.startingPrice
                              : lastCurrentPrice.current
                          }
                          end={currentPrice}
                          duration={1.5}
                          separator=" "
                          decimals={3}
                          decimal="."
                        />{" "}
                        RU
                      </div>
                    </div>
                    <div className="buttons">{getActionBtn()}</div>
                  </div>
                ) : isOnSale && currentPrice !== undefined ? (
                  <div className="fix-price">
                    <div>Price</div>
                    <div className="price">
                      {thounsandSeparators(currentPrice, 3)} RU
                    </div>
                    <div className="buttons">{getActionBtn()}</div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="buttons">{getActionBtn()}</div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="col-info-property"
          >
            <>
              <div className="info-property">
                <div className="info-property__general">
                  <Container>
                    <Row>
                      <Col sm={12} md={6} lg={12} xl={6}>
                        <div className="main-info">
                          <div className="footballer-name">
                            <div className="name"> {name} </div>
                            <div className="id">
                              <span>ID:</span> {tokenId}
                            </div>
                          </div>
                          <div className="footballer-overall">
                            <div className="name">OVERALL</div>
                            <div className="value">{ovr}</div>
                          </div>
                          <ul>
                            <li>
                              <div className="property">AGE</div>
                              <div className="value">{age}</div>
                            </li>
                            <li>
                              <div className="property">HEIGHT</div>
                              <div className="value"> {height} CM </div>
                            </li>
                            <li>
                              <div className="property">WEIGHT</div>
                              <div className="value">{weight} KG</div>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={12} xl={6}>
                        <div className="d-flex justify-content-center">
                          <ul className="property-info">
                            <li>
                              <div className="property">Preferred Foot</div>
                              <div className="value">
                                {" "}
                                {pfdFoot === "R" ? "Right" : "Left"}{" "}
                              </div>
                            </li>
                            <li>
                              <div className="property">
                                Preferred Positions
                              </div>
                              <div className="value">
                                {" "}
                                {getPreferedPosition()}{" "}
                              </div>
                            </li>
                            <li>
                              <div className="property">
                                Special (Nationality)
                              </div>
                              <div className="value">{specialName} </div>
                            </li>
                            <li>
                              <div className="property">League</div>
                              <div className="value"> {leagueName} </div>
                            </li>
                            <li>
                              <div className="property">Club</div>
                              <div className="value">{clubName} </div>
                            </li>
                            <li>
                              <div className="property">Wage</div>
                              <div className="value">{wage} RU</div>
                            </li>
                            {/* <li>
                      <div className="property">Value</div>
                      <div className="value">{value} RU</div>
                    </li>
                    <li>
                      <div className="property">Talents</div>
                      <div className="value">{getTalents(talentCards)}</div>
                    </li> */}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {talentCards && talentCards.length > 0 && (
                  <div className="info-property__talents">
                    <img src={Talents} alt="Talents" className="talents-icon" />
                    <Row className="talent-list">
                      {talentCards.map((talent) => (
                        <Col
                          key={talent["_id"]}
                          xs={6}
                          sm={4}
                          md={3}
                          className="talent-item"
                        >
                          <Tooltip
                            title={talent.description}
                            placement="bottom"
                            overlayClassName="talent-description-tooltip"
                          >
                            {talent.name}
                          </Tooltip>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
                <div className="info-property__type">
                  {checkTrainingGK() || checkTrainingPlayer() ? (
                    <div className="tab-attribute">
                      <div
                        onClick={() => {
                          setTab(TAB_ATTRIBUTE[0].value);
                        }}
                        className={`tab-item ${
                          tab === TAB_ATTRIBUTE[0].value ? "active" : ""
                        }`}
                      >
                        {TAB_ATTRIBUTE[0].label}
                      </div>

                      <div
                        onClick={() => {
                          setTab(TAB_ATTRIBUTE[1].value);
                        }}
                        className={`tab-item ${
                          tab === TAB_ATTRIBUTE[1].value ? "active" : ""
                        }`}
                      >
                        {TAB_ATTRIBUTE[1].label}
                      </div>
                    </div>
                  ) : null}
                  <Row className="skills">
                    {pfdPos1 && pfdPos1?.pos === "GK" ? (
                      <>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Diving} alt="Pace" />
                            <span>DIVING</span>
                            <div>{renderAttr(div, origDiv)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Handing} alt="Pace" />
                            <span>HANDING</span>
                            <div>{renderAttr(han, origHan)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Positioning} alt="Pace" />
                            <span>POSITIONING</span>
                            <div>{renderAttr(pos, origPos)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Reflexes} alt="Pace" />
                            <span>REFLEXES</span>
                            <div>{renderAttr(ref, origRef)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Speed} alt="Pace" />
                            <span>SPEED</span>
                            <div>{renderAttr(spd, origSpd)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Kicking} alt="Pace" />
                            <span>KICKING</span>
                            <div>{renderAttr(kic, origKic)}</div>
                          </li>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Pace} alt="Pace" />
                            <span>Pace</span>
                            <div>{renderAttr(pac, origPac)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Passing} alt="Pace" />
                            <span>PASSING</span>
                            <div>{renderAttr(pas, origPas)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Defence} alt="Pace" />
                            <span>DEFENCE</span>
                            <div>{renderAttr(def, origDef)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Shooting} alt="Pace" />
                            <span>SHOOTING</span>
                            <div>{renderAttr(sho, origSho)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Dribbling} alt="Pace" />
                            <span>DRIBBLING</span>
                            <div>{renderAttr(dri, origDri)}</div>
                          </li>
                        </Col>
                        <Col md={2} lg={4} xl={2}>
                          <li className={"skill"}>
                            <img src={Physical} alt="Pace" />
                            <span>PHYSICAL</span>
                            <div>{renderAttr(phy, origPhy)}</div>
                          </li>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FootballerInfo;
