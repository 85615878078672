import useWindowDimensions from "hooks/useWindowDimensions";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { thounsandSeparators } from "../../../utils/helper";
import { REGEX_INPUT_NUMBER } from "../../../utils/constants";
import { Checkbox, Modal, Button, Collapse } from "antd";
import NFTList from "./NFTList";
import { RU_CONTRACT, PLAYER_CONTRACT, NFT_VAULT_CONTRACT, RPC } from "consts";
import http from "utils/http";
import BigNumber from "bignumber.js";
import Reward from "./Reward";
import Countdown from "./Countdown";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import RuIcon from "assets/icons/ic_ru.svg";
import TheCountdown from "../../TheCountdown/TheCountdown";
import { useHistory, useParams } from "react-router";
import Banner from "./Banner";
import { VAULT_NFT, TOP100 } from "utils/constants";
import Top100 from "./Top100";
import CountUp from "react-countup";
import usePrevious from "hooks/usePrevious";
import { getListPlayerOnWallet } from "utils/client_api";

const { Panel } = Collapse;

console.log("NFT_VAULT = ", NFT_VAULT_CONTRACT.ADDRESS);

const NFTVault = () => {
  const { account, library, chainId } = useWeb3React();
  const [depositTabActive, setDepositTabActive] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [balanceRUInWallet, setBalanceRUInWallet] = useState(0);
  const [balanceRUInVault, setBalanceRUInVault] = useState(0);
  const [listNftInVault, setListNftInVault] = useState([]);
  // const [interest, setInterest] = useState(0);
  // const [rate, setRate] = useState(0);
  const [isLoadingRU, setIsLoadingRU] = useState(false);
  const [isLoadingNFT, setIsLoadingNFT] = useState(false);
  const [depositing, setDepositing] = useState(false);
  const [withdrawing, setWithdrawing] = useState(false);
  const [unStaking, setUnStaking] = useState(false);
  const [isApproveRU, setIsApproveRU] = useState(false);
  const [isApproveNFT, setIsApproveNFT] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isDepositNFT, setIsDepositNFT] = useState(false);
  const [openModalNft, setOpenModalNft] = useState(false);
  const [listNftDeposit, setListNftDeposit] = useState([]);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [reFetchInfo, setReFetchInfo] = useState(0);
  const [requestWithdrawTime, setRequestWithdrawTime] = useState(0);
  const [unboundingTime, setUnboundingTime] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [isLoadingPlayer, setIsLoadingPlayer] = useState(false);
  const [players, setPlayers] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isCollapse, setIsCollapse] = useState([]);
  const [ruTVL, setRuTVL] = useState();
  const previousTVL = usePrevious(ruTVL);
  const [typeTab, setTypeTab] = useState(VAULT_NFT);

  const toggleStart = 768;
  const { width } = useWindowDimensions();
  const param = useParams();

  const [isCountdownComplete, setIsCountdownComplete] = useState(false);
  const isForceStarted = param && param.id === "ru";
  const time = isForceStarted ? 0 : 1638439200000; // Dec 02 2021 17:00 PM
  // const time = 1638429419000;

  const countdownComplete = () => {
    setIsCountdownComplete(true);
    return;
  };

  useEffect(() => {
    if (Date.now() >= time) setIsCountdownComplete(true);
  }, []);

  const clearInput = () => {
    setInputValue("");
  };

  const handleCheckNft = (e) => {
    if (!e.target.checked) {
      setListNftDeposit();
      setMinAmount(0);
      setCheckAll(false);
      setIndeterminate(false);
    }
    setIsDepositNFT(e.target.checked);
  };

  const handleApproveRU = async () => {
    try {
      setIsLoadingRU(true);
      const web3 = new Web3(library.provider);
      const ruContract = new web3.eth.Contract(
        RU_CONTRACT.ABI,
        RU_CONTRACT.ADDRESS
      );
      const approveNumber = "10000000000000000000000000000";
      // loading overlay
      const result = await ruContract.methods
        .approve(NFT_VAULT_CONTRACT.ADDRESS, approveNumber)
        .send({
          from: account,
        });

      if (result && result.blockHash) {
        setIsApproveRU(true);
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsLoadingRU(false);
    }
  };

  const handleApproveNFT = async () => {
    if (account && library && library.provider) {
      setIsLoadingNFT(true);
      const web3 = new Web3(library.provider);
      const playerContract = new web3.eth.Contract(
        PLAYER_CONTRACT.ABI,
        PLAYER_CONTRACT.ADDRESS
      );
      try {
        const result = await playerContract.methods
          .setApprovalForAll(NFT_VAULT_CONTRACT.ADDRESS, true)
          .send({
            from: account,
          });
        if (result) {
          setIsApproveNFT(true);
        }
      } catch (error) {}
      setIsLoadingNFT(false);
    }
    // setIsApproveNFT(true);
  };

  // const handleWithdrawRU = async () => {};
  // const handleWithdrawNFT = async () => {};

  const handleWithdrawAll = async () => {
    if (account && library && library.provider) {
      try {
        setWithdrawing(true);
        const web3 = new Web3(library.provider);
        const vaultContract = new web3.eth.Contract(
          NFT_VAULT_CONTRACT.ABI,
          NFT_VAULT_CONTRACT.ADDRESS
        );
        const result = await vaultContract.methods.withdrawAll().send({
          from: account,
        });
        refetchInfo();
      } catch (error) {
        console.log(error);
      }
      setWithdrawing(false);
    }
  };

  const handleSelectNftDeposit = (checkedValues) => {
    setListNftDeposit(checkedValues);
    setIndeterminate(
      !!checkedValues.length &&
        checkedValues.length < players?.map((item) => item.tokenId)?.length
    );
    setCheckAll(
      checkedValues.length === players?.map((item) => item.tokenId)?.length
    );
  };

  const handleCancelSelectNft = () => {
    // setListNftDeposit();
    setOpenModalNft(false);
    // setMinAmount(0);
  };

  const handleSelectAllNft = (e) => {
    setListNftDeposit(
      e.target.checked ? players?.map((item) => item.tokenId) : undefined
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    if (!e.target.checked) {
      setMinAmount(0);
    }
  };
  const handleResetNft = () => {
    setListNftDeposit();
    setMinAmount(0);
    setIndeterminate(false);
    setCheckAll(false);
  };

  const handleOnChangeInput = (inputValue) => {
    const reg = REGEX_INPUT_NUMBER;
    if (inputValue.match(reg)) {
      setInputValue(inputValue.trim());
    }
  };

  const handleOpenModalNFT = () => {
    setOpenModalNft(true);
    handleGetPlayer();
  };

  const handleMax = () => {
    setInputValue(balanceRUInWallet);
  };

  const refetchInfo = () => {
    setReFetchInfo((v) => v + 1);
  };

  const handleUnstake = async () => {
    setUnStaking(true);
    const web3 = new Web3(library.provider);
    const vaultContract = new web3.eth.Contract(
      NFT_VAULT_CONTRACT.ABI,
      NFT_VAULT_CONTRACT.ADDRESS
    );
    if (account && library && library.provider) {
      try {
        const result = await vaultContract.methods.requestWithdraw().send({
          from: account,
        });
        refetchInfo();
      } catch (error) {
        console.log(error);
      }
    }
    setUnStaking(false);
  };

  const toggle = () => {
    setClicked(!clicked);
  };

  const getBalanceRUInWallet = async () => {
    if (!account) {
      return;
    }
    const web3 = new Web3(RPC.BSC);
    const RUContract = new web3.eth.Contract(
      RU_CONTRACT.ABI,
      RU_CONTRACT.ADDRESS
    );
    try {
      const result = web3.utils.fromWei(
        await RUContract.methods.balanceOf(account).call()
      );
      if (result) {
        setBalanceRUInWallet(result);
      }
    } catch (error) {}
  };

  const getApproveRU = async () => {
    if (library && library.provider) {
      const web3 = new Web3(library.provider);
      const ruContract = new web3.eth.Contract(
        RU_CONTRACT.ABI,
        RU_CONTRACT.ADDRESS
      );
      const allowance = web3.utils.fromWei(
        await ruContract.methods
          .allowance(account, NFT_VAULT_CONTRACT.ADDRESS)
          .call()
      );
      setIsApproveRU(parseInt(allowance) > 9999999);
    }
  };

  const getApproveNFT = async () => {
    if (library && library.provider) {
      const web3 = new Web3(library.provider);
      const playerContract = new web3.eth.Contract(
        PLAYER_CONTRACT.ABI,
        PLAYER_CONTRACT.ADDRESS
      );
      const isApprove = await playerContract.methods
        .isApprovedForAll(account, NFT_VAULT_CONTRACT.ADDRESS)
        .call();
      setIsApproveNFT(!!isApprove);
    }
  };

  function getDepositBtn() {
    // const amountInput = !!inputValue ? parseInt(inputValue) : 0;
    if (isDepositNFT) {
      if (isApproveNFT && isApproveRU) {
        return (
          <Button
            loading={depositing}
            onClick={handleDeposit}
            disabled={
              Number(inputValue) > Number(balanceRUInWallet) ||
              (!inputValue && !listNftDeposit)
            }
          >
            Deposit
          </Button>
        );
      }

      if (isApproveNFT && !isApproveRU) {
        return (
          <>
            <Button onClick={handleApproveRU} loading={isLoadingRU}>
              Approve RU
            </Button>
            <Button disabled={true}>Deposit</Button>
          </>
        );
      }

      if (!isApproveNFT && isApproveRU) {
        return (
          <>
            <Button onClick={handleApproveNFT} loading={isLoadingNFT}>
              Approve NFT
            </Button>
            <Button disabled={true}>Deposit</Button>
          </>
        );
      }

      return (
        <>
          <Button onClick={handleApproveRU} loading={isLoadingRU}>
            Approve RU
          </Button>
          <Button onClick={handleApproveNFT} loading={isLoadingNFT}>
            Approve NFT
          </Button>
          <Button disabled={true} onClick={handleDeposit}>
            Deposit
          </Button>
        </>
      );
    }

    return (
      <>
        {!isApproveRU && (
          <Button onClick={handleApproveRU} loading={isLoadingRU}>
            Approve RU
          </Button>
        )}
        <Button
          loading={depositing}
          disabled={
            !isApproveRU ||
            Number(inputValue) > Number(balanceRUInWallet) ||
            Number(inputValue) === 0
          }
          onClick={handleDeposit}
        >
          Deposit
        </Button>
      </>
    );
  }

  function getWithdrawBtn() {
    if (requestWithdrawTime === 0) {
      return (
        <div className="box__btn">
          <Button
            onClick={handleUnstake}
            loading={unStaking}
            disabled={!account || Number(totalDeposit) === 0}
          >
            Unstake
          </Button>
        </div>
      );
    }

    const unboundingDate = new Date(
      (requestWithdrawTime + unboundingTime) * 1000
    ).getTime();
    if (unboundingDate > Date.now()) {
      return (
        <>
          {" "}
          <div className="desc-withdraw"> Withdrawal delay time </div>
          <Countdown time={unboundingDate} countdownComplete={refetchInfo} />
        </>
      );
    }

    return (
      <div className="box__btn">
        <Button
          disabled={!account}
          onClick={handleWithdrawAll}
          loading={withdrawing}
        >
          Withdraw
        </Button>
      </div>
    );
  }

  const handleDeposit = async () => {
    console.log("listNftDeposit === ", listNftDeposit);
    let nftSign;
    if (account && library && library.provider) {
      setDepositing(true);
      const web3 = new Web3(library.provider);
      const amountInput = !!inputValue ? inputValue : 0;
      const amount = web3.utils.toWei(`${amountInput}`);
      const vaultContract = new web3.eth.Contract(
        NFT_VAULT_CONTRACT.ABI,
        NFT_VAULT_CONTRACT.ADDRESS
      );
      try {
        if (listNftDeposit && listNftDeposit.length) {
          try {
            nftSign = await http.post("/api/v1/vault/nft2ru", {
              tokenIds: listNftDeposit,
              amount,
            });
          } catch (e) {
            setDepositing(false);
            toast.error(e.msg || e.toString());
            return;
          }
        }
        let tokenIds = [];
        let nft2Ru = [];
        let adminSignedData = web3.utils.hexToBytes("0x");
        let minRUAmount = 0;
        if (nftSign) {
          tokenIds = nftSign.tokenIds;
          nft2Ru = nftSign.nft2Ru;
          adminSignedData = nftSign.adminSignedData;
          minRUAmount = nftSign.minRUAmount;
        }
        const result = await vaultContract.methods
          .deposit(tokenIds, nft2Ru, minRUAmount, adminSignedData, amount)
          .send({
            from: account,
          });
        if (result) {
          toast.success("Deposit Success");
          setIsDepositNFT(false);
          setListNftDeposit();
          setInputValue("");
          refetchInfo();
        }
      } catch (error) {
        console.log(error);
      }
      setDepositing(false);
    }
  };

  const handleGetPlayer = async () => {
    if (account && library && library.provider) {
      setIsLoadingPlayer(true);
      let listPlayer = await getListPlayerOnWallet({ account });

      listPlayer = listPlayer.sort(
        (player1, player2) =>
          parseInt(player1.tokenId) - parseInt(player2.tokenId)
      );
      setPlayers(listPlayer);
    }
    setIsLoadingPlayer(false);
  };

  async function initAccount() {
    const web3 = new Web3(RPC.BSC);
    const vaultContract = new web3.eth.Contract(
      NFT_VAULT_CONTRACT.ABI,
      NFT_VAULT_CONTRACT.ADDRESS
    );
    const unboundingTime = await vaultContract.methods.unboundingTime().call();
    setUnboundingTime(parseInt(unboundingTime));

    if (account && library && library.provider) {
      const web3 = new Web3(library.provider);
      const vaultContract = new web3.eth.Contract(
        NFT_VAULT_CONTRACT.ABI,
        NFT_VAULT_CONTRACT.ADDRESS
      );
      const totalDeposit = await vaultContract.methods
        .getBalanceRU(account)
        .call();
      const userInfo = await vaultContract.methods.userInfo(account).call();
      setTotalDeposit(new BigNumber(totalDeposit).dividedBy(1e18));
      setRequestWithdrawTime(parseInt(userInfo.requestWithdrawTime));
    }
  }

  async function getTVL() {
    const web3 = new Web3(RPC.BSC);
    const vaultContract = new web3.eth.Contract(
      NFT_VAULT_CONTRACT.ABI,
      NFT_VAULT_CONTRACT.ADDRESS
    );
    const totalDeposit = await vaultContract.methods.totalDeposit().call();
    setRuTVL(web3.utils.fromWei(totalDeposit));
  }

  useEffect(() => {
    clearInput();
    setIsDepositNFT(false);
    setListNftDeposit();
  }, [depositTabActive]);

  useEffect(() => {
    getBalanceRUInWallet();
    getApproveRU();
    getApproveNFT();
    initAccount();
    handleResetNft();
    handleCancelSelectNft();
    getTVL();
  }, [account, chainId, reFetchInfo]);

  useEffect(() => {
    const inverval = setInterval(getTVL, 10 * 1000);
    return () => {
      clearInterval(inverval);
    };
  }, []);

  const renderWithModal = () => {
    if (width > 1200) return "70%";
    if (width < 576) return "100%";
    if (width < 768) return "95%";
    if (width < 992) return "90%";
    if (width < 1200) return "85%";
  };

  const Description = (
    <div className="content">
      <div className="tvl">
        <ul>
          <li>
            <div className="title">TVL</div>
            {/* <span>
              {thounsandSeparators(busdTVL) || "--"}{" "}
              <img src={BUSDIcon} alt="RU" className="coin-icon" />
            </span> */}
            <span className="countup">
              <CountUp
                start={previousTVL === ruTVL ? 0 : previousTVL}
                end={ruTVL}
                duration={1.5}
                separator=","
                decimals={2}
                decimal="."
              />
              {/* {thounsandSeparators(ruTVL) || "--"}{" "} */}
              <img src={RuIcon} alt="RU" className="coin-icon" />
            </span>
          </li>
        </ul>
      </div>
      <ul>
        <li>
          {" "}
          <span className="bold"> RU-NFT Farming Vault </span> provides you with
          a chance to make your own passive profit. You can stake{" "}
          <span className="bold"> RU </span> or both{" "}
          <span className="bold"> RU & NFT </span> players in RU-NFT Farming
          Vault.
        </li>
        <li>
          {" "}
          For dual RU & NFT staking, the amount of RU staked must be{" "}
          <span className="bold"> equal </span> to or{" "}
          <span className="bold"> greater </span> than the value of NFT when
          converted to RU.{" "}
        </li>
        <li>
          {" "}
          The average price for Rising Stars, Accomplished, World-classed, and
          Legendary are{" "}
          <span className="bold"> 3000 RU, 5000 RU, 25000 RU, </span> and{" "}
          <span className="bold"> 60000 RU </span> respectively.{" "}
        </li>
        <li>
          {" "}
          <span className="bold"> NO LOCK </span> at all. Unstake whenever you
          want, with a <span className="bold"> 7-day </span> withdrawal delay
          period.{" "}
        </li>
        <li>
          {" "}
          <span className="bold"> APY </span> is{" "}
          <span className="bold"> 30% </span> as default.{" "}
        </li>
      </ul>
    </div>
  );

  if (!isCountdownComplete) {
    return (
      <TheCountdown
        style={{ minHeight: "100vh" }}
        time={time}
        countdownComplete={countdownComplete}
      />
    );
  }

  return (
    <div className="token-vault nft-vault">
      <div className="vault-wrapper">
        <Banner typeTab={typeTab} setTypeTab={setTypeTab} />
      </div>
      {typeTab === VAULT_NFT && (
        <Container>
          <section className="token-vault__info">
            <div className="info">
              <div className="info__description">
                {width <= toggleStart ? (
                  <>
                    <div className="iheader" onClick={toggle}>
                      {/* <div className="title">DESCRIPTION</div> */}
                      <IconContext.Provider
                        value={{ color: "#5d666f", size: "1.5rem" }}
                      >
                        <div>{clicked ? <FiMinus /> : <FiPlus />}</div>
                      </IconContext.Provider>
                    </div>
                    {clicked ? Description : null}
                  </>
                ) : (
                  <>
                    {/* <div className="title">DESCRIPTION</div> */}
                    {Description}
                  </>
                )}
              </div>
            </div>
            <div></div>
          </section>
          <section className="token-vault__main">
            <div className="boxes">
              <Reward reFetchInfo={reFetchInfo} />
              <div className="box box-right">
                <ul className="box__info">
                  <li>
                    <p className="label">Wallet Balance</p>
                    <p className="value">
                      {thounsandSeparators(balanceRUInWallet)} RU
                    </p>
                  </li>
                </ul>
                <div className="box__tabs">
                  <ul>
                    <li
                      className={`${depositTabActive ? "tab active" : "tab"}`}
                      onClick={() => setDepositTabActive(true)}
                    >
                      Deposit
                    </li>
                    <li
                      className={`${!depositTabActive ? "tab active" : "tab"}`}
                      onClick={() => setDepositTabActive(false)}
                    >
                      Withdraw
                    </li>
                  </ul>
                  {depositTabActive ? (
                    <>
                      <div className="box__input">
                        <input
                          placeholder="0"
                          maxLength="100"
                          onChange={(e) => handleOnChangeInput(e.target.value)}
                          value={inputValue}
                        />
                        <button className="btn" onClick={handleMax}>
                          Max
                        </button>
                      </div>
                      {Number(inputValue) > Number(balanceRUInWallet) ? (
                        <div className="txt-error">
                          Balance less than or equal wallet balance
                        </div>
                      ) : null}
                      <div className="deposit-withdraw-nft">
                        <div>
                          <Checkbox
                            onChange={handleCheckNft}
                            checked={isDepositNFT}
                          >
                            Deposit NFT Player{" "}
                            {isDepositNFT &&
                              listNftDeposit &&
                              listNftDeposit.length > 0 && (
                                <span className="info-deposit">
                                  (
                                  {`${listNftDeposit.length} NFT - ${minAmount} RU`}
                                  )
                                </span>
                              )}
                          </Checkbox>
                        </div>

                        {isDepositNFT ? (
                          <div
                            className="view-all"
                            onClick={handleOpenModalNFT}
                          >
                            View NFT
                          </div>
                        ) : null}
                      </div>
                      <div className="box__btn">{getDepositBtn()}</div>
                    </>
                  ) : (
                    <>
                      <div className="withdraw">
                        <div className="total-withdraw">
                          <span>
                            Total amount: {totalDeposit.toFixed(2)} RU
                          </span>
                        </div>
                      </div>
                      {getWithdrawBtn()}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
          <Modal
            visible={openModalNft}
            onCancel={handleCancelSelectNft}
            title={
              <div>
                <div className="title-modal">SELECT NFT</div>
              </div>
            }
            className="app-modal modal-nft-vault"
            width={renderWithModal()}
            centered
            footer={null}
            closable={true}
          >
            <Row>
              <Col sm={12} md={4} lg={3} xl={3}>
                {width < 768 ? (
                  <div>
                    <Collapse
                      accordion
                      onChange={(key) => {
                        setIsCollapse(key);
                      }}
                      expandIconPosition="right"
                    >
                      <Panel
                        header={
                          <div className="title-info__item">
                            Total NFT:{" "}
                            <span className="value">{players?.length}</span>
                          </div>
                        }
                        key="title-nft"
                      >
                        <div className="title-info">
                          <div className="title-info__item">
                            Selected NFT:{" "}
                            <span className="value">{`${
                              listNftDeposit?.length ?? 0
                            } (NFT)`}</span>
                          </div>
                          <div className="title-info__item">
                            NFT Value:{" "}
                            <span className="value">{`${thounsandSeparators(
                              minAmount
                            )} (RU)`}</span>
                          </div>
                        </div>
                        <div className="action-modal">
                          <Checkbox
                            indeterminate={indeterminate}
                            checked={checkAll}
                            onChange={handleSelectAllNft}
                          >
                            Select all
                          </Checkbox>
                          <Button
                            onClick={handleResetNft}
                            className="btn-reset"
                          >
                            Reset
                          </Button>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                ) : (
                  <>
                    <div className="title-info">
                      <div className="title-info__item">
                        Total NFT:{" "}
                        <span className="value">{players?.length}</span>
                      </div>
                      <div className="title-info__item">
                        Selected NFT:{" "}
                        <span className="value">{`${
                          listNftDeposit?.length ?? 0
                        } (NFT)`}</span>
                      </div>
                      <div className="title-info__item">
                        NFT Value:{" "}
                        <span className="value">{`${thounsandSeparators(
                          minAmount
                        )} (RU)`}</span>
                      </div>
                    </div>
                    <div className="action-modal">
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checkAll}
                        onChange={handleSelectAllNft}
                      >
                        Select all
                      </Checkbox>
                      <Button onClick={handleResetNft} className="btn-reset">
                        Reset
                      </Button>
                    </div>
                  </>
                )}
              </Col>
              <Col sm={12} md={8} lg={9} xl={9}>
                <NFTList
                  className={`${!isEmpty(isCollapse) ? "info-collapse" : ""}`}
                  setMinAmount={setMinAmount}
                  handleSelectNft={handleSelectNftDeposit}
                  listNftDeposit={listNftDeposit}
                  // listNftInVault={listNftInVault}
                  // isDepositNFT={isDepositNFT}
                  // isWithdrawNFT={isWithdrawNFT}
                  isLoadingPlayer={isLoadingPlayer}
                  players={players}
                />
              </Col>
            </Row>
          </Modal>
        </Container>
      )}
      {typeTab === TOP100 && <Top100 typeTab={typeTab} />}
    </div>
  );
};

export default NFTVault;
