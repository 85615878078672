import React, { useEffect, useState } from "react";
import Title from "assets/images/wallet/banner-title.png";
import { BINANCE_LEGENDARY, PLAYERS, BOX } from "utils/constants";
import { BNFT_CONTRACT, SWAP_NFT_CONTRACT } from "consts";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import http from "utils/http";

console.log('SWAP_NFT_CONTRACT = ', SWAP_NFT_CONTRACT.ADDRESS);

const LIST_NFT_TOKENID = [
  100300285094, 100300285100, 100300285111, 100300285127, 100300266650,
  100300266648, 100300266641, 100300266642, 100300266639, 100300266638,
  100300266636, 100300266633, 100300266627, 100300266620, 100300266617,
  100300266628, 100300266621, 100300266629, 100300266631, 100300266634,
];

const Banner = ({
  tab,
  setTab,
  handleSetBnfts,
  // runGetNFTOwner,
  fetchNewData,
}) => {
  const [bnfts, setBnfts] = useState([]);
  const { account, library, chainId } = useWeb3React();

  useEffect(() => {
    async function getNFTOwner() {
      if (account && library && library.provider) {
        const web3 = new Web3(library.provider);
        const binanceNFTContract = new web3.eth.Contract(
          BNFT_CONTRACT.ABI,
          BNFT_CONTRACT.ADDRESS
        );

        const balance = await binanceNFTContract.methods
          .balanceOf(account)
          .call();
        console.log("balance === ", balance);

        if (parseInt(balance) === 0) {
          setBnfts([]);
          handleSetBnfts([]);
          return;
        }

        const owners = await Promise.all(
          LIST_NFT_TOKENID.map(async (tokenId) => {
            const owner = await binanceNFTContract.methods
              .ownerOf(tokenId)
              .call();
            return {
              tokenId,
              owner,
            };
          })
        );
        console.log("list nft == ", owners);
        const nfts = [];
        for (let i = 0; i < owners.length; i++) {
          const { tokenId, owner } = owners[i];
          // if (owner === "0xe0A9e5B59701a776575fDd6257c3F89Ae362629a") {
          if (owner === account) {
            // let player = await binanceNFTContract.methods.tokenURI(tokenId).call();
            // player = await axios.get(player);
            const player = await http.get(`/api/v1/box/bnft/${tokenId}`);
            nfts.push({ ...player, tokenId, owner, legendary: true });
          }
        }
        console.log("owner nfts == ", nfts);
        setBnfts(nfts);
        handleSetBnfts(nfts);
      }
    }
    getNFTOwner();
  }, [account, chainId, fetchNewData]);

  return (
    <div className="banner">
      <div className="title">
        <img src={Title} alt="Wallet" />
        <div className="tab-wallet">
          <div
            onClick={() => {
              setTab(PLAYERS);
            }}
            className={`tab-item ${tab === PLAYERS ? "active" : ""}`}
          >
            Players
          </div>
          {bnfts && bnfts.length > 0 ? (
            <div
              onClick={() => {
                setTab(BINANCE_LEGENDARY);
              }}
              className={`tab-item ${
                tab === BINANCE_LEGENDARY ? "active" : ""
              }`}
            >
              Binance Legendary
            </div>
          ) : null}
          <div
            onClick={() => {
              setTab(BOX);
            }}
            className={`tab-item ${tab === BOX ? "active" : ""}`}
          >
            BOX
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
