import React, { useState, useEffect } from "react";
import RUIcon from "../../assets/images/vault/ru.png";
import { Button } from "antd";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { VAULT_CONTRACT, LPTOKEN_CONTRACT } from "../../consts/vault_abi";
import { thounsandSeparators, CustomTooltip } from "../../utils/helper";
import { IconContext } from "react-icons";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { BSC_TESTNET, NETWORKS } from "../../utils/constants";

const depositMessagesError = {
  startTime: "Can't deposit after start time",
  approve: "Please approve first",
  input: "Please enter the correct number you want to deposit",
  wallet: "Insufficient balance",
};

const withdrawMessagesError = {
  balance: "Invalid balance",
  lockTime: "Can't withdraw before vault unlock",
};

const Boxes = ({ startTime, endTime, endLockTime, vaultAddress }) => {
  const { account, library, chainId } = useWeb3React();
  const [depositTabActive, setDepositTabActive] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [balanceInWallet, setBalanceInWallet] = useState(0);
  const [balanceInVault, setBalanceInVault] = useState(0);
  const [interest, setInterest] = useState(0);
  const [rate, setRate] = useState(0);
  const [appoving, setApproving] = useState(false);
  const [depositing, setDepositing] = useState(false);
  const [withdrawing, setWithdrawing] = useState(false);
  const [isApprove, setIsApprove] = useState(true);

  let swapLink =
    "https://pancakeswap.finance/add/0x6dc923900b3000bd074d1fea072839d51c76e70e/0xe9e7cea3dedca5984780bafc599bd69add087d56";
  if (chainId === NETWORKS[BSC_TESTNET]) {
    swapLink =
      "https://pcs.nhancv.com/#/add/0x88DFbF8ca8F6937818DA9C3A09bE152E3b221E9a/0x9A1848825F158F702ae5c7C9743F685dEcb70E5E";
  }

  const clearInput = () => {
    setInputValue("");
  };

  const getBlanceInVault = async (account, library) => {
    if (account && library && library.provider) {
      const web3 = new Web3(library.provider);
      const vaultContract = new web3.eth.Contract(
        VAULT_CONTRACT.ABI,
        vaultAddress
      );
      try {
        const result = await vaultContract.methods.getBalance(account).call();
        if (result) {
          setBalanceInVault(web3.utils.fromWei(result));
        }
      } catch (error) {}
    }
  };

  const getBlanceInWallet = async (account, library) => {
    if (account && library && library.provider) {
      const web3 = new Web3(library.provider);
      const LPTokenContract = new web3.eth.Contract(
        LPTOKEN_CONTRACT.ABI,
        LPTOKEN_CONTRACT.ADDRESS
      );
      try {
        // const decimal = await LPTokenContract.methods.decimals().call();
        const result = web3.utils.fromWei(
          await LPTokenContract.methods.balanceOf(account).call()
        );
        if (result) {
          setBalanceInWallet(result);
        }
      } catch (error) {}
    }
  };

  const getCurrentInterest = async (account, library) => {
    if (account && library && library.provider) {
      const web3 = new Web3(library.provider);
      const vaultContract = new web3.eth.Contract(
        VAULT_CONTRACT.ABI,
        vaultAddress
      );
      try {
        const result = await vaultContract.methods
          .getCurrentInterest(account)
          .call();
        if (result) {
          let { interest, rate } = result;
          setInterest(web3.utils.fromWei(interest));
          setRate(web3.utils.fromWei(rate, "gwei") * 10);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getBlanceInVault(account, library);
    getBlanceInWallet(account, library);
    getCurrentInterest(account, library);
  }, [account, library, depositing, withdrawing]);

  useEffect(() => {
    clearInput();
  }, [depositTabActive]);

  useEffect(() => {
    if (!depositing) {
      clearInput();
    }
  }, [depositing]);

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const LPTokenContract = new web3.eth.Contract(
          LPTOKEN_CONTRACT.ABI,
          LPTOKEN_CONTRACT.ADDRESS
        );
        const approveDeposit = await LPTokenContract.methods
          .allowance(account, vaultAddress)
          .call();
        setIsApprove(web3.utils.fromWei(approveDeposit) > 0); // ???
      }
    }
    getAllowance();
  }, [account, library, vaultAddress]);

  const handleApprove = async () => {
    if (account && library && library.provider) {
      setApproving(true);
      const web3 = new Web3(library.provider);
      const LPTokenContract = new web3.eth.Contract(
        LPTOKEN_CONTRACT.ABI,
        LPTOKEN_CONTRACT.ADDRESS
      );
      try {
        const result = await LPTokenContract.methods
          .approve(vaultAddress, web3.utils.toWei("9999999999999"))
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
        }
      } catch (error) {
      } finally {
        setApproving(false);
      }
    }
  };

  const handleDeposit = async () => {
    if (account && library && library.provider) {
      setDepositing(true);
      const web3 = new Web3(library.provider);
      const vaultContract = new web3.eth.Contract(
        VAULT_CONTRACT.ABI,
        vaultAddress
      );
      try {
        await vaultContract.methods.deposit(web3.utils.toWei(inputValue)).send({
          from: account,
        });
      } catch (error) {
      } finally {
        setDepositing(false);
      }
    }
  };

  const handleWithdraw = async () => {
    if (account && library && library.provider) {
      setWithdrawing(true);
      const web3 = new Web3(library.provider);
      const vaultContract = new web3.eth.Contract(
        VAULT_CONTRACT.ABI,
        vaultAddress
      );
      try {
        await vaultContract.methods.withdraw().send({
          from: account,
        });
      } catch (error) {
      } finally {
        setWithdrawing(false);
      }
    }
  };

  const handleOnChangeInput = (inputValue) => {
    const reg = "^[0-9.]*$";
    if (inputValue.match(reg)) {
      setInputValue(inputValue.trim());
    }
  };

  const handleMax = () => {
    setInputValue(balanceInWallet);
  };

  const conditionDisableDeposit = (value) => {
    if (Number(value) > balanceInWallet) {
      return [true, depositMessagesError.wallet];
    } else if (!isApprove) {
      return [true, depositMessagesError.approve];
    } else if (Date.now() >= startTime) {
      // Can not deposit after startTime
      return [true, depositMessagesError.startTime];
    } else if (!Number(value) || Number(value) <= 0) {
      return [true, depositMessagesError.input];
    } else {
      return [false, ""];
    }
  };

  const conditionDisableWithdraw = () => {
    if (Date.now() < endLockTime) {
      // Can't withdraw before unlock time
      return [true, withdrawMessagesError.lockTime];
    } else if (balanceInVault <= 0) {
      return [true, withdrawMessagesError.balance];
    } else {
      return [false, ""];
    }
  };

  return (
    <div className="boxes">
      <div className="box box-left">
        <ul className="box__info">
          <li>
            <p className="label">Deposit</p>
            <p className="value">
              {thounsandSeparators(balanceInVault)} RU/BUSD LPs
            </p>
          </li>
          <li>
            <p className="label">APY</p>
            <p className="value">up to 5000% (based on the amount of $RU)</p>
          </li>
        </ul>
        <h2 className="box__title">Reward</h2>
        <ul className="box__info">
          <li>
            <p className="label">RU</p>
            <div className="coin">
              <img src={RUIcon} alt="RU" />
              <p>{thounsandSeparators(interest)}</p>
            </div>
            <p className="value">
              Rate: {thounsandSeparators(rate * 100 * 2)}%
            </p>
          </li>
        </ul>
      </div>
      <div className="box box-right">
        <ul className="box__info">
          <li>
            <p className="label">Wallet Balance</p>
            <p className="value">
              {thounsandSeparators(balanceInWallet)} RU/BUSD LPs
            </p>
          </li>
          <li>
            <p className="label">
              <a target="_blank" rel="noreferrer" href={swapLink}>
                <span>Add liquidity on Pancake Swap</span>
                <IconContext.Provider
                  value={{
                    color: "#fff",
                    size: "1rem",
                  }}
                >
                  <BsFillArrowUpRightCircleFill />
                </IconContext.Provider>
              </a>
            </p>
          </li>
        </ul>
        <div className="box__tabs">
          <ul>
            <li
              className={`${depositTabActive ? "tab active" : "tab"}`}
              onClick={() => setDepositTabActive(true)}
            >
              Deposit
            </li>
            <li
              className={`${!depositTabActive ? "tab active" : "tab"}`}
              onClick={() => setDepositTabActive(false)}
            >
              Withdraw
            </li>
          </ul>
          {depositTabActive ? (
            <>
              <div className="box__input">
                <input
                  placeholder="0"
                  maxLength="100"
                  onChange={(e) => handleOnChangeInput(e.target.value)}
                  value={inputValue}
                />
                <button className="btn" onClick={handleMax}>
                  Max
                </button>
              </div>
              <div className="box__btn">
                <Button
                  disabled={isApprove ? true : false}
                  onClick={handleApprove}
                  loading={appoving}
                >
                  Approve
                </Button>
                <CustomTooltip
                  isDisplay={conditionDisableDeposit(inputValue)[0]}
                  text={conditionDisableDeposit(inputValue)[1]}
                  render={
                    <div className="btn-wrapper">
                      <Button
                        loading={depositing}
                        disabled={conditionDisableDeposit(inputValue)[0]}
                        onClick={handleDeposit}
                      >
                        Deposit
                      </Button>
                    </div>
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="total-withdraw">
                <span>Total amount:</span>
                {thounsandSeparators(Number(balanceInVault))} RU/BUSD LPs +{" "}
                {thounsandSeparators(Number(interest))} RU
              </div>
              <div className="box__btn">
                <CustomTooltip
                  isDisplay={conditionDisableWithdraw()[0]}
                  text={conditionDisableWithdraw()[1]}
                  render={
                    <div className="btn-wrapper">
                      <Button
                        disabled={conditionDisableWithdraw()[0]}
                        onClick={handleWithdraw}
                        loading={withdrawing}
                      >
                        Exit: Claim & Withdraw
                      </Button>
                    </div>
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Boxes;
