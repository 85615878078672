import React, { useEffect, useState, useCallback, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Banner from "./Banner";
import Pagination from "components/Common/Pagination";
import ic_filter from "../../assets/icons/filter.svg";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { PLAYER_CONTRACT, RPC } from "../../consts";
import Loading from "components/Common/Loading";
import PlayerCard from "components/Common/PlayerCard";
import { Select } from "antd";
import { getDetailPlayer, getListMarket } from "../../utils/client_api";
import Filter from "components/Common/Filter";
import PlayerCardSkelaton from "components/Common/PlayerCardSkelaton";
import { debounce } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const { Option } = Select;

const defaultFilter = {
  currentPage: 1,
  overallRange: [20, 120],
  ageRange: [15, 45],
  position: [],
  species: [],
  heightRange: [160, 220],
  weightRange: [60, 100],
  talent: undefined,
  league: undefined,
  club: undefined,
};

const PlayerList = () => {
  let history = useHistory();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const { account } = useWeb3React();
  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [marketData, setMarketData] = useState([]);
  const [pages, setPages] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("startedAt");
  const [filter, setFilter] = useState({ ...defaultFilter, ...parsed });
  const itemPerPage = 9;

  const PushQuery = (modifiedQuery, isSearch = true) => {
    if (isSearch === false) {
      history.push({
        pathname: location.pathname,
        search: null,
      });
    } else {
      const parsed = queryString.parse(location.search);
      let search = { ...parsed, ...modifiedQuery };
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(search),
      });
    }
  };

  // useEffect(() => {
  //   const UseQuery = () => {
  //     const parsed = queryString.parse(location.search);
  //     return parsed;
  //   };

  //   const queryURL = UseQuery();
  //   setFilter({ ...defaultFilter, ...queryURL });
  // }, []);

  useEffect(() => {
    const marketData = async () => {
      setIsLoading(true);
      const result = await getListMarket({
        sortBy,
        orderBy,
        page: filter.currentPage,
        limit: itemPerPage,
        ...filter,
      });

      if (result) {
        setMarketData(result.markets);
        setPages(Math.ceil(result.total / itemPerPage));
      } else {
        setTimeout(marketData, 1000 * 5);
      }
      setTimeout(() => setIsLoading(false), 1000);
    };

    marketData();
  }, [filter, orderBy, sortBy, account]);

  useEffect(() => {
    async function init() {
      const promises =
        marketData &&
        marketData.length > 0 &&
        marketData.map(async (item, index) => {
          let tokenId = item.tokenId;
          let startingPrice = item.startingPrice;
          // const ipfs = await playerContract.methods.tokenURI(tokenId).call();
          try {
            const fullData = await getDetailPlayer(tokenId);
            return {
              ...fullData,
              tokenId,
              startingPrice,
              data: {},
            };
          } catch (error) {
            return {};
          }
        });

      const listPlayers = promises && (await Promise.all(promises));
      setPlayers(listPlayers || []);
    }

    init();
  }, [marketData]);

  const handleSort = (value) => {
    let [sortBy, orderBy] = value.split("-");
    setSortBy(sortBy);
    setOrderBy(orderBy);
  };

  const handleDebounceSearch = debounce((func) => {
    func();
  }, 100);

  const handleChangeCurrentPage = useCallback(async (page) => {
    setFilter((state) => ({
      ...state,
      currentPage: page,
    }));
    PushQuery({ currentPage: page });
  }, []);

  const handleChangeOverall = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        overallRange: range,
        currentPage: 1,
      }));
      PushQuery({ overallRange: range });
    });
  };

  const handleChangeAge = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        ageRange: range,
        currentPage: 1,
      }));
      PushQuery({ ageRange: range });
    });
  };

  const handleChangePosition = (position) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        position: position,
        currentPage: 1,
      }));
      PushQuery({ position: position });
    });
  };

  const handleChangeHeight = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        heightRange: range,
        currentPage: 1,
      }));
    });
    PushQuery({ heightRange: range });
  };

  const handleChangeWeight = (range) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        weightRange: range,
        currentPage: 1,
      }));
    });
    PushQuery({ weightRange: range });
  };

  const handleChangeSpecies = (species) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        species: species,
        currentPage: 1,
      }));
    });
    PushQuery({ species: species });
  };

  const handleChangeTalent = (talent) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        talent: talent,
        currentPage: 1,
      }));
    });
    PushQuery({ talent: talent });
  };

  const handleChangeLeague = (league) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        league: league,
        currentPage: 1,
      }));
    });
    PushQuery({ league: league });
  };

  const handleChangeClub = (club) => {
    handleDebounceSearch(() => {
      setFilter((state) => ({
        ...state,
        club: club,
        currentPage: 1,
      }));
    });
    PushQuery({ club: club });
  };

  const hanldeClearFilter = async () => {
    setFilter({ ...defaultFilter });
    PushQuery({}, false);
  };

  function LoadingComponent() {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ height: "40vh", alignItems: "center" }}
      >
        <Loading />
      </div>
    );
  }

  // return <div className="maintenance">Temporarily closed for maintenance</div>;

  return (
    <div className="wallet-wrapper">
      {/* <Banner /> */}
      <Container className="wallet-list">
        <Row>
          <Col xs={12} lg={3}>
            <Filter
              handleChangePosition={handleChangePosition}
              handleChangeAge={handleChangeAge}
              handleChangeOverall={handleChangeOverall}
              handleChangeHeight={handleChangeHeight}
              handleChangeWeight={handleChangeWeight}
              handleChangeSpecies={handleChangeSpecies}
              hanldeClearFilter={hanldeClearFilter}
              handleChangeTalent={handleChangeTalent}
              handleChangeLeague={handleChangeLeague}
              handleChangeClub={handleChangeClub}
              filter={filter}
            />
          </Col>
          <Col xs={12} lg={9}>
            <Row>
              <div className="wallet-sort">
                <div className="select-group">
                  <div className="select-pretext">
                    <img src={ic_filter} alt="sort" />
                    Sort by:
                  </div>
                  <Select defaultValue="startedAt-desc" onChange={handleSort}>
                    <Option value="startedAt-desc">Latest</Option>
                    <Option value="recommended-asc">Recommended</Option>
                    <Option value="startingPrice-asc">
                      Lowest Starting Price
                    </Option>
                    <Option value="endingPrice-asc">Lowest Ending Price</Option>
                    <Option value="startingPrice-desc">
                      Highest Starting Price
                    </Option>
                    <Option value="endingPrice-desc">
                      Highest Ending Price
                    </Option>
                  </Select>
                </div>
              </div>
            </Row>
            {isLoading && <LoadingComponent />}
            {!isLoading && players && players.length > 0 && (
              <>
                <Row>
                  {players.map((item, index) => {
                    if (!item.name) {
                      return null;
                    } else {
                      return (
                        <Col key={index} sm={6} xl={4} className="wallet-item">
                          <PlayerCard item={item} cardType="marketplace" />
                        </Col>
                      );
                    }
                  })}
                </Row>
                <Pagination
                  currentPage={Number(filter.currentPage)}
                  pages={Number(pages)}
                  onPageChange={handleChangeCurrentPage}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PlayerList;
