import React, { useState, useEffect, Fragment } from "react";
import { Modal, Form, Input, Button } from "antd";
import {
  KEY_INVALID_INPUT,
  MAX_BOX_OPEN,
  PLAYERS,
  REGEX_INPUT_NUMBER,
  REGEX_QUANTITY,
} from "utils/constants";
import { useWeb3React } from "@web3-react/core";
import { convertExponential, thounsandSeparators } from "utils/helper";
import Web3 from "web3";
import Loading from "components/Common/Loading";
import {
  API_URL,
  PLAYER_BOX_CONTRACT,
  PLAYER_CONTRACT,
  RU_BOX_MARKETPLACE,
  BOX_TYPE_NUMBER,
} from "consts";
import useGetRUPrice from "hooks/useGetRUPrice";
import { debounce } from "lodash";
import { getDetailPlayer, getOpenBoxAdminSignedData } from "utils/client_api";
import Player from "components/Common/Player";
import Slider from "react-slick";
import stars3 from "../../assets/images/marketplace/3-stars.png";
import stars5 from "../../assets/images/marketplace/5-stars.png";
import confetti from "../../assets/images/marketplace/confetti.svg";
import PlayerCard from "components/Common/PlayerCard";
import { useHistory } from "react-router-dom";

const settings = {
  className: "result-slider",
  centerMode: true,
  infinite: false,
  centerPadding: "60px",
  slidesToShow: 1,
  speed: 500,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        centerPadding: "0px",
        variableWidth: false,
        centerMode: true,
      },
    },
  ],
};

const settings_gold = {
  slidesToShow: 2,
};

const ModalOpenBox = ({
  visible,
  type,
  handleCloseModal,
  handleFetchNewData,
  totalBox,
  handleChangeTab,
}) => {
  const { account, library } = useWeb3React();
  const [isApprove, setIsApprove] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(1);
  const [modalBody, setModalBody] = useState(null);
  const [isGold, setIsGold] = useState(false);
  const [isResultModal, setIsResultModal] = useState(false);
  const [modalMaskClosable, setModalMaskClosable] = useState(true);
  const [modalCloseable, setModalCloseable] = useState(true);

  const [form] = Form.useForm();
  const history = useHistory();

  const handleCancel = () => {
    setAmount(1);
    form.resetFields();
    setIsResultModal(false);
    setIsGold(false);
    handleCloseModal();
  };

  const getPlayerInfo = async (result) => {
    const web3 = new Web3(library.provider);
    let goldIndex = -1;
    let legendIndex = -1;
    const items = [];
    if (result.events) {
      const ids = [];
      const eventKeys = Object.keys(result.events);
      for (let i = 0; i < eventKeys.length; i++) {
        const event = result.events[i];
        if (
          event?.address?.toLowerCase() ===
          PLAYER_CONTRACT.ADDRESS.toLowerCase()
        ) {
          const id = web3.eth.abi.decodeParameter(
            "uint256",
            event?.raw?.topics[3]
          );
          ids.push(id);
        }
      }
      let maxOvr = 78;
      for (let i = 0; i < ids.length; i++) {
        const player = await getDetailPlayer(ids[i]);
        if (!player) {
          setTimeout(getPlayerInfo.bind(null, result), 10 * 1000);
          return;
        }
        if (player?.legendary) {
          legendIndex = i;
        }

        if (player?.playerAttribute?.ovr > maxOvr) {
          goldIndex = i;
          maxOvr = player.playerAttribute.ovr;
        }
        items.push({
          ...player,
          playerId: ids[i],
          tokenId: ids[i],
        });
      }
    }
    if (legendIndex !== -1 || goldIndex !== -1) {
      let itemIndex = legendIndex !== -1 ? legendIndex : goldIndex;
      const goldId = items[itemIndex].playerId;
      setIsGold(true);
      setModalBody(
        <div className="open-box-result-body">
          <h3 className="box-title">Congratulation!</h3>
          <div className="box-left">
            <div className="player-stats">
              <div className="player-stats__top">
                <img src={legendIndex !== -1 ? stars5 : stars3} alt="stars" />
                <div className="player-name">{items[itemIndex].name}</div>
                <div className="player-id">
                  <span>ID: </span>
                  {items[itemIndex].playerId}
                </div>
              </div>
              <div className="player-stats__middle">
                <div className="player-overall">
                  OVERALL
                  <br />
                  <span>{items[itemIndex].playerAttribute.ovr}</span>
                </div>
              </div>
              <div className="player-stats__bottom">
                <div className="info-block">
                  <div className="info-name">AGE</div>
                  <div className="info-value">
                    {items[itemIndex].playerAttribute.age}
                  </div>
                </div>
                <div className="block-line"></div>
                <div className="info-block">
                  <div className="info-name">POSITION</div>
                  <div className="info-value">
                    {items[itemIndex].playerAttribute.pfdPos1.pos}
                  </div>
                </div>
              </div>
            </div>
            <div className="player-img">
              {legendIndex !== -1 ? (
                <img
                  className="player-legendary"
                  src={`${API_URL}${items[itemIndex]?.playerAttribute?.bodyImage}`}
                  alt=""
                />
              ) : (
                <Player
                  {...items[itemIndex].playerAttribute?.bodyDetails}
                  pos={items[itemIndex].playerAttribute?.pfdPos1?.pos}
                />
              )}
              <img
                src={confetti}
                alt="Confetti"
                className="player-img__confetti"
              />
            </div>
          </div>
          <div className="box-right">
            <div className="box-right__top">
              <h3>Congratulation!</h3>
            </div>
            <div className="box-right__middle">
              <Slider {...settings_gold}>
                {items
                  .filter((i) => i.playerId !== goldId)
                  .map((p) => (
                    <div className="player-card">
                      <div className="player-avatar">
                        <Player
                          {...p.playerAttribute?.bodyDetails}
                          pos={p.playerAttribute?.pfdPos1?.pos}
                        />
                      </div>
                      <div className="player-name">{p.name}</div>
                      <div className="player-id">
                        <span>ID: {p.playerId}</span>
                      </div>
                      <div className="player-info">
                        Overall: <span>{p.playerAttribute.ovr}</span>
                      </div>
                      <div className="player-info">
                        Position: <span>{p.playerAttribute.pfdPos1.pos}</span>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="box-right__bottom">
              <button
                onClick={() => {
                  handleChangeTab(PLAYERS);
                }}
              >
                GO TO WALLET
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      setModalBody(
        <div className="open-box-result-body">
          <div className="title">Congratulation</div>
          <Slider {...settings}>
            {items.map((item) => {
              return (
                <div className="slider-item">
                  <div className="item-wrapper">
                    <PlayerCard item={item} />
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="actions">
            <Button
              onClick={() => {
                // history.push("/wallet?tab=PLAYERS");
                handleChangeTab(PLAYERS);
              }}
              type="primary"
              className="app-btn app-btn__primary"
            >
              Go to wallet
            </Button>
          </div>
        </div>
      );
    }
    setModalCloseable(true);
    setIsResultModal(true);
  };

  function setErrorModal(msg) {
    setModalCloseable(true);
    setModalMaskClosable(true);
    setModalBody(
      <Fragment>
        <div className="error"> {msg} </div>
      </Fragment>
    );
    return;
  }

  const handleSubmit = async (values) => {
    setModalCloseable(false);
    setModalMaskClosable(false);
    setModalBody(
      <Fragment>
        <div style={{ textAlign: "center" }} className="message">
          Your box is being open
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ height: "40px" }}
        >
          <Loading />
        </div>
      </Fragment>
    );
    setIsGold(false);

    const data = await getOpenBoxAdminSignedData({
      account,
      type,
      amount: values.amount,
    });
    try {
      if (data && library && library.provider) {
        const { newplayersIpfs, adminSignedData } = data;
        const web3 = new Web3(library.provider);
        const ruBoxMarketplaceContract = new web3.eth.Contract(
          RU_BOX_MARKETPLACE.ABI,
          RU_BOX_MARKETPLACE.ADDRESS
        );
        const result = await ruBoxMarketplaceContract.methods
          .openBox(
            BOX_TYPE_NUMBER[type],
            values.amount,
            newplayersIpfs,
            adminSignedData
          )
          .send({
            from: account,
          });
        if (result && result.blockHash) {
          getPlayerInfo(result);
          setTimeout(() => handleFetchNewData(), 2000);
        }
      }
    } catch (error) {
      setErrorModal(error.message || error.toString());
    }
  };

  const handleChangeInput = (e) => {
    handleDebounceChange(() => {
      setAmount(e.target.value);
    });
  };

  const handleDebounceChange = debounce((func) => {
    func();
  }, 200);

  const handleApprove = async () => {
    if (account && library && library.provider) {
      setIsLoading(true);
      const web3 = new Web3(library.provider);
      const playerBoxContract = new web3.eth.Contract(
        PLAYER_BOX_CONTRACT.ABI,
        PLAYER_BOX_CONTRACT.ADDRESS
      );
      try {
        const result = await playerBoxContract.methods
          .setApprovalForAll(RU_BOX_MARKETPLACE.ADDRESS, true)
          .send({
            from: account,
          });
        if (result) {
          setIsApprove(true);
        }
      } catch (error) {}
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getAllowance() {
      if (library && library.provider) {
        const web3 = new Web3(library.provider);
        const playerBoxContract = new web3.eth.Contract(
          PLAYER_BOX_CONTRACT.ABI,
          PLAYER_BOX_CONTRACT.ADDRESS
        );
        const isApproveSell = await playerBoxContract.methods
          .isApprovedForAll(account, RU_BOX_MARKETPLACE.ADDRESS)
          .call();
        const balanceBox = await playerBoxContract.methods
          .balanceOf(account, BOX_TYPE_NUMBER[type])
          .call();
        setAmount(balanceBox);
        setIsApprove(!!isApproveSell);
      }
      setIsFetching(false);
    }
    setIsFetching(true);
    type && getAllowance();
  }, [account, library, type]);

  useEffect(() => {
    form.setFieldsValue({
      amount: amount,
    });
    setModalBody(
      <Fragment>
        {account ? (
          <div className="modal-form__content">
            <div className="title">Open box</div>
            <div className="form-item">
              <div> Select amount of box to open </div>
            </div>

            <Form
              name="form-buy"
              className="form-app"
              onFinish={handleSubmit}
              form={form}
            >
              <Form.Item
                name="amount"
                label={null}
                required={false}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: "Amount is required",
                  },
                  {
                    pattern: new RegExp(REGEX_QUANTITY),
                    message:
                      "Please enter a positive integer number greater than 0",
                  },
                  ({}) => ({
                    validator(_, value) {
                      if (value && value > MAX_BOX_OPEN) {
                        return Promise.reject(
                          new Error(
                            `Please enter a less than or equal to ${MAX_BOX_OPEN} box`
                          )
                        );
                      }

                      if (value && value > amount) {
                        return Promise.reject(
                          new Error(
                            `Please enter a less than or equal to ${amount} box`
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  suffix="BOX"
                  name="price"
                  type="number"
                  onChange={handleChangeInput}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (KEY_INVALID_INPUT.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <div className="group-btn-box">
                <Button
                  name="cancel"
                  className="app-btn"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                {!isApprove && (
                  <Button
                    name="approve"
                    className="app-btn app-btn__primary btn-approve"
                    onClick={handleApprove}
                    loading={isLoading}
                  >
                    Approve
                  </Button>
                )}
                {isApprove && (
                  <Button
                    name="list"
                    className="app-btn app-btn__primary btn-list"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    OPEN
                  </Button>
                )}
              </div>
            </Form>
          </div>
        ) : (
          <div className="modal_content">
            Please connect to a wallet first !
          </div>
        )}
      </Fragment>
    );
  }, [account, type, library, isApprove, amount, isLoading]);

  if (isFetching && visible) {
    return (
      <Modal
        className="app-modal modal-form"
        title={null}
        visible={visible}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
      >
        <div
          className="d-flex justify-content-center"
          style={{ height: "10vh", alignItems: "center" }}
        >
          <Loading />
        </div>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        name="modal-buy"
        visible={visible}
        closable={modalCloseable}
        maskClosable={modalMaskClosable}
        centered={true}
        width={!isResultModal ? 412 : isGold ? 960 : 720}
        onCancel={handleCancel}
        className={`app-modal modal-form ${isGold ? " gold" : ""}`}
        footer={null}
      >
        {modalBody}
      </Modal>
    </>
  );
};

export default ModalOpenBox;
