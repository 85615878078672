import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./layouts";
import NotFoundPage from "./components/NotFoundPage";
import Wallet from "components/Wallet/Wallet";
import Marketplace from "components/Marketplace/Marketplace";
import DetailFootballer from "components/DetailFootballer/DetailFootballer";
import Dashboard from "components/Dashboard/Dashboard";
import TokenVault from "components/Vault/TokenVault";
import LuckyVault from "components/Vault/LuckyVault";
import Vault from "components/Vault/Vault";
import Docs from "components/Docs/Docs";
import NFTVault from "components/Vault/NFTVault/NFTVault";
import BoxDetail from "components/Box/BoxDetail";

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Redirect exact from="/" to="/marketplace" />
            <Route exact path="/marketplace/:id" component={Marketplace} />
            <Route exact path="/marketplace" component={Marketplace} />
            <Route exact path="/wallet/:id" component={Wallet} />
            <Route exact path="/wallet" component={Wallet} />
            {/* <Route exact path="/manual" component={Docs} /> */}
            <Route exact path="/player/:id" component={DetailFootballer} />
            <Route exact path="/test" component={DetailFootballer} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/vault/lp-farming" component={TokenVault} />
            <Route exact path="/vault/nft-farming/:id" component={NFTVault} />
            <Route exact path="/vault/nft-farming" component={NFTVault} />
            <Route exact path="/vault/:id" component={Vault} />
            <Route exact path="/vault" component={Vault} />
            <Route exact path="/box/:id" component={BoxDetail} />
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
