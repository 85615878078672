import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import {
  RU_CONTRACT,
  RPC,
  PLAYER_CONTRACT,
  MARKET_CONTRACT,
} from "../../consts";
import FootballerInfo from "./FootballerInfo";
// import OfferHistory from "./OfferHistory";
import SaleHistory from "./SaleHistory";
import { SELL_TYPE } from "utils/constants";
import ModalSell from "./ModalSell";
import ModalConfirmBuy from "./ModalConfirmBuy";
import ModalConfirmDelist from "./ModalConfirmDelist";
import { Form } from "antd";
import { toast } from "react-toastify";
import { getCurrentPlayerPrice, parseUnitsCustom } from "utils/helper";
import { debounce } from "lodash";
import { getDetailPlayer } from "utils/client_api";

const DetailFootballer = () => {
  const params = useParams();
  const { account, library } = useWeb3React();
  const [playerInfo, setPlayerInfo] = useState({
    isOwner: null,
    isOnSale: null,
  });
  const [sellType, setSellType] = useState(SELL_TYPE[0].value);
  const [showModalSell, setShowModalSell] = useState(false);
  const [showModalConfirmBuy, setShowModalConfirmBuy] = useState(false);
  const [showModalConfirmDelist, setShowModalConfirmDelist] = useState(false);
  const [saleInfo, setSaleInfo] = useState({});
  const [currentPrice, setCurrentPrice] = useState(0);
  const [refetchPlayerInfo, setRefetchPlayerInfo] = useState(0);
  const [statusLoading, setStatusLoading] = useState({
    list: false,
    delist: false,
    buy: false,
  });
  const [sellPrice, setSellPrice] = useState();
  const [buyPrice, setBuyPrice] = useState();
  const [formSell] = Form.useForm();
  const [formBuy] = Form.useForm();

  const handleChangeSellType = (e) => {
    setSellType(e.target.value);
    formSell.resetFields();
  };

  const handleShowModalSell = () => {
    setShowModalSell(true);
  };

  const handleCloseModalSell = () => {
    setSellPrice();
    formSell.resetFields();
    setShowModalSell(false);
    setSellType(SELL_TYPE[0].value);
  };

  const handleShowModalConfirmBuy = () => {
    setShowModalConfirmBuy(true);
  };

  const handleCloseModalConfirmBuy = () => {
    formBuy.resetFields();
    setBuyPrice();
    setShowModalConfirmBuy(false);
  };

  const handleShowModalConfirmDelist = () => {
    setShowModalConfirmDelist(true);
  };

  const handleCloseModalConfirmDelist = () => {
    setShowModalConfirmDelist(false);
  };

  const handleChangeInputSell = (e) => {
    setSellPrice(e.target.value);
  };

  const handleChangeInputBuy = (e) => {
    handleDebounceChange(() => {
      setBuyPrice(e.target.value);
    });
  };

  const handleDebounceChange = debounce((func) => {
    func();
  }, 200);

  const handleBuyPlayer = async (values) => {
    const id = params?.id;
    if (values && account && library && library.provider) {
      setStatusLoading((prevState) => ({
        ...prevState,
        buy: true,
      }));
      const web3 = new Web3(library.provider);

      const ruContract = new web3.eth.Contract(
        RU_CONTRACT.ABI,
        RU_CONTRACT.ADDRESS
      );
      const RUBalance = await ruContract.methods.balanceOf(account).call();
      if (web3.utils.fromWei(RUBalance) < parseFloat(values.price)) {
        toast.error("Not enough RU to buy!");
        setStatusLoading((prevState) => ({
          ...prevState,
          buy: false,
        }));
        return;
      }

      if (buyPrice < currentPrice) {
        toast.error("Price must be greater than or equal to Curent Price");
        setStatusLoading((prevState) => ({
          ...prevState,
          buy: false,
        }));
        return;
      }

      const marketContract = new web3.eth.Contract(
        MARKET_CONTRACT.ABI,
        MARKET_CONTRACT.ADDRESS
      );
      try {
        const result = await marketContract.methods
          .bid(
            PLAYER_CONTRACT.ADDRESS,
            id,
            parseUnitsCustom(buyPrice ?? currentPrice)
          )
          .send({
            from: account,
          });

        if (result) {
          setRefetchPlayerInfo((v) => v + 1);
        }
      } catch (e) {}

      setStatusLoading((prevState) => ({
        ...prevState,
        buy: false,
      }));

      handleCloseModalConfirmBuy();
    }
    formBuy.resetFields();
  };

  const handleSell = async (values) => {
    const id = params?.id;
    if (
      values &&
      (values.sell || values.startingPrice) &&
      account &&
      library &&
      library.provider
    ) {
      setStatusLoading((prevState) => ({
        ...prevState,
        list: true,
      }));
      const web3 = new Web3(library.provider);
      const marketContract = new web3.eth.Contract(
        MARKET_CONTRACT.ABI,
        MARKET_CONTRACT.ADDRESS
      );

      try {
        const result = await marketContract.methods
          .createAuction(
            PLAYER_CONTRACT.ADDRESS,
            id,
            web3.utils.toWei(`${values.sell || values.startingPrice}`),
            web3.utils.toWei(`${values.sell || values.endingPrice}`),
            parseInt(parseFloat(values.duration * 86400).toFixed(0)) || 3600
          )
          .send({
            from: account,
          });

        if (result) {
          setRefetchPlayerInfo((v) => v + 1);
        }
      } catch (e) {
        console.log(e);
      }
      setStatusLoading((prevState) => ({
        ...prevState,
        list: false,
      }));
      handleCloseModalSell();
    }
    formSell.resetFields();
  };

  const handleDelist = async () => {
    if (account && library && library.provider) {
      setStatusLoading((prevState) => ({
        ...prevState,
        delist: true,
      }));
      const web3 = new Web3(library.provider);
      const marketContract = new web3.eth.Contract(
        MARKET_CONTRACT.ABI,
        MARKET_CONTRACT.ADDRESS
      );

      try {
        const id = params?.id;
        const result = await marketContract.methods
          .cancelAuction(PLAYER_CONTRACT.ADDRESS, id)
          .send({
            from: account,
          });

        if (result) {
          setRefetchPlayerInfo((v) => v + 1);
        }
      } catch (e) {}
      setStatusLoading((prevState) => ({
        ...prevState,
        delist: false,
      }));

      handleCloseModalConfirmDelist();
    }
  };

  useEffect(() => {
    async function getPlayerInfo() {
      const id = params?.id;
      if (id == undefined) {
        return;
      }
      const playerInfo = await getDetailPlayer(id);

      setPlayerInfo((oldState) => {
        return {
          ...oldState,
          ...playerInfo,
        };
      });
    }

    getPlayerInfo();
  }, [params, refetchPlayerInfo]);

  useEffect(() => {
    async function getAccountInfo() {
      let isOwner = null;
      let isOnSale = null;
      if (account && library && library.provider) {
        const id = params?.id;
        const web3 = new Web3(RPC.BSC);
        const playerContract = new web3.eth.Contract(
          PLAYER_CONTRACT.ABI,
          PLAYER_CONTRACT.ADDRESS
        );
        const marketContract = new web3.eth.Contract(
          MARKET_CONTRACT.ABI,
          MARKET_CONTRACT.ADDRESS
        );

        try {
          const owner = await playerContract.methods.ownerOf(id).call();
          isOwner = owner?.toLowerCase() === account?.toLowerCase();
        } catch (e) {}

        const sale = {};
        try {
          const saleInfo = await marketContract.methods
            .getAuction(PLAYER_CONTRACT.ADDRESS, id)
            .call();
          if (saleInfo?.startingPrice) {
            sale.startingPrice = web3.utils.fromWei(saleInfo?.startingPrice);
            sale.endingPrice = web3.utils.fromWei(saleInfo?.endingPrice);
            sale.duration = parseInt(saleInfo.duration);
            sale.isSeller = saleInfo.seller === account;
            sale.startedAt = parseInt(saleInfo.startedAt);
            setSaleInfo(sale);
          }
          isOnSale = !!saleInfo;
        } catch (e) {
          isOnSale = false;
          setSaleInfo({});
        }

        setPlayerInfo((oldState) => {
          return {
            ...oldState,
            isOwner,
            isOnSale,
          };
        });
      }
    }

    getAccountInfo();
    handleCloseModalConfirmBuy();
    handleCloseModalConfirmDelist();
    handleCloseModalSell();
  }, [account, refetchPlayerInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!buyPrice) formBuy.setFieldsValue({ price: currentPrice });
    else {
      formBuy.setFieldsValue({ price: buyPrice });
    }
  }, [account, currentPrice, buyPrice]);

  useEffect(() => {
    const getCurrentPrice = () => {
      setCurrentPrice(getCurrentPlayerPrice(saleInfo));
    };

    getCurrentPrice();
    const interval = setInterval(getCurrentPrice, 5 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [saleInfo, account]);

  useEffect(() => {
    document.title = "RIFI United";
  }, []);

  return (
    <div className="detail-footballer">
      <FootballerInfo
        playerInfo={playerInfo}
        id={params?.id}
        handleShowModalSell={handleShowModalSell}
        handleShowModalConfirmBuy={handleShowModalConfirmBuy}
        handleShowModalConfirmDelist={handleShowModalConfirmDelist}
        saleInfo={saleInfo}
        handleChangeCurrentPrice={setCurrentPrice}
        currentPrice={currentPrice}
      />
      <Container>
        {/* <OfferHistory /> */}
        <SaleHistory tokenId={params?.id} />
      </Container>
      <ModalSell
        visible={showModalSell}
        setSellType={setSellType}
        sellType={sellType}
        handleChangeSellType={handleChangeSellType}
        handleCancel={handleCloseModalSell}
        handleSubmit={handleSell}
        handleChangeInputSell={handleChangeInputSell}
        sellPrice={sellPrice}
        form={formSell}
        statusLoading={statusLoading?.list}
      />
      <ModalConfirmBuy
        visible={showModalConfirmBuy}
        handleCancel={handleCloseModalConfirmBuy}
        handleSubmit={handleBuyPlayer}
        currentPrice={currentPrice}
        form={formBuy}
        statusLoading={statusLoading?.buy}
        saleInfo={saleInfo}
        handleChangeInput={handleChangeInputBuy}
        buyPrice={buyPrice}
      />
      <ModalConfirmDelist
        visible={showModalConfirmDelist}
        handleCancel={handleCloseModalConfirmDelist}
        handleSubmit={handleDelist}
        statusLoading={statusLoading?.delist}
      />
    </div>
  );
};

export default DetailFootballer;
