import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import ic_brazil from "../../assets/icons/brazil.png";
// import ic_character from "../../assets/images/wallet/player.png";
import deco_bronze from "../../assets/images/wallet/deco_bronze.png";
import deco_silver from "../../assets/images/wallet/deco_silver.png";
import deco_gold from "../../assets/images/wallet/deco_gold.png";
import deco_legendary from "../../assets/images/wallet/deco_legendary.png";
import { thounsandSeparators, formatRUDecimals } from "../../utils/helper";
import Player from "./Player";
import { API_URL } from "consts";
import { getDetailPlayer } from "utils/client_api";

function PlayerCard({ item, cardType = "wallet", notOnClick }) {
  item = item ? item : {};
  const { tokenId, startingPrice } = item;
  const history = useHistory();
  const [playerInfo, setPlayerInfo] = useState({});
  useEffect(() => {
    async function getPlayerInfo() {
      if (item.name) {
        setPlayerInfo(item);
        return;
      }

      if (!tokenId) {
        return;
      }
      try {
        const result = await getDetailPlayer(tokenId);
        setPlayerInfo(result);
      } catch (error) {}
    }

    getPlayerInfo();
  }, [tokenId, item, item?.name]);

  const getPlayerTier = () => {
    if (!playerInfo) return "";
    if (playerInfo?.legendary) return "legendary";
    if (playerInfo?.playerAttribute?.ovr < 63) return "bronze";
    if (playerInfo?.playerAttribute?.ovr < 78) return "silver";
    if (playerInfo?.playerAttribute?.ovr <= 88) return "gold";
  };

  const getDeco = () => {
    switch (getPlayerTier()) {
      case "bronze":
        return deco_bronze;
      case "silver":
        return deco_silver;
      case "gold":
        return deco_gold;
      case "legendary":
        return deco_legendary;
      default:
        return "";
    }
  };

  if (!playerInfo) return null;

  return (
    <div
      className="card wallet-card"
      onClick={notOnClick ? () => {} : () => history.push(`/player/${tokenId}`)}
    >
      <div className="card-top">
        <div className="card-shadow" />
        <div className={`card-body ${getPlayerTier()}`}>
          {playerInfo?.legendary ? (
            <img
              className="rifi-player"
              src={`${API_URL}${playerInfo?.playerAttribute?.bodyImage}`}
              alt=""
            />
          ) : (
            <Player
              {...playerInfo?.playerAttribute?.bodyDetails}
              pos={playerInfo?.playerAttribute?.pfdPos1?.pos}
            />
          )}
          <div className="card-deco">
            <img src={getDeco()} alt="" />
          </div>
          {/* <div className="card-flag">
            <div className="card-flag__bg" />
            <img src={ic_brazil} alt="" />
          </div> */}
          <div className="card-name txt--title">{playerInfo?.name}</div>
          <div className="card-content">
            <div className="card-info">
              <div className="info-block">
                <div className="info-name"> Overall </div>
                <div
                  className={`info-value overall ${
                    playerInfo?.legendary ? "legendary" : null
                  }`}
                >
                  {playerInfo?.playerAttribute?.ovr}{" "}
                </div>
              </div>
              <div className="block-line"></div>
              <div className="info-block">
                <div className="info-name">AGE</div>
                <div className="info-value">
                  {" "}
                  {playerInfo?.playerAttribute?.age}{" "}
                </div>
              </div>
              <div className="block-line"></div>
              <div className="info-block">
                <div className="info-name"> POSITION </div>
                <div className="info-value">
                  {" "}
                  {playerInfo?.playerAttribute?.pfdPos1?.pos}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-bottom">
        <div className="card-id">ID: {tokenId}</div>
        {cardType === "wallet" ? null : (
          <div className="card-price">
            {thounsandSeparators(formatRUDecimals(startingPrice)) || "--"} RU
          </div>
        )}
      </div>
    </div>
  );
}

export default PlayerCard;
